/* eslint-disable react-hooks/exhaustive-deps */
import { Box, useTheme, Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog, Tooltip, IconButton, InputLabel, TextField, MenuItem } from "@mui/material";
import { tokens } from "../../theme";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect } from "react";
import { myConfig } from "../../settings.js";
import { useSelector } from 'react-redux';
import { apiService2 } from '../../features/apiService';
import { MaterialReactTable } from "material-react-table";
import { externalWorkshopModel, workshopModel } from "../../schema/schema";

import { getAccess } from "../../features/helpers/authhelper";
import { timeZoneConverter } from "../../features/helpers/commonhelper.js";
import Header from "../../components/Header.jsx";
import { toast } from "react-toastify";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { tableView } from "../../features/helpers/table.js";

const ExternalWorkshop = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user } = useSelector((state) => state.auth);
    const columns = [];

    const [refresh, setRefresh] = useState(false);
    const [workshopList, setWorkshopList] = useState([]);
    const [workshop, setWorkshop] = useState([]);
    const [selectedrow, setRow] = useState(-1);
    const [rowSelection, setRowSelection] = useState({});
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState(new Date());
    const [breakBownCodeList, setBreakBownCodeList] = useState([]);
    const [breakdowncode, setBreakdownCode] = useState('');
    const [externalcost, setExternalCost] = useState('');
    let maxDate = new Date();


    for (const item of externalWorkshopModel) {
        if (item.hasOwnProperty("view")) {
            if (!item.view) continue;
        }
        columns.push({ accessorKey: item['field'], header: item['label'], size: 150 });
    }

    useEffect(() => {
        apiService2('POST', myConfig.getWorkshopEP, { isExternal: true })
            .then(response => {
                var workshopList = response.data;
                workshopList?.forEach(res => {
                    res.cdate = res.createdOn != null ? timeZoneConverter(res.createdOn) : '';
                    res.indate = res.indate != null ? timeZoneConverter(res.indate) : '';
                    res.outdate = res.outdate != null ? timeZoneConverter(res.outdate) : '';
                    res.externaloutdate = res.externaloutdate != null ? timeZoneConverter(res.externaloutdate) : '';
                })
                setWorkshopList(workshopList);
            })
            .catch(error => {
                console.error(error);
            });
    }, [refresh]);

    useEffect(() => {
        const wrnumber = Object.keys(rowSelection)[0];
        const selected = Object.values(rowSelection)[0];
        let index = 0;
        if (selected) {
            for (const temp of workshopList) {
                if (temp._id == wrnumber) {
                    setWorkshop(temp);
                    setRow(index);
                    break;
                }
                ++index;
            }
        } else {
            setWorkshop({});
        }
    }, [rowSelection]);

    function handleClose() {
        setOpen(false);
    }

    function handleOpen() {
        if (Object.keys(rowSelection).length === 0) {
            toast.error("Please select a workshop to update");
            return;
        }
        if (workshop?.status == 'External-Out') {
            setMessage("Update Recieve Date");
        } else {
            setMessage("Update Dispatch Date");
        }
        setOpen(true);
    }

    function updateDispatch() {
        const wrnumber = Object.keys(rowSelection)[0];
        const selected = Object.values(rowSelection)[0];
        if (selected) {
            if (workshop?.status == 'External-Out') {
               if(breakdowncode == ''){
                   toast.error("Please select Breakdown Code");
                   return;
               }
                if(externalcost == ''){
                    toast.error("Please enter External Cost");
                    return;
                }
                const data = {
                    _id: workshop._id,
                    date: date,
                    breakdowncode: breakdowncode.split(":")[0],
                    externalcost: externalcost
                };
                apiService2('POST', myConfig.updateReceiveEP, data)
                    .then(response => {
                        toast.success("Workshop dispatched successfully");
                        setRefresh(!refresh);
                        setOpen(false);
                        setRowSelection({});
                        setWorkshop({});
                    })
                    .catch(error => {
                        console.error(error);
                    });
            } else {
                const data = {
                    _id: wrnumber,
                    date: date
                };
                apiService2('POST', myConfig.updateDispatchEP, data)
                    .then(response => {
                        toast.success("Workshop dispatched successfully");
                        setRefresh(!refresh);
                        setOpen(false);
                        setRowSelection({});
                        setWorkshop({});
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        }
    }

    useEffect(() => {
        apiService2('GET', myConfig.getBreakBownCodeEP)
            .then(response => {
                setBreakBownCodeList(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, [open]);

    let modBreakDownList = [];
    for (const item of breakBownCodeList.filter((item) => item.type == 'BR Code')) {
        modBreakDownList.push(item.code + ": " + item.description);
    }

    function CustomToolbar() {
        return (
            <Box className="mb-1">
                <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={handleOpen} >
                    Update
                </Button>

            </Box>
        );
    }




    return (
        <Box m="10px 20px 20px 20px">
            <Box
                m="20px 0 0 0"
                height="70vh">
                {CustomToolbar()}
                {tableView(false, columns, workshopList, setRowSelection, rowSelection, '_id')}
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth={'xs'}>
                    <DialogTitle id="form-dialog-title">{message}</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant="h6"></DialogContentText>
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            m="20px 0 0 0"
                        >
                            <Box width="100%" m='0 0 20px 0px'>
                                <InputLabel id="simple-label">{'Date'}</InputLabel>
                                <DatePicker
                                    id={'indate'}
                                    name={'indate'}
                                    value={dayjs(date)}
                                    maxDate={dayjs(maxDate)}
                                    onChange={date => setDate(date)}
                                    sx={{ display: 'flex', flexdirection: 'row', width: "100%" }}
                                    margin="normal"
                                />
                                {
                                    workshop.status == 'External-Out' ?
                                        (
                                            <>
                                                <InputLabel id="simple-label" sx={{ marginTop: '10px' }}>{'Breakdown'}</InputLabel>
                                                <TextField
                                                    id="outlined-basic"
                                                    name={'breakdown'}
                                                    value={breakdowncode}
                                                    onChange={e => setBreakdownCode(e.target.value)}
                                                    margin="normal"
                                                    type="text"
                                                    select
                                                    defaultValue={''}
                                                    sx={{ display: 'flex', width: "100%", marginTop: 0 }}>
                                                    {modBreakDownList.map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                <InputLabel id="simple-label" sx={{ marginTop: '10px' }}>{'External Cost'}</InputLabel>
                                                <TextField
                                                    id="outlined-basic"
                                                    placeholder={'External Cost'}
                                                    name={'externalcost'}
                                                    value={externalcost}
                                                    onChange={e => setExternalCost(e.target.value)}
                                                    helperText=""
                                                    margin="normal"
                                                    type="text"
                                                    sx={{ display: 'flex', width: "100%" }} />
                                            </>
                                        ) : (
                                            <></>
                                        )
                                }
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button type="button" variant="contained" color="primary" onClick={updateDispatch}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
};

export default ExternalWorkshop;
