import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { meterModel } from "../../schema/schema";
import { apiService2 } from "../../features/apiService";
import { myConfig } from "../../settings";
import { toast } from 'react-toastify'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { MaterialReactTable } from "material-react-table";
import { getConfig } from "../../features/helpers/genhelper";
import AddUnit from "./addunit";
import EditUnit from "./editunit";
import { getAccess } from "../../features/helpers/authhelper";
import { tableView } from "../../features/helpers/table";

const Meter = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const columns = [];

    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    const [refresh, setRefresh] = useState(false);
    const [unitList, setunitList] = useState([]);
    const [unit, setunit] = useState([]);
    const { user } = useSelector((state) => state.auth);
    const [selectedrow, setRow] = useState(-1);
    const [rowSelection, setRowSelection] = useState({});
    const [message, setMessage] = useState('');
    const [unitConfig, setUnitConfig] = useState('');
    const access = getAccess(user, 'Setup');





    for (const item of meterModel) {
        if (item.hasOwnProperty("skip")) {
            if (item.skip) continue;
        }
        columns.push({ accessorKey: item['field'], header: item['label'], size: 150 });
    }

    useEffect(() => {
        getConfig(user, 'Unit').then(resp => {
            columns.push({ accessorKey: resp, header: resp, size: 150 });
            setUnitConfig(resp)
        });
    }, [refresh])

    useEffect(() => {
        apiService2('GET', myConfig.getUnitEP)
            .then(response => {
                var unitList = response.data;
                unitList?.forEach(res => {
                    if (res.uom == null) return;
                    res.metric = res.uom.metric
                    res.imperial = res.uom.imperial
                })
                setunitList(unitList);
            })
            .catch(error => {
                console.error(error);
            });
    }, [refresh]);

    useEffect(() => {
        const wrnumber = Object.keys(rowSelection)[0];
        const selected = Object.values(rowSelection)[0];
        let index = 0;
        if (selected) {
            for (const temp of unitList) {
                if (temp._id == wrnumber) {
                    setunit(temp);
                    setRow(index);
                    break;
                }
                ++index;
            }
        } else {

            setunit({});
        }
    }, [rowSelection]);

    function handleClose() {
        setOpen(false);
        setOpenEdit(false)
        setOpenDelete(false)
        setRefresh(!refresh);
        setunit({});

    }

    function handleClickOpen() {
        setOpen(true);
        setRefresh(false);
    }
    function handleDelete() {
        apiService2('POST', myConfig.deleteUnitEP, { "_id": unit._id, message: message })
            .then(resp => {
                toast.warning("Deleted Successfully ");
                handleClose();
            })
            .catch(error => {
                console.log(["unit Add Failed->", error]);
                handleClose();
            });
    }

    function CustomToolbar() {
        return (
            <Box className="mb-1">
                {
                    access.isCreate && <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleClickOpen}>
                        Add
                    </Button>
                }
            </Box>
        );
    }
    return (
        <Box m="10px 20px 20px 20px">
            <Box
                m="20px 0 0 0"
                height="70vh">
                {CustomToolbar()}
                {tableView(
                    false, columns, unitList, setRowSelection,
                    rowSelection, '_id',
                    true,
                    ({ row, table }) => [
                        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                            {
                                access.isEdit && <Tooltip title="Edit">
                                    <IconButton
                                        color="secondary"
                                        onClick={() => {
                                            setunit({ ...row.original })
                                            setOpenEdit(true);
                                        }}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            {
                                access.isDeleted && <Tooltip title="Delete">
                                    <IconButton
                                        color="error"
                                        onClick={() => {
                                            setunit({ ...row.original })
                                            setOpenDelete(true);
                                        }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Box>
                    ])
                }
                <AddUnit open={{ state: open, setfn: handleClose }} />
                <EditUnit open={{ state: openEdit, setfn: handleClose, unit: unit }} />
                <Dialog
                    open={openDelete}
                    fullWidth={true}
                    maxWidth={'xs'}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title">
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">{message} unit</DialogTitle>

                        <DialogContent>
                            <DialogContentText variant="h6">Are you sure you want to {message}?</DialogContentText>
                            <DialogActions sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}>
                                <Button type="button" variant="contained" color="secondary" onClick={handleDelete}>
                                    Confirm
                                </Button>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                </Dialog>
            </Box>
        </Box>
    );
}

export default Meter;