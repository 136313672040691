import { Box, useTheme, Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog, TextField, MenuItem } from "@mui/material";
import { tokens } from "../../theme";
import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify'
import { myConfig } from "../../settings.js";
import { useSelector } from 'react-redux';
import { apiService2 } from '../../features/apiService';
import { MaterialReactTable } from "material-react-table";
import { getAccess } from "../../features/helpers/authhelper";
import { storeModel, storeRequestModel } from "../../schema/schema";
import { timeZoneConverter } from "../../features/helpers/commonhelper.js";
import { tableView } from "../../features/helpers/table.js";

const IssueStock = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const columns = [];
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [storeList, setStoreList] = useState([]);
    const [storeList2, setStoreList2] = useState([]);
    const [store, setStore] = useState([]);
    const { user } = useSelector((state) => state.auth);
    const [selectedrow, setRow] = useState(-1);
    const [rowSelection, setRowSelection] = useState({});
    const [storeConfigList, setStoreConfigList] = useState([]);
    const [storeConfig, setStoreConfig] = useState('');
    const [storeConfigId, setStoreConfigId] = useState('');
    const [message, setMessage] = useState('');
    const [id, setId] = useState('');
    const access = getAccess(user, 'setup');

    for (const item of storeRequestModel) {
        if (item.hasOwnProperty("skip")) {
            if (item.skip) continue;
        }
        columns.push({ accessorKey: item['field'], header: item['label'], size: 150 });
    }


    useEffect(() => {
        apiService2('GET', myConfig.getStoreConfigEP)
            .then(response => {
                var storeConfigList = response.data;
                setStoreConfigList(storeConfigList);
                setStoreConfig(storeConfigList[0].name);
                setStoreConfigId(storeConfigList[0]._id);
                apiService2('GET', myConfig.getStoreRequestEP)
                    .then(response => {
                        var storeList = response.data;
                        storeList?.forEach(res => {
                            res.requestdate = timeZoneConverter(res.requestdate)
                        })
                        setStoreList(storeList);
                        if (storeConfigList[0]._id != null) {
                            setStoreList2(storeList.filter((res) => res.store == storeConfigList[0]._id));
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });

            })
            .catch(error => {
                console.error(error);
            });
    }, [refresh]);

    let modStoreConfigList = [];
    for (const cont of storeConfigList) {
        modStoreConfigList.push(cont.name);
    }


    useEffect(() => {
        const wrnumber = Object.keys(rowSelection)[0];
        const selected = Object.values(rowSelection)[0];
        let index = 0;
        if (selected) {
            for (const temp of storeList) {
                if (temp._id == wrnumber) {
                    console.log(temp);
                    setStore(temp);
                    setRow(index);
                    break;
                }
                ++index;
            }
        } else {
            setStore({});
        }
    }, [rowSelection]);

    function handleClose() {
        setOpen(false);
        //setRefresh(!refresh);
    }

    function handleClickOpen() {
        if (selectedrow >= 0) {
            setMessage('Dispatch');
            setId(1);
            setOpen(true);
        } else {
            toast.error('Select a store');
        }
    }

    function handleClickTransfer() {
        if (selectedrow >= 0) {
            setMessage('Transfer');
            setId(2);
            setOpen(true);
        } else {
            toast.error('Select a store');
        }
    }

    function handleUpdate() {
        console.log('id', id);
        if (id == 1) {
            // console.log('store', storeList);
            // var stock = storeList.find(x => x.itemcode === store.itemcode);
            // console.log('stock', stock);
            // if (stock && stock.quantity >= store.quantity) {
            apiService2('POST', myConfig.dispatchStoreRequestEP, store)
                .then(response => {
                    console.log('response', response.data);
                    if (response.data['message']) {
                        console.log('response', response.data['message']);
                        toast.error('Item not found in Store');
                        handleClose();
                        return;
                    } else {
                        console.log('response eeeeee', response.data);
                        toast.success('Store Updated');
                        handleClose();
                    }
                })
                .catch(error => {
                    console.error(error);
                    toast.error('Error updating store');
                });
            // } else {
            //     toast.error('Item not found in Store');
            //     handleClose();
            //     return;
            // }
        } else {
            apiService2('POST', myConfig.updateStoreRequestEP, store)
                .then(response => {
                    toast.success('Store Updated');
                    handleClose();
                })
                .catch(error => {
                    toast.error('Error updating store');
                });
        }


    }

    function CustomToolbar() {
        return (
            <Box className="mb-1">
                <TextField
                    id="outlined-basic"
                    label={'Store'}
                    name={'redMax'}
                    value={storeConfig}
                    onChange={(e) => {
                        setStoreConfig(e.target.value);
                        var storeConfig = storeConfigList.find((res) => res.name == e.target.value);
                        setStoreConfigId(storeConfig._id);
                        setStoreList2(storeList.filter((res) => res.store == storeConfig._id));
                    }}
                    margin="normal"
                    type="text"
                    select
                    defaultValue={''}
                    sx={{ display: 'flex', width: "200px" }}>
                    {modStoreConfigList.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
                {
                    access.isCreate && <Button variant="contained" color="primary" onClick={handleClickOpen}>
                        Dispatch
                    </Button>
                } {
                    access.isCreate && storeConfig != 'Main Store' && <Button variant="contained" color="primary" onClick={handleClickTransfer}>
                        Transfer To Main Store
                    </Button>
                }
            </Box>
        );
    }

    return (
        <Box m="20px">
            <Box
                m="20px 0 0 0"
                height="70vh">
                {CustomToolbar()}
                {tableView(false,columns, storeList2, setRowSelection, rowSelection, '_id')}
                <Dialog
                    open={open}
                    fullWidth={true}
                    maxWidth={'xs'}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title">
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Spare {message}</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">Are you sure you want to {message}?</DialogContentText>
                            <DialogActions sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}>
                                <Button type="button" variant="contained" color="secondary" onClick={handleUpdate}>
                                    Confirm
                                </Button>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                </Dialog>
            </Box>
        </Box>
    );
};

export default IssueStock;
