import React, { useState, useEffect } from "react";
import { Box, Grid, TextField, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { myConfig } from "../../settings";
import { workOrderModel } from "../../schema/schema.js";
import { apiService } from '../../features/apiService';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify'
import { formCreator } from "../../features/helpers/formhelper";

//function initialiseFields() {return workOrderModel};

// API End Point
const endPoint = myConfig.editWOEndPoint;
let objindex = 0;
let fakecount = 0;

const AddNotes = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(props.open.state);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [isSubmitionError, setSubmitionError] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [workorder, setWorkOrder] = useState('');
  const [notes, setNotes] = useState('');

  // Get Department list


  // Handle States for the dialog box
  function handleClose() {
    console.log(["handleClose: Setting open to false", open, props.open.user]);
    setOpen(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);
    //setFormFields(genFormFields);
    props.open.setfn();
    objindex = 0;
  }

  // Handle retry when submit failed
  function handleRetry() {
    setSubmitionError(false);
    setSubmitionCompleted(false);
  }


  // console.log(["Here open->", props.open.state, open, isSubmitionError]);
  if (props.open.state & !open) {
    setOpen(true);
    setWorkOrder(props.open.workorder);
    setNotes(props.open.workorder.notes);
  }

  function handleSubmit() {
    var retobj = workorder;
    retobj['notes'] = notes;
    apiService(endPoint, retobj)
      .then(resp => {
        console.log("WorkOrder updated ");
        setSubmitionCompleted(true);
      })
      .catch(error => {
        console.error(error);
        setSubmitionError(true);
        setSubmitionCompleted(true);
      })
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'sm'}
        aria-labelledby="form-dialog-title"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1000px",  // Set your width here
            },
          },
        }}
      >
        {!isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Add/Update WorkOrder Notes</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6"></DialogContentText>
              <form onSubmit={handleSubmit}>
                <Box >
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        WorkOrder Number
                      </Typography>
                      <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                        {workorder['wonumber']}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Asset Id
                      </Typography>
                      <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                        {workorder['assetid']}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        WorkOrder State
                      </Typography>
                      <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                        {workorder['state']}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="notes"
                        name="notes"
                        label="Notes"
                        multiline
                        rows={4}
                        variant="outlined"
                        required
                        placeholder="Enter Notes"
                        onChange={(e) => {
                          setNotes(e.target.value);
                        }}
                        value={notes}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <DialogActions>
                  <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </DialogActions>
              </form>

            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title"></DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6"></DialogContentText>
              <DialogContentText variant="h6">WorkOrder updated Successfully</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Back
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionError && (
          <React.Fragment
          >
            <DialogTitle id="form-dialog-title">Sorry! WorkOrder Update Failed</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Try Again Later</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleRetry}>
                  Back
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  );
}

export default AddNotes;