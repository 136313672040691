import React, { useState, useEffect } from "react";
import { Grid, IconButton, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import axios from "axios";
import {
    Button,
    withStyles,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { myConfig } from "../../settings";
import { useSelector } from 'react-redux';
import { apiService2 } from "../../features/apiService";

import { toast } from "react-toastify";
// API End Point

let fakecount = 0;
const AddCategory = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(props.open.state);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [isSubmitionError, setSubmitionError] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const [errorMessage, setErrorMessage] = useState('');
    const [name, setName] = useState('');


    if (props.open.state & !open) {
        setOpen(true);
        setName('');
    }

    // Handle States for the dialog box
    function handleClose() {
        setOpen(false);
        setSubmitionError(false);
        setSubmitionCompleted(false);
        props.open.setfn();
    }

    function handleTryAgain() {
        setSubmitionError(false);
        setErrorMessage('');
    }


    function handleSave() {
        if (name != null && name != '') {
            let details = { name: name }
            apiService2('POST', myConfig.addAccessCategory, details)
                .then(resp => {
                    if (resp.data.message) {
                        setErrorMessage(resp.data.message);
                        setSubmitionError(true);
                    } else {
                        setSubmitionCompleted(true);
                    }
                })
                .catch(error => {
                    setSubmitionError(true);
                    setSubmitionCompleted(true);
                });
        } else {
            toast.warning("Please add the category name.");
        }
    }



    return (
        <React.Fragment>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'xs'}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                {!isSubmitionCompleted && !isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Add Access Category</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6"></DialogContentText>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-basic"
                                        label={'Category Name'}
                                        name={'categoryName'}
                                        value={name}
                                        style={{ width: "100%" }}
                                        onChange={(e) => { setName(e.target.value) }}
                                        margin="normal"
                                        type="text"
                                    />
                                </Grid>
                            </Grid>
                        
                            <DialogActions>
                                <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button type="button" variant="contained" color="primary" onClick={handleSave}>
                                    Save
                                </Button>
                            </DialogActions>

                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionCompleted && !isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Thanks! Accesscontrol Added</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">Thanks</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Sorry! Add Accesscontrol Failed</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6"> {errorMessage ? errorMessage : 'Try Again Later'}</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="primary" onClick={handleTryAgain}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
            </Dialog>
        </React.Fragment>
    );
}

export default AddCategory;