import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import React, { useEffect, useState } from "react";
import { inspectionModel } from "../../schema/schema";
import { useSelector } from "react-redux";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { apiService, apiService2 } from "../../features/apiService";
import { myConfig } from "../../settings";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import { DatePicker } from "@mui/x-date-pickers";

let fakecount = 0;
const EditInspection = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(props.open.state);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [maxWidth, setMaxWith] = useState('sm');
    const [isSubmitionError, setSubmitionError] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [formFields, setFormFields] = useState(inspectionModel)
    const { user } = useSelector((state) => state.auth);
    const [assetList, setAssetList] = useState([]);
    const [errorMessage, setErrorMessage] = useState('Try Again Later');
    const [multiSelectValue, setMultiSelectValue] = useState({ "inspectionCategory": [], "aspect": [] });
    const [aspectList, setAspectList] = useState([]);
    const [sched, setSched] = useState(false);
    const [valueDB, setValueDB] = useState({ "assetId": [], "section": [] });
    if (props.open.state & !open) {
        setOpen(true);
        setMaxWith('sm');
        setSched(false);
        getInitialValues();
        inspectionModel.forEach((item) => {
            if (item.field == 'state') {
                item['required'] = true;
            }
        });
    }

    function getInitialValues() {
        let ivalues = {};
        const inspection = props.open.inspection;
        for (const item of inspectionModel) {
            if (item.field2 != null) {
                ivalues[item.field] = inspection[item.field] ? inspection[item.field][item.field2] : '';
            } else {
                if (item.field == 'inspectionCategory') {
                    handleDepart(inspection[item.field], inspection['assetId']['assetid'], inspection['department']);
                }
                ivalues[item.field] = inspection[item.field]
                if (item.multiple) {
                    multiSelectValue[item.field] = inspection[item.field];
                }
            }
        }
        setMultiSelectValue({ ...multiSelectValue })
        setInitialValues({ ...ivalues })
    }

    useEffect(() => {
        apiService(myConfig.getAssetsEP, { state: 'Operational' })
            .then(response => {
                setAssetList(response.data);
                let modAssetList = [];
                assetList.forEach((item) => {
                    modAssetList.push(item.name + "-" + item.assetid);
                })
                var db = valueDB;
                db["assetId"] = modAssetList;
                setValueDB(db);
            })
            .catch(error => {
                console.error(error);
            })
    }, [open]);


    useEffect(() => {
        apiService2('POST', myConfig.getFacilitiesFilterEP, { isFilterSection: true },)
            .then(response => {
                var modSectionList = [];
                for (const fac of response.data?.filter(x => x.type == 'Section')) {
                    modSectionList.push(fac.name);
                }
                var db = valueDB;
                db["section"] = modSectionList;
                setValueDB(db);
            })
            .catch(error => {
                console.error(error);
            })
    }, [open]);
    function handleClose() {
        setOpen(false);
        setSubmitionError(false);
        setSubmitionCompleted(false);
        props.open.setfn();
    }

    function handleRetry() {
        setSubmitionError(false);
        setSubmitionCompleted(false);
        setErrorMessage('Try Again Later');
    }

    function handleDepart(category, assetId, dept) {
        let modAspectList = [];
        var inspectionList = [];
        var asset = assetList.find(x => x.assetid == assetId);
        inspectionList = asset['inspectionList'];
        inspectionList = inspectionList.filter(x => x.department == dept && category.includes(x.inspectionBasic))
        if (inspectionList && inspectionList.length > 0) {
            inspectionList.forEach(res => {
                if (!modAspectList.includes(res.aspect)) {
                    modAspectList.push(res.aspect)
                }
            })
            setAspectList([...modAspectList])
        }
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={maxWidth}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                {!isSubmitionCompleted && !isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Edit Inspection </DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6"></DialogContentText>
                            <Formik
                                enableReinitialize
                                key={fakecount}
                                initialValues={initialValues}
                                onSubmit={(values) => {
                                    const inspection = props.open.inspection;
                                    values['_id'] = inspection?._id;
                                    apiService(myConfig.updateInspectEP, values)
                                        .then(resp => {
                                            if (resp['response'] && resp['response']['status'] == 500) {
                                                setSubmitionError(true);
                                                setErrorMessage('Inspection and this department are already exits!')
                                            } else {
                                                setSubmitionCompleted(true);
                                            }
                                            console.log('Add Inspection Resp->', resp);
                                        })
                                        .catch(error => {
                                            console.log(["Config Maintenance - Errored->", error]);
                                            if (error['response'] && error['response']['status'] == 500) {
                                                setSubmitionError(true);
                                                setErrorMessage('Inspection and this department are already exits!')
                                            } else {
                                                setSubmitionCompleted(true);
                                            }
                                        });
                                }}
                                // Need to expand the validation schema more later
                                validationSchema={Yup.object().shape({
                                    assetId: Yup.string().required("Required"),
                                    department: Yup.string().required("Required"),
                                    aspect: Yup.array().min(1).required("Required"),
                                    inspectionCategory: Yup.array().min(1).required("Required"),
                                    approvalRequired: Yup.string().required("Required"),
                                    safetyClearance: Yup.string().required("Required"),
                                })}>
                                {props => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        handleReset,
                                    } = props;
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            {/*  */}
                                            <Grid container spacing={3}>
                                                {(() => {
                                                    let result = [];
                                                    let enumlist = [];
                                                    let label;
                                                    result.push(<Grid container spacing={2}></Grid>)
                                                    for (const item of formFields.filter(x => x.required == true)) {
                                                        label = item['label']
                                                        if (item.hasOwnProperty("required")) { label = item.required ? label + "*" : label } else label = label;
                                                        if (item['select'] == true && item.edit == true) {
                                                            if (item.value) {
                                                                enumlist = valueDB[item['field']];
                                                            } else if (item.field == 'aspect') {
                                                                enumlist = aspectList;
                                                            } else {
                                                                enumlist = item.enum
                                                            }
                                                            if (item.multiple) {
                                                                result.push(
                                                                    <Grid item xs={6}>
                                                                        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                                                                        <Select
                                                                            id="outlined-basic"
                                                                            name={item.field}
                                                                            value={multiSelectValue[item.field]}
                                                                            error={errors.name && touched.name}
                                                                            onBlur={handleBlur}
                                                                            multiple
                                                                            renderValue={(selected) => selected.join(', ')}
                                                                            onChange={e => {
                                                                                handleChange(e);
                                                                                multiSelectValue[item.field] = e.target.value;
                                                                                setMultiSelectValue(multiSelectValue);
                                                                                handleDepart(item, e);
                                                                            }}
                                                                            margin="normal"
                                                                            type="text"
                                                                            select
                                                                            defaultValue={''}
                                                                            sx={{ display: 'flex', width: "100%", marginTop: 2 }}>
                                                                            {enumlist.map((option) => (
                                                                                <MenuItem key={option} value={option}>
                                                                                    <Checkbox checked={multiSelectValue[item.field].indexOf(option) > -1} />
                                                                                    <ListItemText primary={option} />
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </Grid>)
                                                            } else {
                                                                result.push(
                                                                    <Grid item xs={6}>
                                                                        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                                                                        <TextField
                                                                            id="outlined-basic"
                                                                            placeholder={label}
                                                                            name={item.field}
                                                                            value={values[item.field]}
                                                                            error={errors[item.field] && touched[item.field]}
                                                                            onBlur={handleBlur}
                                                                            onChange={e => { handleChange(e); handleDepart(item, e) }}
                                                                            margin="normal"
                                                                            type="text"
                                                                            select
                                                                            defaultValue={''}
                                                                            sx={{ display: 'flex', width: "100%" }}>
                                                                            {enumlist.map((option) => (
                                                                                <MenuItem key={option} value={option}>
                                                                                    {option}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </TextField>
                                                                    </Grid>)
                                                            }
                                                        } else if (item.field == 'date') {
                                                            // eslint-disable-next-line no-lone-blocks
                                                            {
                                                                sched && result.push(
                                                                <Grid item xs={6}>
                                                                     <DatePicker
                                                                            label={item.label}
                                                                            value={values[item.field]}
                                                                            //maxDate={maxDate}
                                                                            sx={{ width: '100%', marginTop: 2 }}
                                                                            onChange={(value) => {
                                                                                props.setFieldValue('date', value.toDate())
                                                                            }}
                                                                        />
                                                                </Grid>)
                                                            }
                                                        } else {
                                                            result.push(
                                                                <Grid item xs={6}>
                                                                    <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                                                                    <TextField
                                                                        id="outlined-basic"
                                                                        error={errors.name && touched.name}
                                                                        placeholder={label}
                                                                        name={item.field}
                                                                        value={values[item.field]}
                                                                        onChange={e => { handleChange(e); handleDepart(item, e) }}
                                                                        onBlur={handleBlur}
                                                                        helperText=""
                                                                        margin="normal"
                                                                        InputProps={{
                                                                            readOnly: !item.edit,
                                                                        }}
                                                                        type="text"
                                                                        sx={{ display: 'flex', width: "100%" }} />
                                                                </Grid>)
                                                        }

                                                    }
                                                    return result;
                                                })()}
                                            </Grid>
                                            <DialogActions>
                                                <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                                                    Cancel
                                                </Button>
                                                <Button type="button" variant="contained" color="primary" onClick={handleReset}>
                                                    Reset
                                                </Button>
                                                <Button type="submit" variant="contained" color="primary">
                                                    Submit
                                                </Button>
                                            </DialogActions>

                                        </form>
                                    )
                                }}
                            </Formik>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionCompleted && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Thanks!</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">Inspection Updated</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Sorry! Update Inspection Failed</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">
                                {errorMessage}</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleRetry}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
            </Dialog>
        </React.Fragment>
    );
}
export default EditInspection;