import React, { useEffect, useState } from "react";
import { apiService2, apiService3 } from "../../features/apiService";
import { useNavigate, useParams } from "react-router-dom";
import { myConfig } from "../../settings";
import { Box, Button, Container, Dialog } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { timeZoneConverterDate } from "../../features/helpers/commonhelper";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const ApprovalDialog = (props) => {
    const [status, setStatus] = useState();
    const [workorder, setWo] = useState({}); //[workorder, setWorkorder
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [open, setOpen] = useState(props.open.state);

    if (props.open.state & !open) {
        setOpen(true);
        const worko = props.open.workorder;
        setWo(worko);
    }

    function handleClose() {
        setOpen(false);
        props.open.setfn(status);

      }


    const handleApprove = () => {
        setStatus("Approved");
        var data = {
            wonumber: workorder._id,
            userId: user._id,
            level: workorder.currentApproverLevel,
            status: "Approved",
        }
        apiService2("POST", myConfig.approveWorkOrderWithLoginEP, data)
            .then((response) => {
                console.log("response", response.data);
                if (response.data === "Workorder Approved") {
                    toast.success("Workorder Approved");
                    handleClose();
                } else {
                    toast.error(response.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleReject = () => {
        setStatus("Rejected");
        var data = {
            wonumber: workorder._id,
            userId: user._id,
            level: workorder.currentApproverLevel,
            status: "Rejected",
        }
        apiService2("POST", myConfig.rejectWorkOrderWithLoginEP, data)
            .then((response) => {
                console.log("response", response.data);
                if (response.data === "Workorder Rejected") {
                    toast.success("Workorder Rejected");
                    handleClose();
                } else {
                    toast.error(response.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };


    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth={'sm'}>
                <Container component="main" >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Box>
                            <h1>Workorder Approval</h1>
                            <div>
                                <p><strong>Workorder ID:</strong> {workorder.wonumber}</p>
                                <p><strong>Asset Id:</strong> {workorder.assetid}</p>
                                <p><strong>Type:</strong> {workorder.type}</p>
                                <p><strong>Requested By:</strong> {workorder.assignee}</p>
                                <p><strong>Date:</strong> {timeZoneConverterDate(workorder.startdate)}</p>
                            </div>
                            <Box sx={{
                                display: 'flex',
                            }}>
                                <Box>
                                    <Button type="button"
                                       variant="contained" color="primary"
                                        sx={{ mt: 3, mb: 2, mr: 2 }}
                                        onClick={handleApprove}>Approve</Button>

                                </Box>
                                <Box>
                                    <Button type="button"
                                        sx={{ mt: 3, mb: 2, mr: 2  }}
                                        variant="contained" color="primary" onClick={handleReject}>Reject</Button>
                                        
                                </Box>
                                <Button type="button"
                                        sx={{ mt: 3, mb: 2 }}
                                        variant="contained" color="primary" onClick={handleClose}>Close</Button>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Dialog>
        </React.Fragment>
    );
};

export default ApprovalDialog;
