import React, { useState } from "react";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
  Button,
  TextField,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { myConfig } from "../../settings";
import { userModel } from "../../schema/schema.js";
import  { apiService } from '../../features/apiService';
import { useSelector } from 'react-redux';

function initialiseFields() {return userModel};

// API End Point
const endPoint = myConfig.editUserEndPoint;
let objindex = 0;

const EditUser = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let genFormFields = initialiseFields();
  const [open, setOpen] = useState(props.open.state);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [isSubmitionError, setSubmitionError] = useState(false);
  const [formFields, setFormFields] = useState(userModel)
  const [muser, setUser] = useState(props.open.user);
  const { user } = useSelector((state) => state.auth);

  // Handle States for the dialog box
  function handleClose() {
    console.log(["handleClose: Setting open to false", open, props.open.user]);
    setOpen(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);
    //setFormFields(genFormFields);
    props.open.setfn();
    objindex = 0;
  }

  // Handle retry when submit failed
  function handleRetry() {
    //console.log(["handleClose: Setting open to false", open, props.open.user]);
    //setOpen(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);
    //setFormFields(genFormFields);
    //props.open.setfn();
    //objindex = 0;
  }

  function handleClickOpen() {
    // console.log(["Comp: open->", true])
    setSubmitionCompleted(false);
    setOpen(true);
    objindex++;
  }

  // console.log(["Here open->", props.open.state, open, isSubmitionError]);
  if (props.open.state & !open) {
    setOpen(true);
    //console.log(["OpenCheck: Setting open to false", open, props.open.user]);
  }

  return (
    <React.Fragment>
      <Dialog
        open = {open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        >
      {!isSubmitionCompleted && (
        <React.Fragment>
          <DialogTitle id="form-dialog-title">Update User </DialogTitle>
          <DialogContent>
            <DialogContentText variant="h6"></DialogContentText>
            <Formik
              //enableReinitialize 
              initialValues= {(() => {
                let ivalues = {};
                const user = props.open.user;
                
                //console.log(["Initialize fields->", user]);
                formFields.forEach( (value)=> {
                  if (value.hasOwnProperty("skip")) {
                    if (!value["skip"]) {ivalues[value['field']] = user[value['field']];}
                  } else { 
                        ivalues[value['field']] = user[value['field']];
                  }
                });
                //console.log(["Initial values->", ivalues]);
                return ivalues;
              })
              }
              onSubmit={(values, { setSubmitting }) => {
                
                const luser = props.open.user;
                let retobj = values;
                setSubmitting(true);
                {(() => {
                  
                  for (let key in luser) {
                    let tobj = {};
                    if(!retobj.hasOwnProperty(key)) {
                      retobj[key] = luser[key];
                    }
                  }
                })()};
                retobj['tenantid'] = user.tenantid;
                console.log(["Submitted->", retobj]);
                apiService(endPoint, retobj)
                  .then ( resp => {
                    console.log("User updated ");
                    setSubmitionCompleted(true);
                  })
                  .catch (error => {
                    console.error(error);
                    setSubmitionError(true);
                    setSubmitionCompleted(true);
                  }) 
              }}
              // Need to expand the validation schema more later
              validationSchema={Yup.object().shape({ })}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    {(() => {
                      let result = [];
                      let skipflag = false;
                      //const user = props.open.user;
                      const suser = props.initialValues();
                      console.log(["building form->", muser, suser])
                      formFields.forEach(function (vitem) {
                        let key = vitem['field'];
                        
                        // console.log(['building form->',key]);
                        // console.log(vitem);
                        skipflag = false;
                        if (vitem.hasOwnProperty("fkey")) {
                          if (vitem["fkey"]) {skipflag = true;}
                        }
                        if (vitem.hasOwnProperty("skip")) {
                          if (vitem["skip"]) {skipflag = true;}
                        }
                        if (!vitem.hasOwnProperty("enum") && !skipflag) {
                            result.push(
                              <TextField
                                id="outlined-basic"
                                error={errors.name && touched.name}
                                label= { key }
                                name= { key }
                                value={values.key}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText=""
                                margin="normal"
                                type="text"
                                defaultValue={suser[key]}
                              />
                          )
                        if (vitem.hasOwnProperty("subfield")) {
                          let skey = vitem['subfield']
                          //console.log(["skey->",skey]);
                          result.push(
                            <TextField
                              id="outlined-basic"
                              error={errors.name && touched.name}
                              label= { skey }
                              name= { skey }
                              value={values.skey}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText=""
                              margin="normal"
                              type="text"
                          />
                      )}
                          } else if (!skipflag) {
                            //console.log(["key ->", key]);
                            result.push(
                              <Select
                                id="outlined-basic"
                                error={errors.name && touched.name}
                                label={ key }
                                name={ key }
                                value= {values.key}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText=""
                                margin="normal"
                                sx={{ display:'flex'}}
                                defaultValue = ""
                                >
                                {vitem["enum"].map((ivalue ) => (
                                  <MenuItem key={ivalue} value={ivalue}>
                                    {ivalue}
                                  </MenuItem>
                                ))}
                              </Select>
                              )
                          }
                        })
                        return result;
                      })()}

                    <DialogActions>
                      <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button type="button" variant="contained" color="primary"
                        onClick={handleReset}
                        disabled={!dirty || isSubmitting}
                      >
                        Reset
                      </Button>
                      <Button type="submit" variant="contained" color="primary" disabled={!dirty || isSubmitting}>
                        Submit
                      </Button>
                    </DialogActions>
                  </form>
                );
              }}
            </Formik>
          </DialogContent>
        </React.Fragment>
      )}
      {isSubmitionCompleted && (
        <React.Fragment>
          <DialogTitle id="form-dialog-title">Thanks! User updated</DialogTitle>
          <DialogContent>
            <DialogContentText variant="h6">Thanks</DialogContentText>
            <DialogActions>
              <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                Back
              </Button>
            </DialogActions>
          </DialogContent>
        </React.Fragment>
      )}
      {isSubmitionError && (
        <React.Fragment
        >
          <DialogTitle id="form-dialog-title">Sorry! Update User Failed</DialogTitle>
          <DialogContent>
            <DialogContentText variant="h6">Try Again Later</DialogContentText>
            <DialogActions>
              <Button type="button" variant="contained" color="secondary" onClick={handleRetry}>
                Back
              </Button>
            </DialogActions>
          </DialogContent>
        </React.Fragment>
      )}
    </Dialog>
  </React.Fragment>
  );
}

export default EditUser;