import React, { useState, useEffect } from "react";
import { Grid, IconButton, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import axios from "axios";
import {
    Button,
    withStyles,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { myConfig } from "../../settings";
import { useSelector } from 'react-redux';
import { procurementModel } from "../../schema/schema";
import { apiService, apiService2 } from "../../features/apiService";
import { formCreator } from "../../features/helpers/formhelper";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
// API End Point
const endPoint = myConfig.addDeptEP;
let fakecount = 0;
const Accessor = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(props.open.state);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [isSubmitionError, setSubmitionError] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const [errorMessage, setErrorMessage] = useState('');
    const [inventoryList, setInventoryList] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const [itemname, setItemName] = useState('');
    const [quantity, setQuantity] = useState('');
    const [itemList, setItemList] = useState([]);
    const [preferedstore, setPreferedstore] = useState('');
    const [storeConfigList, setStoreConfigList] = useState([]);

    if (props.open.state & !open) {
        setOpen(true);
        setItemList([]);
        const workorder = props.open.workorder;
        if (workorder.itemlist) {
            setItemList(workorder.itemlist);
        }
    }

    // Handle States for the dialog box
    function handleClose() {
        setOpen(false);
        setSubmitionError(false);
        setSubmitionCompleted(false);
        props.open.setfn();
    }

    function handleTryAgain() {
        setSubmitionError(false);
        setErrorMessage('');
    }

    useEffect(() => {
        apiService2('GET', myConfig.getInventoryEP)
            .then(response => {
                setInventoryList(response.data)
                if (response.data.length == 0) {
                    toast.error("Inventory list Empty, Please add inventory first");
                }
            })
            .catch(error => {
                console.error(error);
            })
    }, []);

    let modInventoryList = [];
    for (const cont of inventoryList) {
        modInventoryList.push(cont.itemname);
    }

    useEffect(() => {
        apiService2('GET', myConfig.getStoreConfigEP)
            .then(response => {
                var storeConfigList = response.data;
                setStoreConfigList(storeConfigList);
            })
            .catch(error => {
                console.error(error);
            });
    }, [open]);

    let modStoreConfigList = [];
    for (const cont of storeConfigList) {
        modStoreConfigList.push(cont.name);
    }

    function handleEditRow(index, row) {
        setItemName(row.itemname);
        setQuantity(row.quantity);
        let newList = itemList.filter((item, i) => i !== index);
        setItemList(newList);
    }

    function handleDeleteRow(index) {
        let newList = itemList.filter((item, i) => i !== index);
        setItemList(newList);
    }

    function handleAddItem() {
        if (!itemList.find(x => x.itemname == itemname)) {
            let item = inventoryList.find(x => x.itemname == itemname);
            let data = {
                itemname: itemname, itemcode: item.itemcode, quantity: quantity, unitcost: item.unitcost
            }
            setItemList([...itemList, data]);
            setItemName('');
            setQuantity('');
        } else {
            toast.error("Item already added");
            setItemName('');
            setQuantity('');
            return;
        }
    }


    return (
        <React.Fragment>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'md'}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                {!isSubmitionCompleted && !isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Add Accessory</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6"></DialogContentText>
                            <Formik
                                key={fakecount}
                                enableReinitialize
                                initialValues={initialValues}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(true);
                                    console.log(values);
                                    const workorder = props.open.workorder;
                                    values['_id'] = workorder._id;
                                    const store = storeConfigList.find(x => x.name == preferedstore);
                                    values['preferedstore'] = store._id;
                                    values['itemlist'] = itemList;
                                    values['totalsparescost'] = 0;
                                    apiService2('POST', myConfig.addAccessorWOEndPoint, values)
                                        .then(resp => {
                                            if (resp.data.message) {
                                                setErrorMessage(resp.data.message);
                                                setSubmitionError(true);
                                            } else {
                                                setSubmitionCompleted(true);
                                            }
                                        })
                                        .catch(error => {
                                            console.log(["Procurement Add Failed->", error]);
                                            setSubmitionError(true);
                                            setSubmitionCompleted(true);
                                        });
                                }}
                            >
                                {props => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        dirty,
                                        isSubmitting,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        handleReset,
                                        resetForm,
                                        setFieldValue
                                    } = props;
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label={'Stock Preference Store'}
                                                        name={'redMax'}
                                                        value={preferedstore}
                                                        onChange={(e) => {
                                                            setPreferedstore(e.target.value)
                                                        }}
                                                        onBlur={handleBlur}
                                                        helperText={errors.name && "Incorrect entry."}
                                                        margin="normal"
                                                        type="text"
                                                        select
                                                        defaultValue={''}
                                                        sx={{ display: 'flex', width: "100%" }}>
                                                        {modStoreConfigList.map((option) => (
                                                            <MenuItem key={option} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={4} sm={5}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label={'Item Name'}
                                                        name={'redMax'}
                                                        value={itemname}
                                                        onChange={(e) => {
                                                            setItemName(e.target.value)
                                                        }}
                                                        onBlur={handleBlur}
                                                        helperText={errors.name && "Incorrect entry."}
                                                        margin="normal"
                                                        type="text"
                                                        select
                                                        defaultValue={''}
                                                        sx={{ display: 'flex', width: "100%" }}>
                                                        {modInventoryList.map((option) => (
                                                            <MenuItem key={option} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={4} sm={5}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label={'Quantity'}
                                                        name={'redMax'}
                                                        value={quantity}
                                                        onChange={(e) => {
                                                            setQuantity(e.target.value)
                                                        }}
                                                        onBlur={handleBlur}
                                                        helperText={errors.name && "Incorrect entry."}
                                                        margin="normal"
                                                        type="text"
                                                        sx={{ display: 'flex', width: "100%" }} />
                                                </Grid>
                                                <Grid item xs={4} sm={2}>
                                                    <Button type="button" variant="contained" color="primary" onClick={handleAddItem} >
                                                        Add
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow style={{ textAlign: "left" }}>
                                                            <TableCell>Item Name</TableCell>
                                                            <TableCell>Quantity</TableCell>
                                                            <TableCell>Unit Cost</TableCell>
                                                            <TableCell>Action</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {itemList.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell component="th" scope="row">
                                                                    {row.itemname}
                                                                </TableCell>
                                                                <TableCell > {row.quantity}</TableCell>
                                                                <TableCell > {row.unitcost}</TableCell>
                                                                <TableCell >
                                                                    <IconButton onClick={() => handleEditRow(index, row)}>
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                    <IconButton onClick={() => handleDeleteRow(index)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <DialogActions>
                                                <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                                                    Cancel
                                                </Button>
                                                <Button type="button" variant="contained" color="primary"
                                                    onClick={() => resetForm()}
                                                    disabled={!dirty || isSubmitting}>
                                                    Reset
                                                </Button>
                                                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                                                    Submit
                                                </Button>
                                            </DialogActions>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionCompleted && !isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title"></DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">Spares Added Successfully</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Sorry! Add Spares Failed</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6"> {errorMessage ? errorMessage : 'Try Again Later'}</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleTryAgain}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
            </Dialog>
        </React.Fragment>
    );
}

export default Accessor;