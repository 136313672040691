import React, { useEffect, useState } from "react";
import { apiService3 } from "../../features/apiService";
import { useNavigate, useParams } from "react-router-dom";
import { myConfig } from "../../settings";
import { Box, Button, Container, TextField } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { timeZoneConverterDate } from "../../features/helpers/commonhelper";
import { toast } from "react-toastify";

const ApprovalScreen = () => {
    const [status, setStatus] = useState();
    const [workorder, setWo] = useState({}); //[workorder, setWorkorder
    const { id, tenantid, user, level } = useParams();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [values, setValues] = useState();
    const navigate = useNavigate();
    const handleApprove = () => {
        setStatus("Approved");
        var data = {
            wonumber: id,
            tenantid: tenantid,
            user: user,
            level: level,
            status: "Approved",
            remarks: values
        }
        apiService3("POST", myConfig.approveWorkOrderEP, data)
            .then((response) => {
                console.log("response", response.data);
                if (response.data === "Workorder Approved") {
                    toast.success("Workorder Approved");
                    navigate('/')
                } else {
                    toast.error(response.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleReject = () => {
        setStatus("Rejected");
        var data = {
            wonumber: id,
            tenantid: tenantid,
            user: user,
            level: level,
            status: "Rejected",
            remarks: values
        }
        apiService3("POST", myConfig.rejectWorkOrderEP, data)
            .then((response) => {
                if (response.data === "Workorder Rejected") {
                    toast.success("Workorder Rejected");
                    navigate('/')
                } else {
                    toast.error(response.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        var data = { wonumber: id, tenantid: tenantid };
        apiService3("POST", myConfig.getWorkOrderById, data)
            .then((response) => {
                var resources = response.data;
                if (resources) {
                    setWo(resources);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    return (
        // <div className="workorder-approval">
        //   <h1>Workorder Approval</h1>
        //   <div className="workorder-details">
        //     <p><strong>Workorder ID:</strong> {workorder.id}</p>
        //     <p><strong>Description:</strong> {workorder.description}</p>
        //     <p><strong>Status:</strong> {status}</p>
        //     <p><strong>Requested By:</strong> {workorder.requestedBy}</p>
        //     <p><strong>Date:</strong> {workorder.date}</p>
        //   </div>
        //   <div className="workorder-actions">
        //     <button onClick={handleApprove} className="approve-btn">Approve</button>
        //     <button onClick={handleReject} className="reject-btn">Reject</button>
        //   </div>
        // </div>
        <Container component="main" maxWidth="lg">
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "100vh",
                    width: "100%",
                }}
            >
                <Box>
                    <h1>Workorder Approval</h1>
                    <div>
                        <p><strong>Workorder ID:</strong> {workorder.wonumber}</p>
                        <p><strong>Asset Id:</strong> {workorder.assetid}</p>
                        <p><strong>Type:</strong> {workorder.type}</p>
                        <p><strong>Requested By:</strong> {workorder.assignee}</p>
                        <p><strong>Date:</strong> {timeZoneConverterDate(workorder.startdate)}</p>
                        <TextField
                            id="outlined-basic"
                            label='Notes'
                            name={'notes'}
                            value={values}
                            onChange={(e) => setValues(e.target.value)}
                            margin="normal"
                            type="text"
                            multiline
                            sx={{ display: 'flex', width: '100%', borderColor: 'white', border: '1px solid #ccc'}}
                        />
                    </div>
                    <Box sx={{
                        display: 'flex',
                    }}>
                        <Box>
                            <Button type="button"
                                variant="contained"
                                color="primary"
                                sx={{ mt: 3, mb: 2, mr: 2 }}
                                onClick={handleApprove}>Approve</Button>

                        </Box>
                        <Box>
                            <Button type="button"
                                sx={{ mt: 3, mb: 2 }}
                                variant="contained"  color="primary"
                                 onClick={handleReject}>Reject</Button>
                        </Box>

                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default ApprovalScreen;
