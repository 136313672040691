import { toast } from 'react-toastify';
import { apiService, apiService2 } from '../apiService.js';
import { myConfig } from "../../settings.js";
import { Box, IconButton, useTheme } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';


// Get all the departments configured, also return just the list of department names
export const getDepts = (user) => {
  let deptList = [];
  let modDeptList = [];
  const rpromise = apiService(myConfig.getDeptEP, { tenantid: user.tenantid }, user)
    .then(response => {
      deptList = response.data;
      if (response.data.length == 0) {
        //console.log("Dept List->", response.data.length);
        toast.error("Department list Empty, Please add department first");
      } else {
        for (const deptItem of deptList) {
          modDeptList.push(deptItem.name);
        }
      }
      return [deptList, modDeptList];
    })
    .catch(error => {
      console.error(error);
      return [deptList, modDeptList];
    })
  return rpromise;
}

// Get all the assets, also return just the list of assets of format <assetname-assetid>
export const getAssets = (user) => {
  let modAssetList = [];
  let assetList = [];
  const rpromise = apiService(myConfig.getLogicalAssetsEP, { tenantid: user.tenantid }, user)
    .then(response => {
      assetList = response.data;
      assetList.forEach((item) => {
        modAssetList.push(item.name + "-" + item.assetid);
      })
      //console.log("Logical Assets->", assetList, modAssetList);
      return [assetList, modAssetList];
    })
    .catch(error => {
      console.error("Received Error->", error);
      return [assetList, modAssetList];
    })
  return rpromise;
}


export const getConfig = (user,name) => {
  const rpromise = apiService2('POST', myConfig.getConfigurationFilter, { name: name },)
    .then(response => {
      return response.data?.length > 0 ? response.data[0].value : '';
    })
    .catch(error => {
      console.error("Received Error->", error);
      return '';
    })
  return rpromise;
}


export function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}