import { Box, useTheme, Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog, InputLabel, Select, MenuItem } from "@mui/material";
import { tokens } from "../../theme";
import EditIcon from '@mui/icons-material/Edit';
import React, { useState, useEffect } from "react";
import { myConfig } from "../../settings.js";
import { useSelector } from 'react-redux';
import { apiService2 } from '../../features/apiService';
import { MaterialReactTable } from "material-react-table";
import { workshopModel } from "../../schema/schema";

import { timeZoneConverter } from "../../features/helpers/commonhelper.js";
import { toast } from "react-toastify";
import { tableView } from "../../features/helpers/table.js";

const Inhouse = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user } = useSelector((state) => state.auth);
    const columns = [];

    const [refresh, setRefresh] = useState(false);
    const [workshopList, setWorkshopList] = useState([]);
    const [workshop, setWorkshop] = useState([]);
    const [selectedrow, setRow] = useState(-1);
    const [rowSelection, setRowSelection] = useState({});
    const [open, setOpen] = useState(false);
    const [breakBownCodeList, setBreakBownCodeList] = useState([]);
    const [state, setState] = useState();
    const [breakdowncode, setBreakdowncode] = useState();


    for (const item of workshopModel) {
        if (item.hasOwnProperty("view")) {
            if (!item.view) continue;
        }
        columns.push({ accessorKey: item['field'], header: item['label'], size: 150 });
    }

    useEffect(() => {
        apiService2('POST', myConfig.getWorkshopEP, { isExternal: false })
            .then(response => {
                var workshopList = response.data;
                workshopList?.forEach(res => {
                    res.cdate = res.createdOn != null ? timeZoneConverter(res.createdOn) : '';
                    res.indate = res.indate != null ? timeZoneConverter(res.indate) : '';
                    res.outdate = res.outdate != null ? timeZoneConverter(res.outdate) : '';
                })
                setWorkshopList(workshopList);
            })
            .catch(error => {
                console.error(error);
            });
    }, [refresh]);

    useEffect(() => {
        const wrnumber = Object.keys(rowSelection)[0];
        const selected = Object.values(rowSelection)[0];
        let index = 0;
        if (selected) {
            for (const temp of workshopList) {
                if (temp._id == wrnumber) {
                    setWorkshop(temp);
                    setRow(index);
                    break;
                }
                ++index;
            }
        } else {
            setWorkshop({});
        }
    }, [rowSelection]);

    useEffect(() => {
        apiService2('GET', myConfig.getBreakBownCodeEP)
            .then(response => {
                setBreakBownCodeList(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, [open]);

    let modStateList = ['Breakdown', 'Ready'];

    let modBreakDownList = [];
    for (const item of breakBownCodeList.filter((item) => item.type == 'BR Code')) {
        modBreakDownList.push(item.code + ": " + item.description);
    }

    function handleOpen() {
        if (Object.keys(rowSelection).length === 0) {
            toast.error("Please select a row to update");
            return;
        }
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    function updateWorkshop() {
        const wrnumber = Object.keys(rowSelection)[0];
        const selected = Object.values(rowSelection)[0];
        if (selected) {
            console.log(wrnumber);
            const data = {
                _id: wrnumber,
                state: state,
                breakdowncode: breakdowncode
            };
            console.log(data);
            apiService2('POST', myConfig.updateWorkshopEP, data)
                .then(response => {
                    toast.success("Workshop updated successfully");
                    setRefresh(!refresh);
                    setOpen(false);
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }

    function CustomToolbar() {
        return (
            <Box className="mb-1">
                <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={handleOpen} >
                    Update
                </Button>
            </Box>
        );
    }



    return (
        <Box m="10px 20px 20px 20px">
            <Box
                m="20px 0 0 0"
                height="70vh">
                {tableView(false, columns, workshopList, setRowSelection, rowSelection, '_id')}
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth={'xs'}
                >
                    <DialogTitle id="form-dialog-title">Inhouse From </DialogTitle>
                    <DialogContent>
                        <DialogContentText variant="h6"></DialogContentText>
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            m="20px 0 0 0"
                        >
                            <Box width="100%" m='0 0 20px 0px'>
                                <InputLabel id="simple-label">State</InputLabel>
                                <Select
                                    id="outlined-basic"
                                    label={'State'}
                                    name={'state'}
                                    onChange={(e) => {
                                        setState(e.target.value)
                                    }}
                                    value={state}
                                    margin="normal"
                                    sx={{ display: 'flex', width: '100%' }}>
                                    {modStateList.map((ivalue) => (
                                        <MenuItem key={ivalue} value={ivalue}>
                                            {ivalue}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                            <Box width="100%" m='0 0 20px 0px'>
                                <InputLabel id="simple-label">Breakdown Code</InputLabel>
                                <Select
                                    id="outlined-basic"
                                    label={'Breakdown Code'}
                                    name={'state'}
                                    onChange={(e) => {
                                        setBreakdowncode(e.target.value)
                                    }}
                                    value={breakdowncode}
                                    margin="normal"
                                    sx={{ display: 'flex', width: '100%' }}>
                                    {modBreakDownList.map((ivalue) => (
                                        <MenuItem key={ivalue} value={ivalue}>
                                            {ivalue}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button type="button" variant="contained" color="primary" onClick={updateWorkshop}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
};

export default Inhouse;
