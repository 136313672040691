import { useSelector } from "react-redux";

export const getAccess = (user, id) => {
    if (user) {
        if(user.access == 'ADMIN') return { isCreate: true, isView: true, isEdit: true, isDeleted: true };
        if (user.accesslist.length > 0) {
            let access = user.accesslist.find(item => item.id == id);
            if (access) {
                return access;
            } else {
                return { isCreate: false, isView: false, isEdit: false, isDeleted: false }
            }
        }
    }
};