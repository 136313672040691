
import React, { useState } from "react";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
const FailurePreventionView = (props) => {
    const theme = useTheme();
    const [open, setOpen] = useState(props.open.state);
    const [maxWidth, setMaxWith] = useState('lg');
    const [employeeData, setEmployeeData] = useState([])
    const [failureDetails, setFailureDetails] = useState({})

    if (props.open.state & !open) {
        const failurePrevention = props.open.failurePrevention;
        console.log('failurePrevention',failurePrevention);
        
        const list = failurePrevention['failureDetails']
        setFailureDetails(failurePrevention)
        setEmployeeData([...list])
        setMaxWith('lg')
    }

    function handleClose() {
        setOpen(false);

        props.open.setfn();
    }

    if (props.open.state & !open) {
        setOpen(true);
    }

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={maxWidth}
            onClose={handleClose}
            aria-labelledby="form-dialog-title">
            <React.Fragment>
                <DialogTitle id="form-dialog-title">View Failure Prevention </DialogTitle>
                <DialogContent>
                    <DialogContentText variant="h6">
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    Workorder Id
                                </Typography>
                                <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                                    {failureDetails['workorderId']['wonumber']}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    Asset Id
                                </Typography>
                                <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                                {failureDetails['assetId']['assetid']}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    BO Time
                                </Typography>
                                <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                                {failureDetails['duration']}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    Bo Code
                                </Typography>
                                <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                                {failureDetails['code']}
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContentText>

                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 350 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow style={{ textAlign: "left" }}>
                                        <TableCell>Fault Prevention Cause</TableCell>
                                        <TableCell>Action Taken</TableCell>
                                        <TableCell>Maintenance Change</TableCell>
                                        <TableCell>Inspection Changes</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {employeeData.map(({ preventionCase, actionTaken, maintaenanceChage, inspectionChange }, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{preventionCase}</TableCell>
                                            <TableCell>{actionTaken}</TableCell>
                                            <TableCell>{maintaenanceChage}</TableCell>
                                            <TableCell>{inspectionChange}</TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>

                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={handleClose}>
                            Cancel
                        </Button>
                    </DialogActions>
                </DialogContent>
            </React.Fragment>
        </Dialog>
    );
};
export default FailurePreventionView;