import React, { useState } from "react";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import {
  Button,
  withStyles,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { myConfig } from "../../../settings";
import { facilityModel } from "../../../schema/schema.js";
import { apiService, apiService2 } from '../../../features/apiService';
import { useSelector } from 'react-redux';
import { CURRENCYLIST } from "../../../data/currency";
import { formCreator } from "../../../features/helpers/formhelper";


// API End Point
const endPoint = myConfig.addFacilityEP;

let fakecount = 0;

const AddFacility = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(props.open.state);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [isSubmitionError, setSubmitionError] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [facilityList, setFacility] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [initialValues, setInitialValues] = useState({});
  const [formFields, setFormFields] = useState(facilityModel);


  function getInitialValues() {
    let initialValues = {};
    for (const field of formFields) {
      initialValues[field.field] = '';
    }
    setInitialValues(initialValues);
  }

  function getFacilityList() {
    apiService2('GET', myConfig.getFacilitiesEP, { tenantid: user.tenantid },)
      .then(response => {
        setFacility(response.data);
      })
      .catch(error => {
        console.error(error);
      })
  }

  let modFacilityList = [];
  facilityList.forEach((item) => {
    modFacilityList.push(item.name);
  })

  let modCurrencyList = [];
  CURRENCYLIST.forEach((item) => {
    modCurrencyList.push(item.currency);
    modCurrencyList.sort();
  })

  // Handle States for the dialog box
  function handleClose() {
    setOpen(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);
    props.open.setfn();


  }

  if (props.open.state & !open) {
    setOpen(true);
    getInitialValues();
    getFacilityList();
    if (user.access == 'ADMIN') {
      setTypeList(["Facility", "Unit", "Sub Unit", "Section"])
    } else {
      setTypeList(["Unit", "Sub Unit", "Section"])
    }
  }

  function handleTryAgain() {
    setSubmitionError(false);
    setErrorMessage('');
  }

  function handleSelect() {

  }

  const valueDB = { "type": typeList, "currency": modCurrencyList, "parent": modFacilityList };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'xs'}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        {!isSubmitionCompleted && !isSubmitionError && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Add Facility</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6"></DialogContentText>
              <Formik
                key={fakecount}
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  apiService2('POST', endPoint, values)
                    .then(resp => {
                      if (resp.data.message) {
                        setErrorMessage(resp.data.message);
                        setSubmitionError(true);
                      } else {
                        setSubmitionCompleted(true);
                      }
                    })
                    .catch(error => {
                      setSubmitionError(true);
                      setSubmitionCompleted(true);
                    })
                }}
                // Need to expand the validation schema more later
                validationSchema={Yup.object().shape({
                  name: Yup.string().required("Facility Name Required"),
                  type: Yup.string().required("Facility Type Required"),
                })} >
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      {formCreator("Complex", 'add', formFields, initialValues, values, handleChange, handleBlur, touched, errors, valueDB, handleSelect)}
                      <DialogActions>
                        <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                          Cancel
                        </Button>
                        <Button type="button" variant="contained" color="primary"
                          onClick={handleReset}
                          disabled={!dirty || isSubmitting}
                        >
                          Reset
                        </Button>
                        <Button type="submit" variant="contained" color="primary" disabled={!dirty || isSubmitting}>
                          Submit
                        </Button>
                      </DialogActions>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionCompleted && !isSubmitionError && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title"></DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Thanks! Facility successfully added</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionError && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Sorry! Add Facility Failed</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">  {errorMessage ? errorMessage : 'Try Again Later'}</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleTryAgain}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  );
}

export default AddFacility;