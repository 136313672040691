import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { DialogActions, Grid, Typography, useTheme } from "@mui/material";
import { Box, Button, Dialog } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useSelector } from 'react-redux';
import { assetModel } from "../../schema/schema.js";
import { timeZoneConverter } from "../../features/helpers/commonhelper.js";
import { Image } from "@mui/icons-material";

// Globals
const EXCLUDE_ATTR = ["_id", "params", "__v", "inspectionList"];

const AssetView = (props) => {
  const location = useLocation();
  const [open, setOpen] = useState(props.open.state);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useSelector((state) => state.auth);
  const [asset, setAsset] = React.useState([]);
  const [formValues, setFormValues] = React.useState(assetModel);

  // Build the rows for the DG

  function getInitialValues() {
    var fieldvalues = [];
    var id = 0;
    for (const [key, value] of Object.entries(props.open.asset)) {
      if (value != null && value != '') {
        if (!EXCLUDE_ATTR.includes(key) && key != 'specifications' && key != 'lastinspection' && key != 'geo' && key != 'subRows' && key != 'statetimestamps' && key != 'sensordata') {
          if (key.includes("date") || key.includes("time") || key.includes("from") || key.includes("to") || key.includes("timestamp") || key.includes("createdOn")) {
            fieldvalues.push({ "id": id, "Asset Attribute": key, "Value": timeZoneConverter(value) });
          } else if (value == true || value == false) {
            fieldvalues.push({ "id": id, "Asset Attribute": key, "Value": value == true ? 'Yes' : 'No' });
          } else {
            fieldvalues.push({ "id": id, "Asset Attribute": key, "Value": value });
          }
          ++id;
        }
      }
    }
    setAsset(fieldvalues);
  }

  // Handle States for the dialog box
  function handleClose() {
    // console.log("handleClose: Setting open to false");
    setOpen(false);
    props.open.setfn();
  }

  // To open this dialog box
  if (props.open.state & !open) {
    setOpen(true);
    getInitialValues();
  }

  //

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg">
        <Box m="5px" p="10px">
          <Header
            title="ASSET DETAILS"
            subtitle={"Name: " + props.open.asset.name + " ID: " + props.open.asset.assetid}
          />
          <React.Fragment>
            <Box
              m="40px 0 0 0"
              height="70vh"
              p="10px">
              <Grid container spacing={4}>
                {
                  asset.map((item, index) => {
                    return (
                      <Grid item xs={3} key={index}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          {item['Asset Attribute']?.toUpperCase()}
                        </Typography>
                        {item['Asset Attribute']?.toUpperCase() != 'FILEURL' ? <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                          {item['Value'] != null ? item['Value'] : "N/A"}
                        </Typography> : <img src={item['Value']} alt="Asset Image" width={50} />}
                      </Grid>

                    );
                  })
                }
              </Grid>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Close
                </Button>
              </DialogActions>
            </Box>
          </React.Fragment>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
export default AssetView;