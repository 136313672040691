export const CURRENCYLIST =[
    {
        currency: 'USD',
        id: 'USD'
    },
    {
        currency: 'EUR',
        id: 'EUR'
    },
    {
        currency: 'GBP',
        id: 'GBP'
    },
    {
        currency: 'INR',
        id: 'INR'
    },
    {
        currency: 'AUD',
        id: 'AUD'
    },
    {
        currency: 'CAD',
        id: 'CAD'
    },
    {
        currency: 'SGD',
        id: 'SGD'
    },
    {
        currency: 'CHF',
        id: 'CHF'
    },
    {
        currency: 'MYR',
        id: 'MYR'
    },
    {
        currency: 'JPY',
        id: 'JPY'
    },
    {
        currency: 'CNY',
        id: 'CNY'
    },
    {
        currency: 'NZD',
        id: 'NZD'
    },
    {
        currency: 'THB',
        id: 'THB'
    },
    {
        currency: 'HUF',
        id: 'HUF'
    },
    {
        currency: 'AED',
        id: 'AED'
    },
    {
        currency: 'HKD',
        id: 'HKD'
    },
    {
        currency: 'MXN',
        id: 'MXN'
    },
    {
        currency: 'ZAR',
        id: 'ZAR'
    },
    {
        currency: 'PHP',
        id: 'PHP'
    },
    {
        currency: 'SEK',
        id: 'SEK'
    },
    {
        currency: 'IDR',
        id: 'IDR'
    },
    {
        currency: 'SAR',
        id: 'SAR'
    },
    {
        currency: 'BRL',
        id: 'BRL'
    },
    {
        currency: 'TRY',
        id: 'TRY'
    },
    {
        currency: 'KES',
        id: 'KES'
    },
    {
        currency: 'KRW',
        id: 'KRW'
    },
    {
        currency: 'EGP',
        id: 'EGP'
    },
    {
        currency: 'IQD',
        id: 'IQD'
    },
    {
        currency: 'NOK',
        id: 'NOK'
    },
    {
        currency: 'KWD',
        id: 'KWD'
    },
    {
        currency: 'RUB',
        id: 'RUB'
    },
    {
        currency: 'DKK',
        id: 'DKK'
    },
    {
        currency: 'PKR',
        id: 'PKR'
    },
    {
        currency: 'ILS',
        id: 'ILS'
    },
    {
        currency: 'PLN',
        id: 'PLN'
    },
    {
        currency: 'QAR',
        id: 'QAR'
    },
    {
        currency: 'XAU',
        id: 'XAU'
    },
    {
        currency: 'OMR',
        id: 'OMR'
    },
    {
        currency: 'COP',
        id: 'COP'
    },
    {
        currency: 'CLP',
        id: 'CLP'
    },
    {
        currency: 'TWD',
        id: 'TWD'
    },
    {
        currency: 'ARS',
        id: 'ARS'
    },
    {
        currency: 'CZK',
        id: 'CZK'
    },
    {
        currency: 'VND',
        id: 'VND'
    },
    {
        currency: 'MAD',
        id: 'MAD'
    },
    {
        currency: 'JOD',
        id: 'JOD'
    },
    {
        currency: 'BHD',
        id: 'BHD'
    },
    {
        currency: 'XOF',
        id: 'XOF'
    },
    {
        currency: 'LKR',
        id: 'LKR'
    },
    {
        currency: 'UAH',
        id: 'UAH'
    },
    {
        currency: 'NGN',
        id: 'NGN'
    },
    {
        currency: 'TND',
        id: 'TND'
    },
    {
        currency: 'UGX',
        id: 'UGX'
    },
    {
        currency: 'RON',
        id: 'RON'
    },
    {
        currency: 'BDT',
        id: 'BDT'
    },
    {
        currency: 'PEN',
        id: 'PEN'
    },
    {
        currency: 'GEL',
        id: 'GEL',
    },
    {
        currency: 'XAF',
        id: 'XAF'
    },
    {
        currency: 'FJD',
        id: 'FJD'
    },
    {
        currency: 'VEF',
        id: 'VEF'
    },
    {
        currency: 'VES',
        id: 'VES'
    },
    {
        currency: 'BYN',
        id: 'BYN'
    },
    {
        currency: 'HRK',
        id: 'HRK'
    },
    {
        currency: 'UZS',
        id: 'UZS'
    },
    {
        currency: 'BGN',
        id: 'BGN'
    },
    {
        currency: 'DZD',
        id: 'DZD'
    },
    {
        currency: 'IRR',
        id: 'IRR'
    },
    {
        currency: 'DOP',
        id: 'DOP'
    },
    {
        currency: 'ISK',
        id: 'ISK'
    },
    {
        currency: 'XAG',
        id: 'XAG'
    },
    {
        currency: 'CRC',
        id: 'CRC'
    },
    {
        currency: 'SYP',
        id: 'SYP'
    },
    {
        currency: 'LYD',
        id: 'LYD'
    },
    {
        currency: 'JMD',
        id: 'JMD'
    },
    {
        currency: 'MUR',
        id: 'MUR'
    },
    {
        currency: 'GHS',
        id: 'GHS'
    },
    {
        currency: 'AOA',
        id: 'AOA'
    },
    {
        currency: 'UYU',
        id: 'UYU'
    },
    {
        currency: 'AFN',
        id: 'AFN'
    },
    {
        currency: 'LBP',
        id: 'LBP'
    },
    {
        currency: 'XPF',
        id: 'XPF'
    },
    {
        currency: 'TTD',
        id: 'TTD'
    },
    {
        currency: 'TZS',
        id: 'TZS'
    },
    {
        currency: 'ALL',
        id: 'ALL'
    },
    {
        currency: 'XCD',
        id: 'XCD'
    },
    {
        currency: 'GTQ',
        id: 'GTQ'
    },
    {
        currency: 'NPR',
        id: 'NPR'
    },
    {
        currency: 'BOB',
        id: 'BOB'
    },
    {
        currency: 'ZWD',
        id: 'ZWD'
    },
    {
        currency: 'BBD',
        id: 'BBD'
    },
    {
        currency: 'CUC',
        id: 'CUC'
    },
    {
        currency: 'LAK',
        id: 'LAK'
    },
    {
        currency: 'BND',
        id: 'BND'
    },
    {
        currency: 'BWP',
        id: 'BWP'
    },
    {
        currency: 'HNL',
        id: 'HNL'
    },
    {
        currency: 'PYG',
        id: 'PYG'
    },
    {
        currency: 'ETB',
        id: 'ETB'
    },
    {
        currency: 'NAD',
        id: 'NAD'
    },
    {
        currency: 'PGK',
        id: 'PGK'
    },
    {
        currency: 'SDG',
        id: 'SDG'
    },
    {
        currency: 'MOP',
        id: 'MOP'
    },
    {
        currency: 'NIO',
        id: 'NIO'
    },
    {
        currency: 'BMD',
        id: 'BMD'
    },
    {
        currency: 'KZT',
        id: 'KZT'
    },
    {
        currency: 'PAB',
        id: 'PAB'
    },
    {
        currency: 'BAM',
        id: 'BAM'
    },
    {
        currency: 'GYD',
        id: 'GYD'
    },
    {
        currency: 'YER',
        id: 'YER'
    },
    {
        currency: 'MGA',
        id: 'MGA'
    },
    {
        currency: 'KYD',
        id: 'KYD'
    },
    {
        currency: 'MZN',
        id: 'MZN'
    },
    {
        currency: 'RSD',
        id: 'RSD'
    },
    {
        currency: 'SCR',
        id: 'SCR'
    },
    {
        currency: 'AMD',
        id: 'AMD'
    },
    {
        currency: 'SBD',
        id: 'SBD'
    },
    {
        currency: 'AZN',
        id: 'AZN'
    },
    {
        currency: 'SLL',
        id: 'SLL'
    },
    {
        currency: 'TOP',
        id: 'TOP'
    },
    {
        currency: 'BZD',
        id: 'BZD'
    },
    {
        currency: 'MWK',
        id: 'MWK'
    },
    {
        currency: 'GMD',
        id: 'GMD'
    },
    {
        currency: 'BIF',
        id: 'BIF'
    },
    {
        currency: 'SOS',
        id: 'SOS'
    },
    {
        currency: 'HTG',
        id: 'HTG'
    },
    {
        currency: 'GNF',
        id: 'GNF'
    },
    {
        currency: 'MVR',
        id: 'MVR'
    },
    {
        currency: 'MNT',
        id: 'MNT'
    },
    {
        currency: 'CDF',
        id: 'CDF'
    },
    {
        currency: 'STN',
        id: 'STN'
    },
    {
        currency: 'TJS',
        id: 'TJS'
    },
    {
        currency: 'KPW',
        id: 'KPW'
    },
    {
        currency: 'MMK',
        id: 'MMK'
    },
    {
        currency: 'LSL',
        id: 'LSL'
    },
    {
        currency: 'LRD',
        id: 'LRD'
    },
    {
        currency: 'KGS',
        id: 'KGS'
    },
    {
        currency: 'GIP',
        id: 'GIP'
    },
    {
        currency: 'XPT',
        id: 'XPT'
    },
    {
        currency: 'MDL',
        id: 'MDL'
    },
    {
        currency: 'CUP',
        id: 'CUP'
    },
    {
        currency: 'KHR',
        id: 'KHR'
    },
    {
        currency: 'MKD',
        id: 'MKD'
    },
    {
        currency: 'VUV',
        id: 'VUV'
    },
    {
        currency: 'MRU',
        id: 'MRU'
    },
    {
        currency: 'ANG',
        id: 'ANG'
    },
    {
        currency: 'SZL',
        id: 'SZL'
    },
    {
        currency: 'CVE',
        id: 'CVE'
    },
    {
        currency: 'SRD',
        id: 'SRD'
    },
    {
        currency: 'XPD',
        id: 'XPD'
    },
    {
        currency: 'XDR',
        id: 'XDR'
    },
    {
        currency: 'STD',
        id: 'STD'
    },
    {
        currency: 'BTC',
        id: 'BTC'
    },
    {
        currency: 'SVC',
        id: 'SVC'
    },
    {
        currency: 'DJF',
        id: 'DJF'
    },
    {
        currency: 'BTN',
        id: 'BTN'
    },
    {
        currency: 'BSD',
        id: 'BSD'
    },
]