import { Box, useTheme, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton } from "@mui/material";
import { tokens } from "../../theme";
import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect } from "react";
import { myConfig } from "../../settings.js";
import AddFacility from "./facilityadd";
import { apiService2 } from '../../features/apiService';
import { useNavigate } from 'react-router-dom';
import { MaterialReactTable } from "material-react-table";
import { facilityModel } from "../../schema/schema";
import EditFacility from "./editfacility";
import { getAccess } from "../../features/helpers/authhelper";
import { timeZoneConverter } from "../../features/helpers/commonhelper.js";
import { tableView } from "../../features/helpers/table.js";
const Facilities = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const columns = [];
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [facilityList, setFacilityList] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [selectedrow, setRow] = useState(-1);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedFacility, setFacility] = useState({});
  const access = getAccess(user, 'setup');
  if (!user) {
    navigate("/login");
  } else { console.log("User->", user) }

  for (const item of facilityModel) {
    columns.push({ accessorKey: item['field'], header: item['label'], size: 150 });
  }

  useEffect(() => {
    apiService2('GET', myConfig.getFacilitiesEP, { tenantid: user.tenantid },)
      .then(response => {
        var list = response.data;
        console.log("Facilities->", list);
        
        list?.forEach(res => { res.cdate = timeZoneConverter(res.createdOn) })
        setFacilityList(list);
      })
      .catch(error => {
        console.error(error);
      })
  }, [refresh]);

  useEffect(() => {
    const selectvalue = Object.keys(rowSelection)[0];
    const selected = Object.values(rowSelection)[0];
    let index = 0;
    if (selected) {
      for (const tempwo of facilityList) {
        if (tempwo._id == selectvalue) {
          setFacility(tempwo);
          setRow(index);
          break;
        }
        ++index;
      }
    } else {

      setFacility({});
    }
  }, [rowSelection]);

  function handleClose() {
    setOpen(false);
    setOpenEdit(false);
    setOpenDelete(false);
    setRefresh(!refresh);
  }

  function handleClickOpen() {
    setFacility({});
    setOpen(true);
  }

  function handleDelete() {
    apiService2('POST', myConfig.deleteFacilityEP, { "_id": selectedFacility._id })
      .then(resp => {
        handleClose();
      })
      .catch(error => {
        console.log(["Resource Add Failed->", error]);
        handleClose();
      });
  }

  function CustomToolbar() {
    return (
      <Box className="mb-1">
        {
          access.isCreate && <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleClickOpen}>
            Add
          </Button>
        }
      </Box>
    );
  }
  return (
    <Box m="20px">
      <Box
        height="70vh" >
        {CustomToolbar()}
        {tableView(
          false, columns, facilityList, setRowSelection,
          rowSelection, '_id',
          true,
          ({ row, table }) => [
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
              {
                access.isEdit && <IconButton
                  color="secondary"
                  onClick={() => {
                    setFacility({ ...row.original })
                    setOpenEdit(true);
                  }}>
                  <EditIcon />
                </IconButton>
              }
              {
                access.isDeleted && <IconButton
                  color="error"
                  onClick={() => {
                    setFacility({ ...row.original })
                    setOpenDelete(true);
                  }}>
                  <DeleteIcon />
                </IconButton>
              }

            </Box>
          ])
        }
        <AddFacility open={{ state: open, setfn: handleClose }} />
        <EditFacility open={{ state: openEdit, setfn: handleClose, rowid: selectedrow, facility: selectedFacility }} />
        <Dialog
          open={openDelete}
          fullWidth={true}
          maxWidth={'xs'}
          onClose={handleClose}
          aria-labelledby="form-dialog-title">
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Delete Facility</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h4">Are you sure you want to delete?</DialogContentText>
              <DialogActions >
                <Button type="button" variant="contained" color="secondary" onClick={handleDelete}>
                  Confirm
                </Button>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Close
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Facilities;
