import { Box, useTheme, Button, Tabs, Tab, IconButton, Tooltip } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { myConfig } from "../../settings.js";
import { useSelector } from 'react-redux';
import { apiService } from '../../features/apiService.js';
import { MaterialReactTable } from 'material-react-table';
import { getAccess } from "../../features/helpers/authhelper";
import ViewData from "./viewdata";

const SensorData = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columnlist = [{"AssetID": "assetid", "AssetName": "name", "State": "state", "Last Data": "lastdata"},
                      {"AssetID": "assetid", "AssetName": "name", "Aspect": "aspect", "Parameter": "parameter"}];
  const [open, setViewOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const access = getAccess(user, 'Work Center');
  const [refresh, setRefresh] = useState(false);
  const [sensorList, setSensorList] = useState([]);
  const [assetList, setAssetList] = useState([]);
  const [selectedrow, setRow] = useState(-1);
  const [selectedSensor, setSensor] = useState({});
  const [sensorData, setSensorData] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const columns = [[],[]];
  let wrlist = {};

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  for(const column in columnlist) {
    for (const key in columnlist[column]) {
     columns[column].push({accessorKey:columnlist[column][key], header: key, size:150});
    }
  }

  function updateSensorData(assetlist) {
    var sensorDataList = [];
    for(const asset in assetlist) {
        const inspectionList = assetlist[asset].inspectionList;
        console.log("Inspection List ->", inspectionList);
        for (const inspind in inspectionList) {
            const insp = inspectionList[inspind];
            if (insp.type == "Online") {
                sensorDataList.push({"AssetID": assetlist[asset].assetid, "AssetName": assetlist[asset].name, "Aspect": insp.aspect, "Parameter": insp.parameter, "Recent Data":[]});
            }
        }
    }
    console.log("Sensor List->", sensorDataList);
    return sensorDataList;
  }
  
  function updateAssetState(assetlist) {
    var sensorStateList = [];
    for (const asset of assetlist) {
      const sensordata = asset.sensordata.slice(-1);
      console.log("sensordata->", sensordata);
      var lastdata = "No Data Available";
      
      if (sensordata.length > 0) {
        const stime = new Date(sensordata[0].tstamp);
        console.log('stime->', stime);
        const formattedDate = stime.toLocaleString('en-IN', { hour12: false });
        console.log('formatted date->', formattedDate);
        lastdata = formattedDate + ": " + sensordata[0].state; 
      }
      sensorStateList.push({"assetid": asset.assetid, "name": asset.name, "state": asset.name, "lastdata": lastdata, "sensordata": asset.sensordata });
    }
    console.log("Asset Sensor State List ->", sensorStateList)
    return sensorStateList;
  }

  //Get all the assets with sensor data
  useEffect(() => {
    apiService(myConfig.getSensorDataEP)
    .then (response=> {
      console.log("Resp->", response.data);
      var sensorlist = updateSensorData(response.data);
      setSensorList(sensorlist);
    })
    .catch (error => {
      console.error("Error in getting sensor data-", error);
    }) 
  }, [refresh]);
  
  //Get all the assets with sensor based state information
  useEffect(() => {
    apiService(myConfig.getSensorStateDataEP)
    .then (response=> {
      console.log("Resp->", response.data);
      var assetlist = updateAssetState(response.data);
      setAssetList(assetlist);
    })
    .catch (error => {
      console.error("Error in getting sensor data-", error);
    }) 
  }, [refresh]);

  function handleClose() {
    //console.log("Setting open to false");
    //console.log(["show=>", show]);
    //setAddWROpen(false);
    setViewOpen(false);
    setRefresh(!refresh);
    setRowSelection({});
  }

  useEffect(()=> {
    console.log(Object.keys(rowSelection));
    const wrnumber = Object.keys(rowSelection)[0];
    const selected = Object.values(rowSelection)[0];
    let index = 0;
    if (selected) {
      for (const tempwr of sensorList){
        if (tempwr.wrnumber == wrnumber) {
          //setWR(tempwr);
          setRow(index);
          break;
        }
        ++index;
      } 
    } else {
        
        //setWR({});
    }
  },[rowSelection]);

  function handleViewSensor() {}
  function handleUpdateSensor() {}

  function CustomToolbar() {
    return (
      <Box className="mb-1">
          {
            access?.isCreate && <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleViewSensor}>
                View
            </Button>
          }
          {
            access?.isEdit && <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={handleUpdateSensor}>
                Update
            </Button>
          }
      </Box>
    );
  }

  return (
    <Box m="20px">
      <Header title="SensorData" subtitle="" />
      <Box
        m="40px 0 0 0"
         height="70vh"
      >
        
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={currentTabIndex}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label='Asset State' />
          <Tab label='Asset Aspects' />
        </Tabs>
      </Box>
        
      {/* TAB 1 Contents */}
      {currentTabIndex === 0 && (
            <MaterialReactTable
                columns={columns[currentTabIndex]}
                data={assetList}
                enableMultiRowSelection={false}
                enableRowActions
                //enableRowSelection
                enableColumnResizing
                enableGrouping
                //enableStickyHeader
                enableStickyFooter
                initialState={{
                    density: 'compact',
                    expanded: true, //expand all groups by default
                    //grouping: ['assigneddept'], //an array of columns to group by by default (can be multiple)
                    pagination: { pageIndex: 0, pageSize: 15 },
                    sorting: [{ AssetID: 'AssetID', desc: false }], //sort by state by default
                }}
                getRowId={(row) => row.wrnumber} //give each row a more useful id
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ rowSelection }} //pass our managed row selection state to the table to use
                cell= {(value) => String(value)}
                muiSelectCheckboxProps = {{ color: 'secondary' }}
                renderRowActions={({ row, table }) => [
                  <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                      {
                        <Tooltip sx={{fontSize: 16}} title="View Sensor Data" >
                        <IconButton
                            color="secondary"
                            onClick={() => {
                                setSensorData({ ...row.original })
                                setViewOpen(true);
                            }}>
                            <SummarizeIcon />
                        </IconButton>
                        </Tooltip>
                      }
                  </Box>
              ]}
            />
            )}
      {currentTabIndex === 1 && (
            <MaterialReactTable
                columns={columns[currentTabIndex]}
                data={sensorList}
                enableMultiRowSelection={false}
                enableRowActions
                enableRowSelection
                enableColumnResizing
                enableGrouping
                //enableStickyHeader
                enableStickyFooter
                initialState={{
                    density: 'compact',
                    expanded: true, //expand all groups by default
                    //grouping: ['assigneddept'], //an array of columns to group by by default (can be multiple)
                    pagination: { pageIndex: 0, pageSize: 15 },
                    sorting: [{ AssetID: 'AssetID', desc: false }], //sort by state by default
                }}
                getRowId={(row) => row.wrnumber} //give each row a more useful id
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ rowSelection }} //pass our managed row selection state to the table to use
                cell= {(value) => String(value)}
                muiSelectCheckboxProps = {{ color: 'secondary' }}
            />
            )}
      </Box>
      <ViewData open={{ state: open, setfn: handleClose, sensordata: sensorData }} />
    </Box>
  );
};

export default SensorData;