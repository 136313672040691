import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import React, { useEffect, useState } from "react";
import e from "cors";
import { apiService, apiService2 } from "../../features/apiService";
import { useSelector } from "react-redux";
import { myConfig } from "../../settings";
import { toast } from 'react-toastify';
import { TablePaginationActions } from "../../features/helpers/genhelper";

const AddMeter = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(props.open.state);
    const [meterValue, setMeterValue] = useState('');
    const { user } = useSelector((state) => state.auth);
    const [assetlist, setAsset] = useState([]);
    const [assetlist2, setAsset2] = useState([]);
    const [assetFamilylist, setAssetFamilyList] = useState([]);
    const [assetFamily, setAssetFamily] = useState('');
    const [assetName, setAssetName] = useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    if (props.open.state & !open) {
        setOpen(true);
        setAssetName('');
        setAssetFamily('');
    }

    useEffect(() => {
        apiService2('POST', myConfig.getAssetsFilterEP, { meter: 'Manual' })
            .then(response => {
                response.data.forEach((row, i) => {
                    row.oldMeterValue = row.meterValue;
                    row.newMeterValue = 0;
                    row.meterValue = 0;
                })
                setAsset(response.data);
                setAsset2(response.data)
            })
            .catch(error => {
                console.error("Received Error->", error);
            })
    }, [open]);

    useEffect(() => {
        apiService2('GET', myConfig.getAssetfamily, {},)
            .then(response => {
                setAssetFamilyList(response.data);
            })
            .catch(error => {
                console.error(error);
            })
    }, [open]);

    let modFamilyList = [];
    for (const fam of assetFamilylist) {
        modFamilyList.push(fam.name);
    }


    function handleClose() {
        setOpen(false);
        props.open.setfn();
    }

    function handleSearch() {
        var assetList = assetlist2;
        if (assetFamily == '' && assetName == '') {
            toast.error("Please select atleast one field");
            return;
        }
        console.log('assetList', assetList);
        if (assetFamily) {
            assetList = assetList.filter((row) => row.family == assetFamily);
        }
        if (assetName) {
            assetList = assetList.filter((row) => row.name.toLocaleLowerCase().includes(assetName));
        }
        setAsset([...assetList]);
        console.log(assetList);
    }

    function handleReset() {
        setAssetFamily('');
        setAssetName('');
        setAsset([...assetlist2]);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function handleMeterValue(index, value) {
        let temp = assetlist;
        temp[index].meterValue = value;
        temp[index].newMeterValue = value;
        setAsset([...temp]);
    }

    function handleUpdateMeter() {
        var isSave = true;
        for (let i = 0; i < assetlist.length; i++) {
            console.log(assetlist[i].meterValue );
            if (assetlist[i].meterValue > 0) {
                if (assetlist[i].reset == false) {
                    if (assetlist[i].meterValue > assetlist[i].oldMeterValue) {
                        assetlist[i].totalMeterValue = Number(assetlist[i].meterValue);
                    } else {
                        assetlist[i].meterValue = 0;
                        assetlist[i].newMeterValue = 0;
                        isSave = false;
                    }
                } else {
                    assetlist[i].totalMeterValue = Number(assetlist[i].totalMeterValue) + Number(assetlist[i].meterValue);
                }
            }

        }
        if (isSave) {
            setAsset([...assetlist]);
            let details = { "assetlist": assetlist }
            apiService2('POST', myConfig.updateAssetMeterValueEP, details)
                .then(response => {
                    console.log("Response->", response);
                    handleClose();
                })``
                .catch(error => {
                    console.error("Asset Add Failed->", error);
                })
        } else {
            toast.error("Meter value cannot be less than previous value");
            console.log("Meter value cannot be less than previous value");
            setAsset([...assetlist]);
        }


    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={'md'}
                aria-labelledby="form-dialog-title"
                fullWidth
            >
                <React.Fragment>
                    <DialogTitle id="form-dialog-title">Add Meter Value</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant="h6">
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField
                                        id="outlined-basic"
                                        label={'Asset Family'}
                                        name={'assetFamily'}
                                        value={assetFamily}
                                        onChange={(e) => setAssetFamily(e.target.value)}
                                        margin="normal"
                                        type="text"
                                        select
                                        defaultValue={''}
                                        sx={{ display: 'flex', width: "100%" }}>
                                        {modFamilyList.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        id="outlined-basic"
                                        label={'Asset Name'}
                                        name={'assetname'}
                                        style={{ width: "100%" }}
                                        margin="normal"
                                        type="text"
                                        value={assetName}
                                        onChange={(e) => setAssetName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Button type="button" variant="contained" color="primary" size="medium" onClick={handleSearch}>
                                        Search
                                    </Button>
                                    <Button type="submit" variant="contained" color="primary" size="medium" onClick={handleReset}>
                                        Reset
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Asset Id</TableCell>
                                        <TableCell>Reset</TableCell>
                                        <TableCell>Total Value</TableCell>
                                        <TableCell>Current Value</TableCell>
                                        <TableCell>New Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {assetlist.map((row, i) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.assetid}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.reset == true ? 'Yes' : "No"}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.totalMeterValue}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.oldMeterValue}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <TextField
                                                    id="outlined-basic"
                                                    label={"Meter Value"}
                                                    name={`meterValue${i}`}
                                                    style={{ width: "100%" }}
                                                    margin="normal"
                                                    type="text"
                                                    value={row.newMeterValue}
                                                    onChange={(e) => handleMeterValue(i, e.target.value)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                            colSpan={3}
                                            count={assetlist.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: {
                                                    'aria-label': 'rows per page',
                                                },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                        <DialogActions>
                            <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained" color="primary"  onClick={handleUpdateMeter}>
                                Submit
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </React.Fragment>
            </Dialog>
        </React.Fragment>
    )
}

export default AddMeter;