import React, { useState } from "react";
import { apiService2 } from "../../features/apiService";
import { myConfig } from "../../settings";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";

const WorkorderReopen = (props) => {
    const [workorder, setWo] = useState({}); //[workorder, setWorkorder
    const [open, setOpen] = useState(props.open.state);
    const [reason, setReason] = useState('');

    if (props.open.state & !open) {
        setOpen(true);
        const worko = props.open.workorder;
        setReason('');
        setWo(worko);
    }

    function handleClose() {
        setOpen(false);
        setReason('');
        props.open.setfn();
      }


    const handleApprove = () => {
        var data = {
            _id: workorder._id,
            reason: reason,
        }
        apiService2("POST", myConfig.workorderReopenEP, data)
            .then((response) => {
                handleClose();
            })
            .catch((error) => {
                console.error(error);
            });
    };


    return (
        <React.Fragment>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'xs'}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                <React.Fragment>
                    <DialogTitle id="form-dialog-title">Workorder Reopen</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant="h6">Are you sure you reopen the workorder?</DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Workorder Number"
                            type="text"
                            fullWidth
                            multiline
                            value={workorder.wonumber}
                            disabled
                        />
                       
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Reason"
                            type="text"
                            fullWidth
                            multiline
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            maxRows={4}
                            minRows={4}
                        />
                        
                        <DialogActions sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'}}>
                            <Button type="button" variant="contained" color="primary" onClick={handleApprove}>
                                Confirm
                            </Button>
                            <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                                Close
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </React.Fragment>
            </Dialog>
        </React.Fragment>
    );
};

export default WorkorderReopen;
