import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
//import TaskIcon from '@mui/icons-material/Task';
import React, { useState, useEffect } from "react";
import { GridToolbarContainer } from '@mui/x-data-grid';
import { Button } from "@mui/material";
import { myConfig } from "../../settings.js";
import { inspectionModel } from "../../schema/schema.js";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { MaterialReactTable } from "material-react-table";
import { apiService2 } from "../../features/apiService";
import AddInspection from "./addinspection";
import UpdateInspection from "./updateinspection";
import EditInspection from "./editinspection";
import { getAccess } from "../../features/helpers/authhelper";
import { timeZoneConverter } from "../../features/helpers/commonhelper.js";
import { tableView } from "../../features/helpers/table.js";

const InspectionLib = () => {
  // toast.warning("Feature Under Development")

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [];
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [uopen, setUpdateOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [inspectionList, setInspectionList] = useState([]);
  const [selectedrow, setRow] = useState(-1);
  const [rowSelection, setRowSelection] = useState({});
  const { user } = useSelector((state) => state.auth);
  const [inspection, setInspection] = useState({});
  const access = getAccess(user, 'inspection');

  let ivalues = {};
  for (const item of inspectionModel) {
    if (item.hasOwnProperty("skip")) {
      if (item.skip) continue;
    }
    if (item.type == Boolean) columns.push({ accessorKey: item['field'], header: item['label'], size: 150, accessorFn: d => { return d.approvalrequired ? 'yes' : 'no' } })
    else if (item.populate == true) { columns.push({ accessorKey: item['field'] + '.' + item['field2'], header: item['label'], size: 150 }); }
    else { columns.push({ accessorKey: item['field'], header: item['label'], size: 150 }); }
  }
  useEffect(() => {
    apiService2('POST', myConfig.getInspectionFilterEP)
      .then(response => {
        console.log('response', response);
        
        var inspectionList = response.data;
        inspectionList.forEach((res, index) => {
          res.createdOn = timeZoneConverter(res.createdOn)
        });
        setInspectionList(inspectionList);
      })
      .catch(error => {
        console.error(error);
      })
  }, [refresh]);

  function handleClose() {
    setOpen(false);
    setUpdateOpen(false);
    setEditOpen(false);
    setDeleteOpen(false);
    setRefresh(true);
    setRowSelection({});
  }

  function handleClickOpen() {
    setOpen(true);
    setRefresh(false);
  }

  function handleUpdateOpen() {
    if (selectedrow >= 0) {
      setUpdateOpen(true);
      setRefresh(false);
    } else toast.warning("Select a any one assetfamily first ");
  }

  function handleClickEdit() {
    if (selectedrow >= 0) {
      setEditOpen(true);
      setRefresh(false);
    } else toast.warning("Select a any one assetfamily first ");
  }

  function handleDelete() {
    apiService2('POST', myConfig.deleteInspectionEP, { "_id": inspection._id })
      .then(resp => {
        handleClose();
      })
      .catch(error => {
        console.log(["Resource Add Failed->", error]);
        handleClose();
      });
  }

  function handleClickDelete() {
    if (selectedrow >= 0) {
      setDeleteOpen(true);
      setRefresh(false);
    } else toast.warning("Select a any one assetfamily first ");
  }

  useEffect(() => {
    const wonumber = Object.keys(rowSelection)[0];
    const selected = Object.values(rowSelection)[0];
    let index = 0;
    if (selected) {
      for (const tempwo of inspectionList) {
        if (tempwo._id == wonumber) {
          setInspection(tempwo);
          setRow(index);
          break;
        }
        ++index;
      }
    } else {

      setInspection({});
    }
  }, [rowSelection]);

  function CustomToolbar() {
    return (
      <Box className="mb-1">
        {
          access?.isCreate && <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleClickOpen}>
            Add
          </Button>
        }
        {
          access?.isEdit && <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={handleClickEdit}>
            Edit
          </Button>
        }
        {
          access?.isDeleted && <Button variant="contained" color="primary" startIcon={<DeleteIcon />} onClick={handleClickDelete}>
            Delete
          </Button>
        }
        {
          access?.isEdit && <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleUpdateOpen}>
            Update Suspension
          </Button>
        }
      </Box>
    );
  }

  return (
    <Box m="20px">
      <Header title="Inspection" subtitle="" />
      <Box
        m="40px 0 0 0"
        height="70vh"
      >
        {CustomToolbar()}
        {tableView(false, columns, inspectionList, setRowSelection, rowSelection, '_id', false, null, null)}
        <AddInspection open={{ state: open, setfn: handleClose }} />
        <EditInspection open={{ state: editOpen, setfn: handleClose, rowid: selectedrow, inspection: inspection }} />
        <UpdateInspection open={{ state: uopen, setfn: handleClose, rowid: selectedrow, inspection: inspection }} />
        <Dialog
          open={deleteOpen}
          fullWidth={true}
          maxWidth={'xs'}
          onClose={handleClose}
          aria-labelledby="form-dialog-title">
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Delete Inspection</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Are you sure you want to delete?</DialogContentText>
              <DialogActions sx={{
               
                display: 'flex',
                justifyContent: 'flex-end',
              }}>
                <Button type="button" variant="contained" color="secondary" onClick={handleDelete}>
                  Confirm
                </Button>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Close
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        </Dialog>
      </Box>
    </Box>
  );
};

export default InspectionLib;
