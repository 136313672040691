import React, { useState, useEffect } from "react";
import { FormHelperText, IconButton, InputLabel, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { myConfig } from "../../settings";
import { mtaskModel } from "../../schema/schema.js";
import { useSelector } from 'react-redux';
import { apiService, apiService2 } from '../../features/apiService.js';
import { formCreator } from "../../features/helpers/formhelper";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function initialiseFields() { return mtaskModel };

// API End Points
const endPoint = myConfig.addMainLibTaskEP;
const getDeptEP = myConfig.getDeptEP;

let objindex = 0;
let valueobject = { "Spares": [] };

const AddTasks = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let genFormFields = initialiseFields();
  const [open, setOpen] = useState(props.open.state);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [isSubmitionError, setSubmitionError] = useState(false);
  const [formFields, setFormFields] = useState(mtaskModel)
  const [deptList, setDeptList] = useState([]);
  const [taskname, setTaskName] = useState('');
  const [desc, setDesc] = useState('');
  const [dept, setDept] = useState('');
  const [trade, setTrade] = useState('');
  const [tasklist, setTaskList] = useState([]);
  let initialValues = {
    taskname: '',
    desc: '',
    dept: '',
    trade: '',
  };
  let modDeptList = [];

  // Get Departments
  useEffect(() => {
    console.log("Get Deptartments...");
    apiService(getDeptEP)
      .then(response => {
        setDeptList(response.data)
        if (response.data.length == 0) {
          toast.error("Department list Empty, Please add department first");
        }
      })
      .catch(error => {
        console.error(error);
      })
  }, []);


  for (const deptItem of deptList) {
    modDeptList.push(deptItem.name);
  }
  const valueDB = { "dept": modDeptList };

  // Handle States for the dialog box
  function handleClose() {
    // console.log("handleClose: Setting open to false");
    setOpen(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);
    setFormFields(genFormFields);
    props.open.setfn();
    objindex = 0;
  }


  if (props.open.state & !open) {
    setOpen(true);
    const maintlib = props.open.rowid;
    if (maintlib?.tasklist && maintlib.tasklist.length > 0) {
      setTaskList(maintlib.tasklist);
    } else {
      setTaskList([]);
    }
  }



  function handleEditRow(index, row) {
    setTaskName(row.taskname);
    setDept(row.dept);
    setDesc(row.desc);
    setTrade(row.trade);
    let newList = tasklist.filter((item, i) => i !== index);
    setTaskList(newList);
  }

  function handleDeleteRow(index) {
    let newList = tasklist.filter((item, i) => i !== index);
    setTaskList(newList);
  }

  function handleAddItem() {
    if (taskname !== null && taskname !== '' && dept !== null && dept !== '' && desc !== null && desc !== '' && trade !== null && trade !== '') {
      if (!tasklist.find(x => x.taskname == taskname)) {
        let data = {
          taskname: taskname, desc: desc, dept: dept, trade: trade
        }
        setTaskList([...tasklist, data]);
        setTaskName('');
        setDesc('');
        setDept('');
        setTrade('');
      } else {
        toast.error("Item already added");
        setTaskName('');
        setDesc('');
        setDept('');
        setTrade('');
        return;
      }
    } else {
      toast.error("All Fields are Required");
    }
  }

  function handleReset() {
    setTaskName('');
    setDesc('');
    setDept('');
    setTrade('');
    const maintlib = props.open.rowid;
    if (maintlib?.tasklist && maintlib.tasklist.length > 0) {
      setTaskList(maintlib.tasklist);
    } else {
      setTaskList([]);
    }
  }

  function handleSave() {
    const maintlib = props.open.rowid;
    let details = {
      '_id': maintlib._id,
      'tasklist': tasklist
    }
    apiService2('POST', endPoint, details)
      .then(resp => {
        setSubmitionCompleted(true);
      })
      .catch(error => {
        setSubmitionError(true);
        setSubmitionCompleted(true);
      })
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'md'}
      sx={{
        "&.MuiGrid-item": {
          paddingTop: 10,
        }
      }}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      {!isSubmitionCompleted && (
        <React.Fragment>
          <DialogTitle id="form-dialog-title">Add Tasks </DialogTitle>
          <DialogContent>
            <DialogContentText variant="h6"></DialogContentText>
            <Grid container spacing={4}>
              <Grid item sm={6}>
                <InputLabel id="simple-label">Task Name *</InputLabel>
                <TextField
                  id='taskname'
                  placeholder={'Task Name'}
                  name={'taskname'}
                  value={taskname}
                  onChange={(e) => {
                    setTaskName(e.target.value)
                  }}
                  margin="normal"
                  type="text"
                  sx={{ display: 'flex', width: "100%", marginTop: '2px' }}>
                </TextField>
              </Grid>
              <Grid item sm={6}>
                <InputLabel id="simple-label">Description *</InputLabel>
                <TextField
                  id="desc"
                  placeholder={'Description'}
                  name={'desc'}
                  value={desc}
                  onChange={(e) => {
                    setDesc(e.target.value)
                  }}
                  margin="normal"
                  type="text"
                  sx={{ display: 'flex', width: "100%", marginTop: '2px' }} />
              </Grid>
              <Grid item sm={5}>
                <InputLabel id="simple-label">Department *</InputLabel>
                <TextField
                  id="dept"
                  placeholder={'Department'}
                  name={'dept'}
                  value={dept}
                  onChange={(e) => {
                    setDept(e.target.value)
                  }}
                  margin="normal"
                  type="text"
                  select
                  defaultValue={''}
                  sx={{ display: 'flex', width: "100%", marginTop: '2px' }}>
                  {modDeptList.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item sm={5}>
                <InputLabel id="simple-label">Trade *</InputLabel>
                <TextField
                  id="trade"
                  placeholder={'Trade'}
                  name={'trade'}
                  value={trade}
                  onChange={(e) => {
                    setTrade(e.target.value)
                  }}
                  margin="normal"
                  type="text"
                  sx={{ display: 'flex', width: "100%", marginTop: '2px' }}>
                </TextField>
              </Grid>
              <Grid item xs={4} sm={2}>
                <Button type="submit" variant="contained" color="primary" onClick={handleAddItem}>
                  Add
                </Button>
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow style={{ textAlign: "left" }}>
                    <TableCell>Task Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Department</TableCell>
                    <TableCell>Trade</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tasklist.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {row.taskname}
                      </TableCell>
                      <TableCell > {row.desc}</TableCell>
                      <TableCell > {row.dept}</TableCell>
                      <TableCell > {row.trade}</TableCell>
                      <TableCell >
                        <IconButton onClick={() => handleEditRow(index, row)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteRow(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <DialogActions>
              <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="button" variant="contained" color="primary"
                onClick={handleSave}>
                Update
              </Button>
            </DialogActions>
          </DialogContent>
        </React.Fragment>
      )}
      {isSubmitionCompleted && (
        <React.Fragment>
          <DialogTitle id="form-dialog-title">Thanks! Task Assigned</DialogTitle>
          <DialogContent>
            <DialogContentText variant="h6">Thanks</DialogContentText>
            <DialogActions>
              <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                Back to app
              </Button>
            </DialogActions>
          </DialogContent>
        </React.Fragment>
      )}
      {isSubmitionError && (
        <React.Fragment
        >
          <DialogTitle id="form-dialog-title">Sorry! Assign Task Failed</DialogTitle>
          <DialogContent>
            <DialogContentText variant="h6">Try Again Later</DialogContentText>
            <DialogActions>
              <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                Back to app
              </Button>
            </DialogActions>
          </DialogContent>
        </React.Fragment>
      )}
    </Dialog>
  )
}

export default AddTasks;