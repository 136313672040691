export const TIMEZONE = [
  {
    zone: "Pacific/Midway",
    gmt: "(GMT-11:00)",
    name: "Midway Island",
    local: "en-IN",
  },
  {
    zone: "US/Samoa",
    gmt: "(GMT-11:00)",
    name: "Samoa",
    local: "en-US",
  },
  {
    zone: "US/Hawaii",
    gmt: "(GMT-10:00)",
    name: "Hawaii",
    local: "en-US",
  },
  {
    zone: "US/Alaska",
    gmt: "(GMT-09:00)",
    name: "Alaska",
    local: "en-US",
  },
  {
    zone: "US/Pacific",
    gmt: "(GMT-08:00)",
    name: "Pacific Time (US &amp; Canada)",
    local: "en-US",
  },
  {
    zone: "America/Tijuana",
    gmt: "(GMT-08:00)",
    name: "Tijuana",
    local: "en-US",
  },
  {
    zone: "US/Arizona",
    gmt: "(GMT-07:00)",
    name: "Arizona",
    local: "en-US",
  },
  {
    zone: "US/Mountain",
    gmt: "(GMT-07:00)",
    name: "Mountain Time (US &amp; Canada)",
    local: "en-US",
  },
  {
    zone: "America/Chihuahua",
    gmt: "(GMT-07:00)",
    name: "Chihuahua",
    local: "en-US",
  },
  {
    zone: "America/Mazatlan",
    gmt: "(GMT-07:00)",
    name: "Mazatlan",
    local: "en-US",
  },
  {
    zone: "America/Mexico_City",
    gmt: "(GMT-06:00)",
    name: "Mexico City",
    local: "en-US",
  },
  {
    zone: "America/Monterrey",
    gmt: "(GMT-06:00)",
    name: "Monterrey",
    local: "en-US",
  },
  {
    zone: "Canada/Saskatchewan",
    gmt: "(GMT-06:00)",
    name: "Saskatchewan",
    local: "en-US",
  },
  {
    zone: "US/Central",
    gmt: "(GMT-06:00)",
    name: "Central Time (US &amp; Canada)",
    local: "en-US",
  },
  {
    zone: "US/Eastern",
    gmt: "(GMT-05:00)",
    name: "Eastern Time (US &amp; Canada)",
    local: "en-US",
  },
  {
    zone: "US/East-Indiana",
    gmt: "(GMT-05:00)",
    name: "Indiana (East)",
    local: "en-US",
  },
  {
    zone: "America/Bogota",
    gmt: "(GMT-05:00)",
    name: "Bogota",
    local: "en-US",
  },
  {
    zone: "America/Lima",
    gmt: "(GMT-05:00)",
    name: "Lima",
    local: "en-US",
  },
  {
    zone: "America/Caracas",
    gmt: "(GMT-04:30)",
    name: "Caracas",
    local: "en-US",
  },
  {
    zone: "Canada/Atlantic",
    gmt: "(GMT-04:00)",
    name: "Atlantic Time (Canada)",
    local: "en-US",
  },
  {
    zone: "America/La_Paz",
    gmt: "(GMT-04:00)",
    name: "La_Paz",
    local: "en-US",
  },
  {
    zone: "America/Santiago",
    gmt: "(GMT-04:00)",
    name: "Santiago",
    local: "en-US",
  },
  {
    zone: "Canada/Newfoundland",
    gmt: "(GMT-03:30)",
    name: "Newfoundland",
    local: "en-US",
  },
  {
    zone: "America/Buenos_Aires",
    gmt: "(GMT-03:00)",
    name: "Buenos Aires",
    local: "en-US",
  },
  {
    zone: "Greenland",
    gmt: "(GMT-03:00)",
    name: "Greenland",
    local: "en-US",
  },
  {
    zone: "Atlantic/Stanley",
    gmt: "(GMT-02:00)",
    name: "Stanley",
    local: "en-US",
  },
  {
    zone: "Atlantic/Azores",
    gmt: "(GMT-01:00)",
    name: "Azores",
    local: "en-US",
  },
  {
    zone: "Atlantic/Cape_Verde",
    gmt: "(GMT-01:00)",
    name: "Cape Verde Is.",
    local: "en-US",
  },
  {
    zone: "Africa/Casablanca",
    gmt: "(GMT)",
    name: "Casablanca",
    local: "en-US",
  },
  {
    zone: "Europe/Dublin",
    gmt: "(GMT)",
    name: "Dublin",
    local: "en-US",
  },
  {
    zone: "Europe/Lisbon",
    gmt: "(GMT)",
    name: "Libson",
    local: "en-US",
  },
  {
    zone: "Europe/London",
    gmt: "(GMT)",
    name: "London",
    local: "en-US",
  },
  {
    zone: "Africa/Monrovia",
    gmt: "(GMT)",
    name: "Monrovia",
    local: "en-US",
  },
  {
    zone: "Europe/Amsterdam",
    gmt: "(GMT+01:00)",
    name: "Amsterdam",
    local: "en-US",
  },
  {
    zone: "Europe/Belgrade",
    gmt: "(GMT+01:00)",
    name: "Belgrade",
    local: "en-US",
  },
  {
    zone: "Europe/Berlin",
    gmt: "(GMT+01:00)",
    name: "Berlin",
    local: "en-US",
  },
  {
    zone: "Europe/Bratislava",
    gmt: "(GMT+01:00)",
    name: "Bratislava",
    local: "en-US",
  },
  {
    zone: "Europe/Brussels",
    gmt: "(GMT+01:00)",
    name: "Brussels",
    local: "en-US",
  },
  {
    zone: "Europe/Budapest",
    gmt: "(GMT+01:00)",
    name: "Budapest",
    local: "en-US",
  },
  {
    zone: "Europe/Copenhagen",
    gmt: "(GMT+01:00)",
    name: "Copenhagen",
    local: "en-US",
  },
  {
    zone: "Europe/Ljubljana",
    gmt: "(GMT+01:00)",
    name: "Ljubljana",
    local: "en-US",
  },
  {
    zone: "Europe/Madrid",
    gmt: "(GMT+01:00)",
    name: "Madrid",
    local: "en-US",
  },
  {
    zone: "Europe/Paris",
    gmt: "(GMT+01:00)",
    name: "Paris",
    local: "en-US",
  },
  {
    zone: "Europe/Prague",
    gmt: "(GMT+01:00)",
    name: "Prague",
    local: "en-US",
  },
  {
    zone: "Europe/Rome",
    gmt: "(GMT+01:00)",
    name: "Rome",
    local: "en-US",
  },
  {
    zone: "Europe/Sarajevo",
    gmt: "(GMT+01:00)",
    name: "Sarajevo",
    local: "en-US",
  },
  {
    zone: "Europe/Skopje",
    gmt: "(GMT+01:00)",
    name: "Skopje",
    local: "en-US",
  },
  {
    zone: "Europe/Stockholm",
    gmt: "(GMT+01:00)",
    name: "Stockholm",
    local: "en-US",
  },
  {
    zone: "Europe/Vienna",
    gmt: "(GMT+01:00)",
    name: "Vienna",
    local: "en-US",
  },
  {
    zone: "Europe/Warsaw",
    gmt: "(GMT+01:00)",
    name: "Warsaw",
    local: "en-US",
  },
  {
    zone: "Europe/Zagreb",
    gmt: "(GMT+01:00)",
    name: "Zagreb",
    local: "en-US",
  },
  {
    zone: "Europe/Athens",
    gmt: "(GMT+02:00)",
    name: "Athens",
    local: "en-US",
  },
  {
    zone: "Europe/Bucharest",
    gmt: "(GMT+02:00)",
    name: "Bucharest",
    local: "en-US",
  },
  {
    zone: "Africa/Cairo",
    gmt: "(GMT+02:00)",
    name: "Cairo",
    local: "en-US",
  },
  {
    zone: "Africa/Harare",
    gmt: "(GMT+02:00)",
    name: "Harere",
    local: "en-US",
  },
  {
    zone: "Europe/Helsinki",
    gmt: "(GMT+02:00)",
    name: "Helsinki",
    local: "en-US",
  },
  {
    zone: "Europe/Istanbul",
    gmt: "(GMT+02:00)",
    name: "Istanbul",
    local: "en-US",
  },
  {
    zone: "Asia/Jerusalem",
    gmt: "(GMT+02:00)",
    name: "Jerusalem",
    local: "en-US",
  },
  {
    zone: "Europe/Kiev",
    gmt: "(GMT+02:00)",
    name: "Kiev",
    local: "en-US",
  },
  {
    zone: "Europe/Minsk",
    gmt: "(GMT+02:00)",
    name: "Minsk",
    local: "en-US",
  },
  {
    zone: "Europe/Riga",
    gmt: "(GMT+02:00)",
    name: "Riga",
    local: "en-US",
  },
  {
    zone: "Europe/Sofia",
    gmt: "(GMT+02:00)",
    name: "Sofia",
    local: "en-US",
  },
  {
    zone: "Europe/Tallinn",
    gmt: "(GMT+02:00)",
    name: "Tallinn",
    local: "en-US",
  },
  {
    zone: "Europe/Vilnius",
    gmt: "(GMT+02:00)",
    name: "Vilnius",
    local: "en-US",
  },
  {
    zone: "Asia/Baghdad",
    gmt: "(GMT+03:00)",
    name: "Baghdad",
    local: "en-US",
  },
  {
    zone: "Asia/Kuwait",
    gmt: "(GMT+03:00)",
    name: "Kuwait",
    local: "en-US",
  },
  {
    zone: "Africa/Nairobi",
    gmt: "(GMT+03:00)",
    name: "Nairobi",
    local: "en-US",
  },
  {
    zone: "Asia/Riyadh",
    gmt: "(GMT+03:00)",
    name: "Riyadh",
    local: "en-US",
  },
  {
    zone: "Asia/Tehran",
    gmt: "(GMT+03:30)",
    name: "Tehran",
    local: "en-US",
  },
  {
    zone: "Europe/Moscow",
    gmt: "(GMT+04:00)",
    name: "Moscow",
    local: "en-US",
  },
  {
    zone: "Asia/Baku",
    gmt: "(GMT+04:00)",
    name: "Baku",
    local: "en-US",
  },
  {
    zone: "Europe/Volgograd",
    gmt: "(GMT+04:00)",
    name: "Volgograd",
    local: "en-US",
  },
  {
    zone: "Asia/Muscat",
    gmt: "(GMT+04:00)",
    name: "Muscat",
    local: "en-US",
  },
  {
    zone: "Asia/Tbilisi",
    gmt: "(GMT+04:00)",
    name: "Tbilisi",
    local: "en-US",
  },
  {
    zone: "Asia/Yerevan",
    gmt: "(GMT+04:00)",
    name: "Yerevan",
    local: "en-US",
  },
  {
    zone: "Asia/Kabul",
    gmt: "(GMT+04:30)",
    name: "Kabul",
    local: "en-US",
  },
  {
    zone: "Asia/Karachi",
    gmt: "(GMT+05:00)",
    name: "Karachi",
    local: "en-US",
  },
  {
    zone: "Asia/Tashkent",
    gmt: "(GMT+05:00)",
    name: "Tashkent",
    local: "en-US",
  },
  {
    zone: "Asia/Kolkata",
    gmt: "(GMT+05:30)",
    name: "Kolkata",
    local: "en-IN",
  },
  {
    zone: "Asia/Kathmandu",
    gmt: "(GMT+05:45)",
    name: "Kathmandu",
    local: "en-US",
  },
  {
    zone: "Asia/Yekaterinburg",
    gmt: "(GMT+06:00)",
    name: "Yekaterinburg",
    local: "en-US",
  },
  {
    zone: "Asia/Almaty",
    gmt: "(GMT+06:00)",
    name: "Almaty",
    local: "en-US",
  },
  {
    zone: "Asia/Dhaka",
    gmt: "(GMT+06:00)",
    name: "Dhaka",
    local: "en-US",
  },
  {
    zone: "Asia/Novosibirsk",
    gmt: "(GMT+07:00)",
    name: "Novosibirsk",
    local: "en-US",
  },
  {
    zone: "Asia/Bangkok",
    gmt: "(GMT+07:00)",
    name: "Bangkok",
    local: "en-US",
  },
  {
    zone: "Asia/Jakarta",
    gmt: "(GMT+07:00)",
    name: "Jakarta",
    local: "en-US",
  },
  {
    zone: "Asia/Krasnoyarsk",
    gmt: "(GMT+08:00)",
    name: "Krasnoyarsk",
    local: "en-US",
  },
  {
    zone: "Asia/Chongqing",
    gmt: "(GMT+08:00)",
    name: "Chongqing",
    local: "en-US",
  },
  {
    zone: "Asia/Hong_Kong",
    gmt: "(GMT+08:00)",
    name: "Hong Kong",
    local: "en-US",
  },
  {
    zone: "Asia/Kuala_Lumpur",
    gmt: "(GMT+08:00)",
    name: "Kuala Lumpur",
    local: "en-US",
  },
  {
    zone: "Australia/Perth",
    gmt: "(GMT+08:00)",
    name: "Perth",
    local: "en-US",
  },
  {
    zone: "Asia/Singapore",
    gmt: "(GMT+08:00)",
    name: "Singapore",
    local: "en-US",
  },
  {
    zone: "Asia/Taipei",
    gmt: "(GMT+08:00)",
    name: "Taipei",
    local: "en-US",
  },
  {
    zone: "Asia/Ulaanbaatar",
    gmt: "(GMT+08:00)",
    name: "Ulaan Bataar",
    local: "en-US",
  },
  {
    zone: "Asia/Urumqi",
    gmt: "(GMT+08:00)",
    name: "Urumqi",
    local: "en-US",
  },
  {
    zone: "Asia/Irkutsk",
    gmt: "(GMT+09:00)",
    name: "Irkutsk",
    local: "en-US",
  },
  {
    zone: "Asia/Seoul",
    gmt: "(GMT+09:00)",
    name: "Seoul",
    local: "en-US",
  },
  {
    zone: "Asia/Tokyo",
    gmt: "(GMT+09:00)",
    name: "Tokyo",
    local: "en-US",
  },
  {
    zone: "Australia/Adelaide",
    gmt: "(GMT+09:30)",
    name: "Adelaide",
    local: "en-US",
  },
  {
    zone: "Australia/Darwin",
    gmt: "(GMT+09:30)",
    name: "Darwin",
    local: "en-US",
  },
  {
    zone: "Asia/Yakutsk",
    gmt: "(GMT+10:00)",
    name: "Yakutsk",
    local: "en-US",
  },
  {
    zone: "Australia/Brisbane",
    gmt: "(GMT+10:00)",
    name: "Brisbane",
    local: "en-US",
  },
  {
    zone: "Australia/Canberra",
    gmt: "(GMT+10:00)",
    name: "Canberra",
    local: "en-US",
  },
  {
    zone: "Pacific/Guam",
    gmt: "(GMT+10:00)",
    name: "Guam",
    local: "en-US",
  },
  {
    zone: "Australia/Hobart",
    gmt: "(GMT+10:00)",
    name: "Hobart",
    local: "en-US",
  },
  {
    zone: "Australia/Melbourne",
    gmt: "(GMT+10:00)",
    name: "Melbourne",
    local: "en-US",
  },
  {
    zone: "Pacific/Port_Moresby",
    gmt: "(GMT+10:00)",
    name: "Port Moresby",
    local: "en-US",
  },
  {
    zone: "Australia/Sydney",
    gmt: "(GMT+10:00)",
    name: "Sydney",
    local: "en-US",
  },
  {
    zone: "Asia/Vladivostok",
    gmt: "(GMT+11:00)",
    name: "Vladivostok",
    local: "en-US",
  },
  {
    zone: "Asia/Magadan",
    gmt: "(GMT+12:00)",
    name: "Magadan",
    local: "en-US",
  },
  {
    zone: "Pacific/Auckland",
    gmt: "(GMT+12:00)",
    name: "Auckland",
    local: "en-US",
  },
  {
    zone: "Pacific/Fiji",
    gmt: "(GMT+12:00)",
    name: "Fiji",
  },
];
