import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import React, { useEffect, useState } from "react";
import { inspectionModel } from "../../schema/schema";
import { useSelector } from "react-redux";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { apiService, apiService2 } from "../../features/apiService";
import { myConfig } from "../../settings";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import { DatePicker } from "@mui/x-date-pickers";

let fakecount = 0;
const AddInspection = (props) => {
    const theme = useTheme();
    const [open, setOpen] = useState(props.open.state);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [maxWidth, setMaxWith] = useState('sm');
    const [isSubmitionError, setSubmitionError] = useState(false);
    const [isUpdateView, setUpdateView] = useState(false)
    const [inspectionList, setInspectionList] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const { user } = useSelector((state) => state.auth);
    const [deptList, setDeptList] = useState([]);
    const [assetList, setAssetList] = useState([]);
    const [section, setSectiontList] = useState([]);
    const [errorMessage, setErrorMessage] = useState('Try Again Later');
    const [assetId, setAsset] = useState([]);
    const [dept, setDept] = useState();
    const [multiSelectValue, setMultiSelectValue] = useState({ "inspectionCategory": [], "aspect": [], });
    const [aspectList, setAspectList] = useState([]);
    const [sched, setSched] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [inspection, setInspection] = useState({});
    const [valueDB, setValueDB] = useState({ "assetId": [], "section": [] });
    function getInitialValues() {
        let ivalues = {};
        for (const item of inspectionModel) {
            if (item.hasOwnProperty("fkey")) {
                if (!item["fkey"]) { ivalues[item['field']] = ""; }
            } else if (item.multiple) {
                ivalues[item['field']] = []
            } else {
                ivalues[item['field']] = "";
            }
        }
        ++fakecount;
        setMultiSelectValue({ "inspectionCategory": [], "aspect": [] });
        setInitialValues({ ...ivalues });
        return ivalues;
    }

    useEffect(() => {
        apiService2('GET', myConfig.getInspectionEP)
            .then(response => {
                setInspectionList(response.data);
            })
            .catch(error => {
                console.error(error);
            })
    }, [open]);

    useEffect(() => {
        apiService(myConfig.getAssetsEP, { state: 'Operational' })
            .then(response => {
                setAssetList(response.data);
            })
            .catch(error => {
                console.error(error);
            })
    }, [open]);



    useEffect(() => {
        apiService2('POST', myConfig.getFacilitiesFilterEP, { isFilterSection: true },)
            .then(response => {
                var modSectionList = [];
                for (const fac of response.data?.filter(x => x.type == 'Section')) {
                    modSectionList.push(fac.name);
                }
                var db = valueDB;
                db["section"] = modSectionList;
                setValueDB(db);

            })
            .catch(error => {
                console.error(error);
            })
    }, [open]);

    function handleClose() {
        setOpen(false);
        setSubmitionError(false);
        setSubmitionCompleted(false);
        props.open.setfn();
    }

    if (props.open.state & !open) {
        setOpen(true);
        setMaxWith('sm');
        setErrorMessage('Try Again Later');
        getInitialValues();
        setAspectList([]);
        setSched(false);
        setUpdateView(false);
        setMultiSelectValue({ "inspectionCategory": [], "aspect": [], "sections": [] })
    }

    function handleRetry() {
        setSubmitionError(false);
        setSubmitionCompleted(false);
        setErrorMessage('Try Again Later');
    }
    let modDeptList = [];
    let modAspectList = [];
    function handleDepart(item, event) {
        if (item.field == 'assetId') {
            modDeptList = [];
            setAspectList([]);
            var db = valueDB;
            db["inspectionCategory"] = [];
            db["aspect"] = [];
            setValueDB(db);
            var inspectionList = [];
            setAsset(event.target.value)
            var asset = assetList.find(x => x.name + "-" + x.assetid == event.target.value);
            inspectionList = asset['inspectionList']
            if (inspectionList && inspectionList.length > 0) {
                inspectionList.forEach(res => {
                    if (!modDeptList.includes(res.department)) {
                        modDeptList.push(res.department)
                    }
                })
                setDeptList([...modDeptList])
            }
        } else if (item.field == 'department') {
            setDept(event.target.value)
        } else if (item.field == 'schedulingFrequency') {
            if (event.target.value == 'One Time') {
                setSched(true)
            } else {
                setSched(false)
            }

        } else if (item.field == 'inspectionCategory') {
            modAspectList = [];
            var inspectionList = [];
            if (event.target.value && event.target.value.length > 0) {
                var asset = assetList.find(x => x.name + "-" + x.assetid == assetId);
                inspectionList = asset['inspectionList']
                var category = [];
                category = event.target.value;
                inspectionList = inspectionList.filter(x => x.department == dept && category.includes(x.inspectionBasic) && x.isActive)
                if (inspectionList && inspectionList.length > 0) {
                    inspectionList.forEach(res => {
                        if (!modAspectList.includes(res.aspect)) {
                            modAspectList.push(res.aspect)
                        }
                    })
                    setAspectList([...modAspectList])
                }
            } else {
                setAspectList([...[]])
            }

        } else if (item.field == 'section') {
            const asset = assetList.filter(x => event.target.value == x.section)
            let modAssetList = [];
            asset?.forEach((item) => {
                if (item.state === "Operational") modAssetList.push(item.name + "-" + item.assetid);
            })
            var db = valueDB;
            db["assetId"] = modAssetList;
            setValueDB(db);
        }
    }


    function handleSave(values) {
        apiService(myConfig.addInspectionEP, values)
            .then(resp => {
                setUpdateView(false);
                if (resp.data == 'Inspection Already Exist.') {
                    setSubmitionError(true);
                    setErrorMessage('Inspection and this department are already exits!')
                } else {
                    setSubmitionCompleted(true);
                }
            })
            .catch(error => {
                setUpdateView(false);
                if (error['response'] && error['response']['data'] == 'Inspection Already Exist.') {
                    setSubmitionError(true);
                    setErrorMessage('Inspection and this department are already exits!')
                } else {
                    setSubmitionCompleted(true);
                }
            });
    }

    function handleUpdate() {
        var aspect = formValues['aspect'];
        var aspect2 = inspection.aspect;
        aspect2 = aspect2.concat(aspect);
        let data = { "_id": inspection._id, "aspect": aspect2 };
        apiService2('POST', myConfig.updateInspectionAspectEP, data)
            .then(resp => {
                setUpdateView(false);
                if (resp['response'] && resp['response']['status'] == 500) {
                    setSubmitionError(true);
                    setErrorMessage('Inspection and this department are already exits!')
                } else {
                    setSubmitionCompleted(true);
                }
            })
            .catch(error => {
                setUpdateView(false);
                if (error['response'] && error['response']['status'] == 500) {
                    setSubmitionError(true);
                    setErrorMessage('Inspection and this department are already exits!')
                } else {
                    setSubmitionCompleted(true);
                }
            });
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={maxWidth}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                {!isUpdateView && !isSubmitionCompleted && !isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Add Inspection </DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6"></DialogContentText>
                            <Formik
                                enableReinitialize
                                key={fakecount}
                                initialValues={initialValues}
                                onSubmit={(values) => {
                                    var asset = assetList.find(x => x.name + "-" + x.assetid == values.assetId)
                                    values['assetId'] = asset?._id;
                                    values['assetid'] = asset?.assetid;
                                    values['section'] = asset?.section;
                                    values['subsection'] = asset?.subsection;
                                    setFormValues(values)
                                    var inspect = inspectionList.find(x => x.assetid == values['assetid'] && x.department == values['department'] && x.schedulingFrequency == values['schedulingFrequency'] && x.schedulingFrequency != 'One Time');
                                    var i = 0;
                                    if (inspect) {
                                        values['aspect'].forEach(res => {
                                            if (inspect.aspect.includes(res)) {
                                                i++;
                                            }
                                        })
                                    }
                                    if (!inspect || values['aspect'].length <= i) {
                                        handleSave(values);
                                    } else {
                                        setInspection(inspect);
                                        setUpdateView(true);
                                    }
                                }}
                                // Need to expand the validation schema more later
                                validationSchema={Yup.object().shape({
                                    assetId: Yup.string().required("Asset is required"),
                                    department: Yup.string().required("Department is required"),
                                    schedulingFrequency: Yup.string().required("Scheduling Frequency is required"),
                                    inspectionCategory: Yup.array().required("Inspection Category is required"),
                                    aspect: Yup.array().required("Aspect is required"),
                                    approvalRequired: Yup.string().required("Approval Required is required"),
                                    safetyClearance: Yup.string().required("Safety Clearance is required"),

                                })}>
                                {props => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        resetForm,
                                    } = props;
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            {/*  */}
                                            <Grid container spacing={3}>
                                                {(() => {
                                                    let result = [];
                                                    let enumlist = [];
                                                    let label;

                                                    for (const item of inspectionModel.filter(x => x.required == true)) {
                                                        label = item['label']
                                                        console.log(item);

                                                        if (item.hasOwnProperty("required")) { label = item.required ? label + "*" : label } else label = label;
                                                        if (item['select'] == true) {
                                                            if (item.value) {
                                                                enumlist = valueDB[item['field']];
                                                            } else if (item.field == 'department') {
                                                                enumlist = deptList;
                                                            } else if (item.field == 'aspect') {
                                                                enumlist = aspectList;
                                                            } else {
                                                                enumlist = item.enum
                                                            }
                                                            if (item.multiple) {
                                                                console.log(enumlist);
                                                                console.log('multiSelectValue[item.field]', multiSelectValue[item.field]);

                                                                result.push(
                                                                    <Grid item xs={6}>
                                                                        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-label"
                                                                            id="demo-simple-select"
                                                                            name={item.field}
                                                                            value={multiSelectValue[item.field]}
                                                                            error={errors[item.field] && touched[item.field]}
                                                                            onBlur={handleBlur}
                                                                            multiple
                                                                            renderValue={(selected) => selected.join(', ')}
                                                                            onChange={e => {
                                                                                handleChange(e);
                                                                                multiSelectValue[item.field] = e.target.value;
                                                                                setMultiSelectValue(multiSelectValue);
                                                                                handleDepart(item, e);
                                                                            }}
                                                                            margin="normal"
                                                                            type="text"
                                                                            select
                                                                            defaultValue={''}
                                                                            sx={{ display: 'flex', width: "100%", marginTop: 0 }}>
                                                                            {enumlist.map((option) => (
                                                                                <MenuItem key={option} value={option}>
                                                                                    <Checkbox checked={multiSelectValue[item.field]?.indexOf(option) > -1} />
                                                                                    <ListItemText primary={option} />
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                        <FormHelperText style={{ color: 'red', fontSize: '15px' }}>{errors[item.field] && touched[item.field] ? errors[item.field] : ""}</FormHelperText>
                                                                    </Grid>)
                                                            } else if (item.condition) {
                                                                if (values[item.conditionField] == item.conditionValue) {
                                                                    result.push(
                                                                        <Grid item xs={6}>
                                                                            <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                                                                            <TextField
                                                                                id="outlined-basic"
                                                                                name={item.field}
                                                                                value={values.key}
                                                                                error={errors[item.field] && touched[item.field]}
                                                                                onBlur={handleBlur}
                                                                                onChange={e => { handleChange(e); handleDepart(item, e) }}
                                                                                margin="normal"
                                                                                type="text"
                                                                                select
                                                                                defaultValue={''}
                                                                                sx={{ display: 'flex', width: "100%", marginTop: 0 }}>
                                                                                {enumlist.map((option) => (
                                                                                    <MenuItem key={option} value={option}>
                                                                                        {option}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>
                                                                            <FormHelperText style={{ color: 'red', fontSize: '15px' }}>{errors[item.field] && touched[item.field] ? errors[item.field] : ""}</FormHelperText>
                                                                        </Grid>)
                                                                }
                                                            } else {
                                                                result.push(
                                                                    <Grid item xs={6}>
                                                                        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                                                                        <TextField
                                                                            id="outlined-basic"
                                                                            name={item.field}
                                                                            value={values.key}
                                                                            error={errors[item.field] && touched[item.field]}
                                                                            onBlur={handleBlur}
                                                                            onChange={e => { handleChange(e); handleDepart(item, e) }}
                                                                            margin="normal"
                                                                            type="text"
                                                                            select
                                                                            defaultValue={''}
                                                                            sx={{ display: 'flex', width: "100%", marginTop: 0 }}>
                                                                            {enumlist.map((option) => (
                                                                                <MenuItem key={option} value={option}>
                                                                                    {option}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </TextField>
                                                                        <FormHelperText style={{ color: 'red', fontSize: '15px' }}>{errors[item.field] && touched[item.field] ? errors[item.field] : ""}</FormHelperText>
                                                                    </Grid>)
                                                            }
                                                        } else if (item.field == 'date') {
                                                            if (sched) {
                                                                result.push(
                                                                    <Grid item xs={6}>
                                                                        <DatePicker
                                                                            label={item.label}
                                                                            value={values[item.field]}
                                                                            //maxDate={maxDate}
                                                                            sx={{ width: '100%', marginTop: 2 }}
                                                                            onChange={(value) => {
                                                                                props.setFieldValue('date', value.toDate())
                                                                            }}
                                                                        />
                                                                    </Grid>)
                                                            }
                                                        } else {
                                                            result.push(
                                                                <Grid item xs={6}>
                                                                    <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                                                                    <TextField
                                                                        id="outlined-basic"
                                                                        error={errors.name && touched.name}
                                                                        placeholder={label}
                                                                        name={item.field}
                                                                        value={values.key}
                                                                        onChange={e => { handleChange(e); handleDepart(item, e) }}
                                                                        onBlur={handleBlur}
                                                                        helperText=""
                                                                        margin="normal"
                                                                        type="text"
                                                                        sx={{ display: 'flex', width: "100%" }} />
                                                                </Grid>)
                                                        }
                                                    }
                                                    return result;
                                                })()}
                                            </Grid>
                                            <DialogActions>
                                                <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                                                    Cancel
                                                </Button>
                                                <Button type="button" variant="contained" color="primary" onClick={() => resetForm({ values: setInitialValues(getInitialValues()) })}>
                                                    Reset
                                                </Button>
                                                <Button type="submit" variant="contained" color="primary">
                                                    Submit
                                                </Button>
                                            </DialogActions>

                                        </form>
                                    )
                                }}
                            </Formik>
                        </DialogContent>
                    </React.Fragment>
                )}
                {!isUpdateView && isSubmitionCompleted && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title"></DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">Inspection Added Successfully</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
                {!isUpdateView && isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Sorry! Add Inspection Failed</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">
                                {errorMessage}</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleRetry}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isUpdateView && (<React.Fragment>
                    <DialogTitle id="form-dialog-title">Add Inspection</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant="h6">
                            You want to update previous Inspection</DialogContentText>
                        <DialogActions>
                            <Button type="button" variant="contained" color="secondary" onClick={handleUpdate}>
                                Yes
                            </Button>
                            <Button type="button" variant="contained" color="secondary" onClick={handleSave}>
                                No
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </React.Fragment>
                )}
            </Dialog>
        </React.Fragment>

    );
}
export default AddInspection;