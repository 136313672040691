import React, { useState } from "react";
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper
} from "@mui/material";
import { myConfig } from "../../settings";
import { useSelector } from 'react-redux';
import { accesslistModel } from "../../schema/schema";
import { apiService2 } from "../../features/apiService";

// API End Point

let fakecount = 0;
const AddAccessControl = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(props.open.state);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [isSubmitionError, setSubmitionError] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const [errorMessage, setErrorMessage] = useState('');
    const [accesslist, setAccessList] = useState([]);


    if (props.open.state & !open) {
        setOpen(true);
        let accList = [];
        const category = props.open.category;
        if (category.accesslist && category.accesslist.length > 0) {
            category.accesslist.forEach(element => {
                accList.push({ name: element.name, isCreate: element.isCreate, isView: element.isView, isEdit: element.isEdit, isDeleted: element.isDeleted, isAll: element.isAll });
            });
        } else {
            accesslistModel.forEach(element => {
                accList.push({ name: element.label, isCreate: false, isView: false, isEdit: false, isDeleted: false, isAll: false });
            });
        }
        setAccessList(accList)
    }

    // Handle States for the dialog box
    function handleClose() {
        setOpen(false);
        setSubmitionError(false);
        setSubmitionCompleted(false);
        props.open.setfn();
    }

    function handleTryAgain() {
        setSubmitionError(false);
        setErrorMessage('');
    }

    function handleChange(event, filed, i) {
        let accesslistTemp = accesslist;
        if (filed == 'isAll' || (filed == 'isDeleted' && event.target.checked)) {
            accesslistTemp[i]['isCreate'] = event.target.checked;
            accesslistTemp[i]['isView'] = event.target.checked;
            accesslistTemp[i]['isEdit'] = event.target.checked;
            accesslistTemp[i]['isDeleted'] = event.target.checked;
            accesslistTemp[i]['isAll'] = event.target.checked;
        } else {
            accesslistTemp[i][filed] = event.target.checked;
            if (accesslistTemp[i]['isCreate'] && accesslistTemp[i]['isView'] && accesslistTemp[i]['isEdit'] && accesslistTemp[i]['isDeleted']) {
                accesslistTemp[i]['isAll'] = true;
            } else {
                accesslistTemp[i]['isAll'] = false;
            }
        }
        setAccessList([...accesslistTemp]);
    }


    function handleSave() {
        const category = props.open.category;
        let details = { _id: category._id, accesslist: accesslist }
        apiService2('POST', myConfig.updateItemAccessCategory, details)
            .then(resp => {
                if (resp.data.message) {
                    setErrorMessage(resp.data.message);
                    setSubmitionError(true);
                } else {
                    setSubmitionCompleted(true);
                }
            })
            .catch(error => {
                setSubmitionError(true);
                setSubmitionCompleted(true);
            });

    }



    return (
        <React.Fragment>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'md'}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                {!isSubmitionCompleted && !isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Add Access Category</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6"></DialogContentText>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{ textAlign: "left" }}>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Create</TableCell>
                                            <TableCell>Read</TableCell>
                                            <TableCell>Update</TableCell>
                                            <TableCell>Delete</TableCell>
                                            <TableCell>All</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {accesslist.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell> <Checkbox
                                                    labelStyle={{ color: 'white' }}
                                                    iconStyle={{ fill: 'white' }}
                                                    color="secondary"
                                                    checked={row.isCreate}
                                                    onChange={(event) => handleChange(event, 'isCreate', index)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                /></TableCell>
                                                <TableCell><Checkbox
                                                    labelStyle={{ color: 'white' }}
                                                    iconStyle={{ fill: 'white' }}
                                                    color="secondary"
                                                    checked={row.isView}
                                                    onChange={(event) => handleChange(event, 'isView', index)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                /></TableCell>
                                                <TableCell> <Checkbox
                                                    labelStyle={{ color: 'white' }}
                                                    iconStyle={{ fill: 'white' }}
                                                    color="secondary"
                                                    checked={row.isEdit}
                                                    onChange={(event) => handleChange(event, 'isEdit', index)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                /></TableCell>
                                                <TableCell> <Checkbox
                                                    labelStyle={{ color: 'white' }}
                                                    iconStyle={{ fill: 'white' }}
                                                    color="secondary"
                                                    checked={row.isDeleted}
                                                    onChange={(event) => handleChange(event, 'isDeleted', index)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                /></TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        labelStyle={{ color: 'white' }}
                                                        iconStyle={{ fill: 'white' }}
                                                        color="secondary"
                                                        checked={row.isAll}
                                                        onChange={(event) => handleChange(event, 'isAll', index)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    /></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <DialogActions>
                                <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button type="button" variant="contained" color="primary" onClick={handleSave}>
                                    Save
                                </Button>
                            </DialogActions>

                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionCompleted && !isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Thanks! Access Family Added</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">Thanks</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Sorry! Add Access Family Failed</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6"> {errorMessage ? errorMessage : 'Try Again Later'}</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleTryAgain}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
            </Dialog>
        </React.Fragment>
    );
}

export default AddAccessControl;