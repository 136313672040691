import axios from 'axios'
import { myConfig } from "../../settings.js";

const API_URL = myConfig.loginUserEP;
const API_REGISTER_URL = myConfig.registerUserEP;

// Register user
const register = async (userData) => {
  const response = await axios.post(API_REGISTER_URL, userData)
  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data))
  }
  return response.data
}

// Login user
const login = async (userData) => {
  const response = await axios.post(API_URL, userData)
  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data))
    localStorage.setItem('facilitylist', JSON.stringify(response.data.facility))
    localStorage.setItem('timeZone', JSON.stringify(response.data.timeZone))
  }
  console.log("After login-->", response.data);
  return response.data
}

// Logout user
const logout = () => {
  console.log("logout received");
  localStorage.removeItem('user');
  localStorage.removeItem('facilitylist');
  localStorage.removeItem('facility');
  console.log("local storage=>", localStorage.getItem('user'));
}

const authService = {
  register,
  logout,
  login,
}

export default authService
