import React, { useState, useEffect } from "react";
import { Grid, MenuItem, Table, TableCell, TableContainer, TableHead, TableRow, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import axios from "axios";
import {
    Button,
    withStyles,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper
} from "@mui/material";
import { useSelector } from 'react-redux';
import { apiService2 } from "../../features/apiService";
import { Formik } from "formik";
import { myConfig } from "../../settings";
import { toast } from "react-toastify";
import { uploadFile } from "../../features/helpers/awsupload";
const TradeCertification = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(props.open.state);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [isSubmitionError, setSubmitionError] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const [rowList, setRow] = useState([]);
    const [title, setTitle] = useState('');
    const [file, setFile] = useState('');
    if (props.open.state & !open) {
        setOpen(true);
        setRow([]);
        const resource = props.open.resource;
        if (resource.certification && resource.certification.length > 0) {
            setRow([...resource.certification])
        }
    }

    // Handle States for the dialog box
    function handleClose() {
        setOpen(false);
        setSubmitionError(false);
        setSubmitionCompleted(false);
        props.open.setfn();

    }


    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        await uploadFile(file, 'resource')
       // setFile(file)
    };

    function handleSave() {
        const formData = new FormData();
        const resource = props.open.resource;
        rowList.forEach((res, i) => {
            formData.append(`file`, res.file);
            formData.append(`title`, res.title);
        })
        formData.append(`id`, resource._id);
        // 
        apiService2('POST', myConfig.uploadFileResource, formData)
            .then(resp => {
                setSubmitionCompleted(true);
            })
            .catch(error => {
                setSubmitionError(true);
                setSubmitionCompleted(true);
            });
    }


    return (
        <React.Fragment>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'md'}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                {!isSubmitionCompleted && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Upload Trade Certification</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6"></DialogContentText>
                            <Formik
                                enableReinitialize
                                initialValues={''}
                                onSubmit={(values, { setSubmitting }) => {
                                    if (title && title == '' && file) {
                                        setSubmitting(true);
                                        let data = rowList;
                                        data.push({ title: title, fileName: file.name, file: file })
                                        setRow([...data])
                                        setTitle('')
                                    } else {
                                        toast.error('Please fill all the fields')
                                    }

                                }} >
                                {props => {
                                    const {
                                        values,
                                        isSubmitting,
                                        handleSubmit,
                                        handleReset
                                    } = props;
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={5}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label={'File Name'}
                                                        name={'fileName'}
                                                        value={title}
                                                        onChange={(e) => { setTitle(e.target.value) }}
                                                        style={{ width: "100%" }}
                                                        helperText=""
                                                        margin="normal"
                                                        type="text"
                                                    />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <TextField type="file" id="outlined-basic" onChange={handleFileUpload} style={{ width: "100%", marginTop: 15 }} />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Button type="submit" variant="contained" color="primary"  >
                                                        Add
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    );
                                }}
                            </Formik>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{ textAlign: "left" }}>
                                            <TableCell>File Name</TableCell>
                                            <TableCell>File</TableCell>
                                        </TableRow>
                                        {rowList.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    {row.title}
                                                </TableCell>
                                                <TableCell >
                                                    {row.fileName}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableHead>
                                </Table>
                            </TableContainer>
                            <DialogActions>
                                <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                                    Cancel
                                </Button>

                                <Button type="button" variant="contained" color="primary" onClick={handleSave}>
                                    Submit
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )
                }
                {
                    isSubmitionCompleted && (
                        <React.Fragment>
                            <DialogTitle id="form-dialog-title">Thanks! Update Trade Certification</DialogTitle>
                            <DialogContent>
                                {/* <DialogContentText variant="h6">Thanks</DialogContentText> */}
                                <DialogActions >
                                    <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                        Back to app
                                    </Button>
                                </DialogActions>
                            </DialogContent>
                        </React.Fragment>
                    )
                }
                {
                    isSubmitionError && (
                        <React.Fragment>
                            <DialogTitle id="form-dialog-title">Sorry! upload Trade Certification Failed</DialogTitle>
                            <DialogContent>
                                <DialogContentText variant="h6">Try Again Later</DialogContentText>
                                <DialogActions>
                                    <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                        Back to app
                                    </Button>
                                </DialogActions>
                            </DialogContent>
                        </React.Fragment>
                    )
                }
            </Dialog >
        </React.Fragment >
    );
}

export default TradeCertification;