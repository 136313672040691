import { Box, Typography, useTheme, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Tooltip, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { apiService, apiService2 } from '../../features/apiService';
import { MaterialReactTable } from "material-react-table";
import { myConfig } from "../../settings.js";
import { breakdowncodeModel, BreakBownCodeModel } from "../../schema/schema";
import { toast } from 'react-toastify';
import { getAccess } from "../../features/helpers/authhelper";
import EditBreakBownCode from "./edit";
import AddBreakBownCode from "./add";
import { tableView } from "../../features/helpers/table.js";

const BreakBownCode = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [];

  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [BreakBownCodeList, setBreakBownCodeList] = useState([]);
  const [BreakBownCode, setBreakBownCode] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [selectedrow, setRow] = useState(-1);
  const [rowSelection, setRowSelection] = useState({});
  const access = getAccess(user, 'setup');

  for (const item of breakdowncodeModel) {
    if (item.hasOwnProperty("skip")) {
      if (item.skip) continue;
    }
    columns.push({ accessorKey: item['field'], header: item['label'], size: 150 });
  }
  useEffect(() => {
    apiService2('GET', myConfig.getBreakBownCodeEP)
      .then(response => {
        var breaklist = response.data;
        breaklist.forEach((item, index) => { item.cdate = new Date(item.createdOn).toLocaleDateString(); })
        setBreakBownCodeList(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, [refresh]);

  function handleClose() {
    setOpen(false);
    setOpenEdit(false);
    setOpenDelete(false);
    setRefresh(!refresh);
  }

  useEffect(() => {
    const selectvalue = Object.keys(rowSelection)[0];
    const selected = Object.values(rowSelection)[0];
    let index = 0;
    if (selected) {
      for (const tempwo of BreakBownCodeList) {
        if (tempwo._id == selectvalue) {
          setBreakBownCode(tempwo);
          setRow(index);
          break;
        }
        ++index;
      }
    } else {

      setBreakBownCode({});
    }
  }, [rowSelection]);

  function handleClickOpen() {
    setOpen(true);
    setRefresh(false);
  }


  function handleDelete() {
    apiService2('POST', myConfig.deleteBreakBownCodeEP, { "_id": BreakBownCode._id })
      .then(resp => {
        handleClose();
      })
      .catch(error => {
        console.log(["Resource Add Failed->", error]);
        handleClose();
      });
  }


  function CustomToolbar() {
    return (
      <Box className="mb-1">
        {
          access?.isCreate && <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleClickOpen}>
            Add
          </Button>
        }
      </Box>
    );
  }
  return (
    <Box m="20px 20px 20px 20px">
      <Box
        height="70vh">
        {CustomToolbar()}
        {tableView(
          false, columns, BreakBownCodeList, setRowSelection,
          rowSelection, '_id',
          true,
          ({ row, table }) => [
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
              {
                access.isEdit && <Tooltip title="Edit">
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      setBreakBownCode({ ...row.original })
                      setOpenEdit(true);
                    }}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              }
              {
                access.isDeleted && <Tooltip title="Delete">
                  <IconButton
                    color="error"
                    onClick={() => {
                      setBreakBownCode({ ...row.original })
                      setOpenDelete(true);
                    }}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              }
            </Box>
          ])
        }
        <Dialog
          open={openDelete}
          fullWidth={true}
          maxWidth={'xs'}
          onClose={handleClose}
          aria-labelledby="form-dialog-title">
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Delete BreakBown Code</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Are you sure you want to delete?</DialogContentText>
              <DialogActions sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}>
                <Button type="button" variant="contained" color="secondary" onClick={handleDelete}>
                  Confirm
                </Button>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Close
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        </Dialog>
        <AddBreakBownCode open={{ state: open, setfn: handleClose }} />
        <EditBreakBownCode open={{ state: openEdit, setfn: handleClose, rowid: selectedrow, BreakBownCode: BreakBownCode }} />
      </Box>
    </Box>
  );
};

export default BreakBownCode;
