import { Box, useTheme, Tabs, Tab, Button } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { table, tokens } from "../../theme";
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import { myConfig } from "../../settings.js";
import { useSelector } from "react-redux";
import { apiService } from "../../features/apiService.js";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

const Reports = () => {
  const { user } = useSelector((state) => state.auth);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [report, setReport] = useState([]);
  const [woreport, setWOReport] = useState([]);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  //const [refresh, setRefresh] = useState(false);
  const columns = {
    "Asset ID": "assetid",
    "Asset Name": "assetname",
    "MTTR (in Hrs)": "mttr",
  };
  const wocolumns = {
    "Asset ID": "assetid",
    "Asset Name": "assetname",
    "Breakdown Time": "bd_time",
    "Maintenance Start Time": "maintstart",
    "Maintenance Start Duration": "maintstart_dur",
    "Under Observation Time": "underobs",
    "Under Observation Duration": "underobs_dur",
    "Maintenance Finish Time": "maintfinish",
    "Maintenance Finish Duration": "maintfinish_dur",
    "Total Duration (Hrs)": "total_dur",
  };
  var columnList = [];
  var woColumnList = [];
  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  function numberSorting(rowA, rowB, columnId) {
    // your custom sorting logic
    const a = Number(rowA.getValue(columnId));
    const b = Number(rowB.getValue(columnId));
    console.log("sortfn->", a, b, columnId);
    return a > b ? 1 : -1;
  }

  for (const column in columns) {
    if (columns[column] == "mttr")
      columnList.push({
        accessorKey: columns[column],
        header: column,
        size: 150,
        sortingFn: numberSorting,
      });
    else
      columnList.push({
        accessorKey: columns[column],
        header: column,
        size: 150,
      });
  }

  for (const column in wocolumns) {
    woColumnList.push({
      accessorKey: wocolumns[column],
      header: column,
      size: 150,
    });
  }
  //console.log(woColumnList);

  /**
   * Convert to India Time Zone
   * TO-DO: Read teh timezone from the configuration and apply it for the customer/facility
   * @param {} datestr
   * @returns
   */
  function convertTZ(datestr) {
    if (datestr === "") return "";
    if (datestr == 0) return "";
    const newdate = new Date(datestr);
    const convDate = new Intl.DateTimeFormat("en-IN", {
      dateStyle: "short",
      timeStyle: "short",
    }).format(newdate);
    return convDate;
  }

  function round(value, decimals) {
    if (value == 0) return 0;
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
  }

  function getReportData(response) {
    var modData = [];
    for (const woitem of response) {
      if (woitem.status == "INVALID") {
        continue;
      }
      //console.log("status=", woitem.status);
      var woDict = { assetid: woitem.assetid, assetname: woitem.assetname };
      woDict["bd_time"] = convertTZ(woitem.bd.time);
      woDict["bd_dur"] = woitem.bd.dur;
      woDict["maintstart"] = convertTZ(woitem.maintstart.time);
      woDict["maintstart_dur"] = round(woitem.maintstart.dur, 2);
      woDict["underobs"] = convertTZ(woitem.underobs.time);
      woDict["underobs_dur"] = round(woitem.underobs.dur, 2);
      woDict["maintfinish"] = convertTZ(woitem.maintfinish.time);
      woDict["maintfinish_dur"] = round(woitem.maintfinish.dur, 2);
      woDict["total_dur"] = round(woitem.totaldur, 2);
      woDict.status = woitem.status;
      modData.push(woDict);
    }
    console.log("modData->", modData);
    return modData;
  }

  //Get all the assets with sensor based state information
  useEffect(() => {
    apiService(myConfig.getMTTREndPoint)
      .then((response) => {
        console.log("Resp->", response.data);
        setReport(response.data);
      })
      .catch((error) => {
        console.error("Error in getting reports->", error);
      });
  }, []);

  //Get all the POC Report
  useEffect(() => {
    apiService(myConfig.getPOCReportEP)
      .then((response) => {
        console.log("Resp->", response.data);
        const reportData = getReportData(response.data);
        setWOReport(reportData);
      })
      .catch((error) => {
        console.error("Error in getting POC report->", error);
      });
  }, []);

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(report);
    download(csvConfig)(csv);
  };

  const handleExportPDFData = (rows) => {
    const doc = new jsPDF();
    const tableData = rows.map((row) => Object.values(row.original));
    const tableHeaders = columnList.map((c) => c.header);
    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save("mrt-pdf-example.pdf");
  };

  const handleExportData2 = () => {
    const csv = generateCsv(csvConfig)(woreport);
    download(csvConfig)(csv);
  };

  const handleExportPDFData2 = (rows) => {
    const doc = new jsPDF();
    const tableData = rows.map((row) => Object.values(row.original));
    const tableHeaders = woColumnList.map((c) => c.header);
    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save("mrt-pdf-example.pdf");
  };

  return (
    <Box m="20px">
      <Header title="Reports" subtitle="" />
      <Box
        m="40px 0 0 0"
        height="70vh">
        <Tabs
          value={currentTabIndex}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label="MTTR" />
          <Tab label="WO Report" />
        </Tabs>

        {/* TAB 1 Contents */}
        {currentTabIndex === 0 && (
          <MaterialReactTable
            columns={columnList}
            data={report}
            enableMultiRowSelection={false}
            enableStickyFooter
            renderTopToolbarCustomActions={({ table }) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    gap: "16px",
                    padding: "8px",
                    flexWrap: "wrap",
                  }}
                >
                  <Button variant="contained" color="primary"
                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                    onClick={handleExportData}
                    startIcon={<FileDownloadIcon />}
                  >
                    Export All Excel
                  </Button>

                  <Button variant="contained" color="primary"
                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                    onClick={() =>
                      handleExportPDFData(table.getPrePaginationRowModel().rows)
                    }
                    startIcon={<FileDownloadIcon />}
                  >
                    Export All PDF
                  </Button>
                </Box>
              );
            }}
            initialState={{
              density: "compact",
              expanded: true, //expand all groups by default
              //grouping: ['assigneddept'], //an array of columns to group by by default (can be multiple)
              pagination: { pageIndex: 0, pageSize: 15 },
              sorting: [{ id: "mttr", desc: true }], //sort by state by default
            }}
            muiTopToolbarProps={{
              sx: {
                  backgroundColor: theme.palette?.mode === 'dark' ? table.dark.backgroundColor : table.light.backgroundColor,
              }
          }}
          muiTableHeadCellProps={{
              sx: {
                  fontSize: '16px',
                  backgroundColor:  theme.palette?.mode === 'dark' ?  table.dark.backgroundColor : table.light.backgroundColor,
              }
          }}
          muiTableBodyCellProps={{
              sx: {
                  fontSize: '16px',
                  backgroundColor:  theme.palette?.mode === 'dark' ?  table.dark.backgroundColor : table.light.backgroundColor,
              },
          }}
          muiBottomToolbarProps={{
              sx: {
                  backgroundColor: theme.palette?.mode === 'dark' ? table.dark.backgroundColor : table.light.backgroundColor,
              }
          }}
            cell={(value) => String(value)}
            muiSelectCheckboxProps={{ color: colors.primary[400] }}
          />
        )}
        {currentTabIndex === 1 && (
          <MaterialReactTable
            columns={woColumnList}
            data={woreport}
            enableMultiRowSelection={false}
            renderTopToolbarCustomActions={({ table }) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    gap: "16px",
                    padding: "8px",
                    flexWrap: "wrap",
                  }}
                >
                  <Button variant="contained" color="primary"
                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                    onClick={handleExportData2}
                    startIcon={<FileDownloadIcon />}
                  >
                    Export All Excel
                  </Button>
                  <Button variant="contained" color="primary"
                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                    onClick={() =>
                      handleExportPDFData2(table.getPrePaginationRowModel().rows)
                    }
                    startIcon={<FileDownloadIcon />}
                  >
                    Export All PDF
                  </Button>
                </Box>
              );
            }}
            enableSorting
            enableStickyFooter
            initialState={{
              density: "compact",
              expanded: true, //expand all groups by default
              //grouping: ['assetid', 'assetname'], //an array of columns to group by by default (can be multiple)
              pagination: { pageIndex: 0, pageSize: 15 },
              sorting: [{ id: "assetid", desc: true }], //sort by state by default
            }}
            muiTopToolbarProps={{
              sx: {
                backgroundColor: theme.palette?.mode === 'dark' ? table.dark.backgroundColor : table.light.backgroundColor,
              }
            }}
            muiTableHeadCellProps={{
              sx: {
                fontSize: '16px',
                backgroundColor: theme.palette?.mode === 'dark' ? table.dark.backgroundColor : table.light.backgroundColor,
              }
            }}
            muiTableBodyCellProps={{
              sx: {
                fontSize: '16px',
                backgroundColor: theme.palette?.mode === 'dark' ? table.dark.backgroundColor : table.light.backgroundColor,
              },
            }}
            muiBottomToolbarProps={{
              sx: {
                backgroundColor: theme.palette?.mode === 'dark' ? table.dark.backgroundColor : table.light.backgroundColor,
              }
            }}
            //getRowId={(row) => row.wrnumber} //give each row a more useful id
            //onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
            //state={{ rowSelection }} //pass our managed row selection state to the table to use
            cell={(value) => String(value)}
            muiSelectCheckboxProps={{ color: "secondary" }}
          />
        )}
      </Box>
    </Box>
  );
};

export default Reports;
