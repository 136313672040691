import { Box, Typography, useTheme, Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyIcon from '@mui/icons-material/Key';
import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify'
import { myConfig } from "../../settings.js";
import { useSelector } from 'react-redux';
import { apiService, apiService2 } from '../../features/apiService';
import { MaterialReactTable } from "material-react-table";
import {  procurementModel } from "../../schema/schema";
// import AddProcurement from "./addProcurement";
// import EditProcurement from "./editProcurement";
// import ViewAccessControl from "./accesscontrol";
import { getAccess } from "../../features/helpers/authhelper";
import AddProcurement from "./add";
import EditProcurement from "./edit";
import ReceiveProduct from "./receiveproduct";
import { timeZoneConverter } from "../../features/helpers/commonhelper.js";
import { tableView } from "../../features/helpers/table.js";

const ProcurementOrder = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const columns = [];

    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openReceive, setOpenReceive] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [procurementList, setProcurementList] = useState([]);
    const [procurement, setProcurement] = useState([]);
    const { user } = useSelector((state) => state.auth);
    const [selectedrow, setRow] = useState(-1);
    const [rowSelection, setRowSelection] = useState({});
    const [message, setMessage] = useState('');
    const access = getAccess(user, 'procurement');


    for (const item of procurementModel) {
        if (item.hasOwnProperty("skip")) {
            if (item.skip) continue;
        }
        columns.push({ accessorKey: item['field'], header: item['label'], size: 150 });
    }

    useEffect(() => {
        apiService2('GET', myConfig.getProcurementEP)
            .then(response => {
                var procurementList = response.data;
                procurementList?.forEach(res => {
                    res.podate = timeZoneConverter(res.podate)
                    res.isActive = res.isActive ? "Active" : "Inactive"
                    res.status = res.isreceived ? "Received" : "Pending"
                    res.cdate = timeZoneConverter(res.createdOn)
                })
                setProcurementList(procurementList);
            })
            .catch(error => {
                console.error(error);
            });
    }, [refresh]);

    useEffect(() => {
        const wrnumber = Object.keys(rowSelection)[0];
        const selected = Object.values(rowSelection)[0];
        let index = 0;
        if (selected) {
            for (const temp of procurementList) {
                if (temp._id == wrnumber) {
                    setProcurement(temp);
                    setRow(index);
                    break;
                }
                ++index;
            }
        } else {
            
            setProcurement({});
        }
    }, [rowSelection]);

    function handleClose() {
        setOpen(false);
        setOpenEdit(false)
        setOpenDelete(false)
        setOpenReceive(false)
        setRefresh(!refresh);
    }

    function handleClickOpen() {
        setOpen(true);
        setRefresh(false);
    }

    function handleClickEdit() {
        if (selectedrow >= 0) {
            setOpenEdit(true);
            setRefresh(false);
        } else toast.warning("Select a any one Procurement first ");
    }

    function handleClickDelete() {
        if (selectedrow >= 0) {
            setMessage("Delete");
            setOpenDelete(true);
        } else toast.warning("Select a any one Procurement first ");
    }

    function handleClickReceive() {
        if (selectedrow >= 0) {
            setOpenReceive(true);
        } else toast.warning("Select a any one Procurement first ");
    }

    function handleDelete() {
        var isActive = procurement.isActive == 'Active' ? false : true;
        apiService2('POST', myConfig.deleteProcurementEP, { "_id": procurement._id, isActive: isActive, message: message })
            .then(resp => {
                handleClose();
            })
            .catch(error => {
                console.log(["Procurement Add Failed->", error]);
                handleClose();
            });
    }


    function CustomToolbar() {
        return (
            <Box className="mb-1">
                {
                    access.isEdit && <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={handleClickEdit}>
                        Edit
                    </Button>
                }
                {
                    access.isDeleted && <Button variant="contained" color="primary" startIcon={<DeleteIcon />} onClick={handleClickDelete}>
                        Delete
                    </Button>
                }
                {
                    access.isCreate && <Button variant="contained" color="primary" onClick={handleClickReceive}>
                        Receive Product
                    </Button>
                }
            </Box>
        );
    }
    return (
        <Box m="20px">
            {/* <Header title="Procurement" subtitle="" /> */}
            <Box
                m="20px 0 0 0"
                 height="70vh">
                {CustomToolbar()}
                {tableView(false,columns, procurementList, setRowSelection, rowSelection, '_id')}
                <AddProcurement open={{ state: open, setfn: handleClose }} />
                 <EditProcurement open={{ state: openEdit, setfn: handleClose, procurement: procurement }} />
                 <ReceiveProduct open={{ state: openReceive, setfn: handleClose, procurement: procurement }} />
                <Dialog
                    open={openDelete}
                    fullWidth={true}
                    maxWidth={'xs'}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title">
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">{message} Procurement</DialogTitle>

                        <DialogContent>
                            <DialogContentText variant="h6">Are you sure you want to {message}?</DialogContentText>
                            <DialogActions sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}>
                                <Button type="button" variant="contained" color="secondary" onClick={handleDelete}>
                                    Confirm
                                </Button>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                </Dialog>
            </Box>
        </Box>
    );
};

export default ProcurementOrder;
