import FactoryIcon from '@mui/icons-material/Factory';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import WorkIcon from '@mui/icons-material/Work';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import InventoryIcon from '@mui/icons-material/Inventory';
import GradingIcon from '@mui/icons-material/Grading';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SummarizeIcon from '@mui/icons-material/Summarize';
import Store from '../scenes/store';
import { Settings, ShoppingBasket } from '@mui/icons-material';

export const MENULIST =[
    {
        title:'Dashboard',
        url:'/',
        icon:<HomeOutlinedIcon />,
        id:'analytics',
        isTitle: false
    },
    {
        title:'Configuration',
        url:'',
        icon:'',
        id:['setup','asset'],
        isTitle: true
    },
    {
        title:'Setup',
        url:'/setup',
        icon:<FactoryIcon />,
        id:'setup',
        isTitle: false
    },
    {
        title:'Assets',
        url:'/assets',
        icon:<WebAssetIcon />,
        id:'asset',
        isTitle: false
    },
    {
        title:'Inventory',
        url:'',
        icon:'',
        id:['itemmaster','store','procurement'],
        isTitle: true
    },
    {
        title:'Item Master',
        url:'/inventory',
        icon:<InventoryIcon />,
        id:'itemmaster',
        isTitle: false
    },
    {
        title:'Store',
        url:'/store',
        icon:<InventoryIcon />,
        id:'store',
        isTitle: false
    },
    {
        title:'Procurement',
        url:'/procurement',
        icon:<ShoppingBasket />,
        id:'procurement',
        isTitle: false
    },
    {
        title:'Procedures',
        url:'',
        icon:'',
        id:['maintenancelibrary','inspection'],
        isTitle: true
    },
    {
        title:'Maintenance Library',
        url:'/mainlib',
        icon:<EngineeringIcon />,
        id:'maintenancelibrary',
        isTitle: false
    },
    {
        title:'Inspection',
        url:'/inspectionlib',
        icon:<AssignmentIcon />,
        id:'inspection',
        isTitle: false
    },
    {
        title:'Work Items',
        url:'',
        icon:'',
        id:['maintenance','workorder','workrequest','workshop'],
        isTitle: true
    },
    {
        title:'Maintenance',
        url:'/maintenance',
        icon:<EngineeringIcon />,
        id:'maintenance',
        isTitle: false
    },
    {
        title:'Work Order',
        url:'/workorder',
        icon:<GradingIcon />,
        id:'workorder',
        isTitle: false
    },
    {
        title:'Work Request',
        url:'/workrequest',
        icon:<WorkIcon />,
        id:'Work Center',
        isTitle: false
    },
    {
        title:'Workshop',
        url:'/workshop',
        icon:<Settings />,
        id:'workrequest',
        isTitle: false
    },
    {
        title:'Reliability',
        url:'',
        icon:'',
        id:['failureprevention'],
        isTitle: true
    },
    {
        title:'Failure Prevention',
        url:'/failurepreventation',
        icon:<WorkIcon />,
        id:'failureprevention',
        isTitle: false
    },
    {
        title:'IIoT',
        url:'',
        icon:'',
        id:['iot'],
        isTitle: true
    },
    {
        title:'Sensor Data',
        url:'/sensordata',
        icon:<SummarizeIcon />,
        id:'iot',
        isTitle: false
    },
    {
        title:'Analysis',
        url:'',
        icon:'',
        id:['analysis'],
        isTitle: true
    },
    {
        title:'Reports',
        url:'/reports',
        icon:<SummarizeIcon />,
        id:'report',
        isTitle: false
    },
    {
        title:'Analytics',
        url:'/analytics',
        icon:<AnalyticsIcon />,
        id:'analytics',
        isTitle: false
    },
]