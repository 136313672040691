
import { tokens } from "../../theme";
import { Box, useTheme, Tab, Tabs, } from "@mui/material";
import { useState } from "react";
import Header from "../../components/Header";
import { useSelector } from "react-redux";
import { getAccess } from "../../features/helpers/authhelper";
import Store from "./store";
import IssueStock from "./issuestock";

const subTitleList = ['Store', 'Request Item']



const StoreView = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [value, setValue] = useState('1');
    const [subTitle, setSubTitle] = useState('store');
    const { user } = useSelector((state) => state.auth);
    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSubTitle(subTitleList[newValue - 1])
    };

    return (
        <Box m="10px 20px 20px 20px">
            <Header title="Store" subtitle={subTitle} />
            <Box
                m="10px 0 0 0"
                 height="70vh">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} textColor="secondary"
                        indicatorColor="secondary">
                        <Tab label="Store" value="1" />
                        <Tab label="Request Item" value="2" />
                    </Tabs>
                </Box>
                {value == 1 && <Store />}
                {value == 2 && <IssueStock />}
            </Box>
        </Box>
    );
}

export default StoreView;