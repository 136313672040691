import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import {
  Button,
  TextField,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  MenuItem
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { myConfig } from "../../../settings";
import { useSelector } from 'react-redux';
import  { apiService } from '../../../features/apiService.js';


// API End Point
const updateAssetEP = myConfig.updateAssetParamEP;

let objindex=0;
let valueobject={"params":[]};

const UpdateAssetParam= (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //let genFormFields = initialiseFields();
  const [open, setOpen] = useState(props.open.state);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [isSubmitionError, setSubmitionError] = useState(false);
  const [formFields, setFormFields] = useState([]);
  const { user } = useSelector((state) => state.auth);
  
  // Handle States for the dialog box
  function handleClose() {
    // console.log("handleClose: Setting open to false");
    setOpen(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);
    setFormFields([]);
    props.open.setfn();
    objindex = 0;
    valueobject={"params":[]};
  }

  function handleClickOpen() {
    // console.log(["Comp: open->", true])
    setSubmitionCompleted(false);
    setOpen(true);
    objindex++;
  }

  // console.log(["Here open->", props.open.state, open, isSubmitionError]);
  if (props.open.state & !open) {
    setOpen(true);
  }
  
  function handleParams() {
    const indexstr = objindex.toString();
    let obj = {field: "param"+ indexstr, subfield: "uom" + indexstr};
    setFormFields([...formFields, obj]);
    console.log("valueobject", valueobject);
    valueobject["params"].push({"name":"param"+ indexstr , "uom": "uom" + indexstr});
    objindex++;
    //console.log(["index, obj->", objindex, formFields]);
  }

  return (
    <React.Fragment>
      <Dialog
        open = {open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        >
      {!isSubmitionCompleted && (
        <React.Fragment>
          <DialogTitle id="form-dialog-title">Update Asset Parameters </DialogTitle>
          <DialogContent>
            <DialogContentText variant="h6"></DialogContentText>
            <Formik
              //enableReinitialize 
              //initialValues= [];
              
              initialValues= {(() => {
                console.log("EndPt=>", updateAssetEP);
                let ivalues = {};
                formFields.forEach((value)=> {
                  if (value.hasOwnProperty("fkey")) {
                    if (!value["fkey"]) {ivalues[value['field']] = "";}
                  } else { ivalues[value['field']] = "";
                  }
                })
                console.log(["Initial values->", ivalues]);
                return ivalues;
              })
              }
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                values['assetid'] = props.open.rowid;
                values['tenantid'] = user.tenantid;
                //console.log("1.Values=>",values);
                //console.log("ValueObject=>",valueobject);
                {(() => {
                  let retobj = {};
                  values['params'] = [];               
                 
                  for (let item in valueobject["params"]) {
                    let tobj = {};
                    for (let tkey in valueobject["params"][item]) {
                      let tval = valueobject["params"][item][tkey];
                      //console.log([tkey, tval]);
                      tobj[tkey] = values[tval];
                      delete values[tval];
                    }
                    
                    values['params'].push(tobj);
                  }
                  
                })()};
                console.log("Values=>",values, "EndPt=>", updateAssetEP);
              
                apiService(updateAssetEP, values)
                .then ( resp => {
                  console.log("Params updated");
                  setSubmitionCompleted(true);
                })
                .catch (error => {
                  console.error(error);
                  setSubmitionError(true);
                  setSubmitionCompleted(true);
                }) 
              }}
              // Need to expand the validation schema more later
              validationSchema={Yup.object().shape({
              })}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                   {(() => {
                      let result = [];
                      let skipflag = false;
                      formFields.forEach(function (vitem) {
                        let key = vitem['field'];
                        //console.log(key);
                        // console.log(vitem);
                        skipflag = false;
                        if (vitem.hasOwnProperty("fkey")) {
                          if (vitem["fkey"]) {skipflag = true;}
                        }
                        if (vitem.hasOwnProperty("skip")) {
                          if (vitem["skip"]) {skipflag = true;}
                        }
                        if (!vitem.hasOwnProperty("enum") && !skipflag) {
                            result.push(
                              <TextField
                                id="outlined-basic"
                                error={errors.name && touched.name}
                                label= { key }
                                name= { key }
                                value={values.key}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText=""
                                margin="normal"
                                type="text"
                              />
                          )
                        if (vitem.hasOwnProperty("subfield")) {
                          let skey = vitem['subfield']
                          //console.log(["skey->",skey]);
                          result.push(
                            <TextField
                              id="outlined-basic"
                              error={errors.name && touched.name}
                              label= { skey }
                              name= { skey }
                              value={values.skey}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText=""
                              margin="normal"
                              type="text"
                          />
                      )}
                          } else if (!skipflag) {
                            //console.log("default-", vitem["enum"][0]);
                            result.push(
                              <React.Fragment>
                              <InputLabel id="simple-label">{key.toUpperCase()}</InputLabel>
                              <Select
                                labelId="simple-label"
                                id="outlined-basic"
                                label={ key.toUpperCase() }
                                error={errors.name && touched.name}
                                name={ key }
                                value= {values.key}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                margin="normal"
                                sx={{ display:'flex'}}
                                defaultValue={vitem["enum"][0]}
                                >
                                {vitem["enum"].map((ivalue ) => (
                                  <MenuItem key={ivalue} value={ivalue}>
                                    {ivalue}
                                  </MenuItem>
                                ))}
                              </Select>
                              </React.Fragment>
                              )
                          }
                        })
                        return result;
                      })()}

                    <DialogActions>
                      <Button type="button" variant="contained" color="primary" onClick={handleParams}>
                        Add Params
                      </Button>
                      <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button type="button" variant="contained" color="primary"
                        onClick={handleReset}
                        disabled={!dirty || isSubmitting}
                      >
                        Reset
                      </Button>
                      <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                      </Button>
                    </DialogActions>
                  </form>
                );
              }}
            </Formik>
          </DialogContent>
        </React.Fragment>
      )}
      {isSubmitionCompleted && (
        <React.Fragment>
          <DialogTitle id="form-dialog-title">Thanks! Asset Param Updated</DialogTitle>
          <DialogContent>
            <DialogContentText variant="h6">Thanks</DialogContentText>
            <DialogActions>
              <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                Back to app
              </Button>
            </DialogActions>
          </DialogContent>
        </React.Fragment>
      )}
      {isSubmitionError && (
        <React.Fragment
        >
          <DialogTitle id="form-dialog-title">Sorry! Update Asset Param Failed</DialogTitle>
          <DialogContent>
            <DialogContentText variant="h6">Try Again Later</DialogContentText>
            <DialogActions>
              <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                Back to app
              </Button>
            </DialogActions>
          </DialogContent>
        </React.Fragment>
      )}
    </Dialog>
  </React.Fragment>
  );
}

export default UpdateAssetParam;