import * as React from "react";
import { useState} from "react";
//import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material";
import Header from "../../components/Header";
import { Box, IconButton, Button, Dialog} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { GridToolbarContainer } from '@mui/x-data-grid';
//import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//import AddIcon from '@mui/icons-material/Add';
import { tokens } from "../../theme";
import { myConfig } from "../../settings";
import { useSelector } from 'react-redux';
import  { apiService } from '../../features/apiService.js';
import  { toast } from 'react-toastify';
import { formCreator } from "../../features/helpers/formhelper";
import { maintenanceModel } from "../../schema/schema.js";



const ViewMaintenance = (props) => {
    const location = useLocation();
    const asset = location.state;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(props.open.state);
    const formFields = maintenanceModel;
    //const columns = [{field: "attr", headerName:"WO Attribute", flex: 1}, {field: "avalue", headerName: "Value", flex: 1,editable: true}];
    let gdata = [];
    var idval = 1;
    //const { user } = useSelector((state) => state.auth);
    const maint = props.open.maintenance;
    
    for (let key in maint ) {
        //console.log(key, wo[key]);
        //columns.push({field: key, headername: key})
          gdata.push({'id': idval, 'attr':key, 'avalue': maint[key]});
          idval +=1;
      }
    
      function handleClose() {
        console.log(["handleClose: Setting open to false", open, props.open.user]);
        setOpen(false);
        props.open.setfn();
       // objindex = 0;
      }
    if (props.open.state & !open) {
      setOpen(true);
      //console.log(["OpenCheck: Setting open to false", open, props.open.user]);
    }

    function CustomToolbar() {
      const theme = useTheme();
      const colors = tokens(theme.palette.mode);
      
      return (
        <Box className="mb-1">
          <Button variant="contained" color="primary" startIcon={<ArrowBackIcon />} onClick={handleClose}>
            Back
          </Button>

        </Box>
      );
    }

  return (
    <Dialog
    open = {open}
    onClose={handleClose}
    aria-labelledby="form-dialog-title"
    maxWidth="xl"
    >
    
      <Header
        title="Maintenance Details"
      />
      <Box
        m="40px 0 0 0"
         height="70vh"
        width ="80vh"
      >
        {formCreator("simple",'view',formFields, maint)}
        {CustomToolbar()}
        </Box>
    </Dialog>
  );
}
export default ViewMaintenance;