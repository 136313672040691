import React, { useState, useEffect } from "react";
import { Grid, MenuItem, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import axios from "axios";
import {
    Button,
    withStyles,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { myConfig } from "../../settings";
import { useSelector } from 'react-redux';
import { apiService2 } from "../../features/apiService";
import { formCreator } from "../../features/helpers/formhelper";
import { meterModel } from "../../schema/schema";


const EditUnit = (props) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(props.open.state);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [isSubmitionError, setSubmitionError] = useState(false);
    const [formFields, setFormFields] = useState(meterModel);
    const { user } = useSelector((state) => state.auth);
    const [initialValues, setInitialValues] = useState({});

    function getInitialValues() {
        let ivalues = {};
        const unit = props.open.unit;
        for (const item of meterModel) {
            ivalues[item.field] = unit[item.field]
        }
        setInitialValues({ ...ivalues })
    }


    if (props.open.state & !open) {
        setOpen(true);
        setSubmitionError(false);
        setSubmitionCompleted(false);
        getInitialValues();
    }

    // Handle States for the dialog box
    function handleClose() {
        setOpen(false);
        setSubmitionError(false);
        setSubmitionCompleted(false);
        props.open.setfn();
    }

    function handleSelect() {

    }


    return (
        <React.Fragment>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'xs'}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                {!isSubmitionCompleted && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Edit unit</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6"></DialogContentText>
                            <Formik
                                enableReinitialize
                                initialValues={initialValues}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(true);
                                    const unit = props.open.unit;
                                    values['_id'] = unit._id;
                                    if(values.unitType === 'Measurement') {
                                        values['uom'] = {
                                            "metric": values.metric,
                                            "imperial": values.imperial
                                        };
                                    }
                                    apiService2('POST', myConfig.updateUnitEP, values)
                                        .then(resp => {
                                            setSubmitionCompleted(true);
                                        })
                                        .catch(error => {
                                            console.log(["unit Add Failed->", error]);
                                            //handleClose();
                                            setSubmitionError(true);
                                            setSubmitionCompleted(true);
                                        });
                                }}
                                validationSchema={Yup.object().shape({
                                    parameter: Yup.string().required("Parameter is required"),
                                    unitType: Yup.string().required("Unit type is required"),
                                })} >
                                {props => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        dirty,
                                        isSubmitting,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        handleReset,
                                        resetForm
                                    } = props;
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            {formCreator("Complex", 'add', formFields, initialValues, values, handleChange, handleBlur, touched, errors, {}, handleSelect)}
                                            <DialogActions>
                                                <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                                                    Cancel
                                                </Button>
                                                <Button type="button" variant="contained" color="primary"
                                                    onClick={() => resetForm()}
                                                    disabled={!dirty || isSubmitting}>
                                                    Reset
                                                </Button>
                                                <Button type="submit" variant="contained" color="primary" disabled={!dirty || isSubmitting}>
                                                    Submit
                                                </Button>
                                            </DialogActions>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionCompleted && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Thanks! unit Added</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">Thanks</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Sorry! Add unit Failed</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">Try Again Later</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
            </Dialog>
        </React.Fragment>
    );
}

export default EditUnit;