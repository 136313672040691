import * as React from "react";
import { useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Card, CardContent, DialogActions, DialogContent, DialogTitle, Grid, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { Box, Button, Dialog } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { tokens } from "../../theme";
import { formCreator } from "../../features/helpers/formhelper";
import { workOrderModel } from "../../schema/schema.js";
import { useEffect } from "react";
import { apiService, apiService2 } from "../../features/apiService";
import { myConfig } from "../../settings";


const ViewWorkOrder = (props) => {
  const location = useLocation();
  const asset = location.state;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const formFields = workOrderModel;
  const [inspectionList, setInspectionList] = useState([]);
  const [resourceList, setResourceList] = useState([]);
  const [spareList, setSpareList] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [reopenList, setReopenList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [wo, setWo] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  let gdata = [];
  var idval = 1;
  console.log("Asset->", searchParams.get("workorder"));

  useEffect(() => {
    var data = { "_id": id };
    apiService2('POST', myConfig.getWorkOrderSingleEP, data)
      .then(response => {
        var resources = response.data;
        if (resources) {
          setWo(resources)
          setResourceList(resources.resourcelist || []);
          setInspectionList(resources.inspectionList || []);
          setSpareList(resources.itemlist || []);
          setTaskList(resources.tasklist || []);
          setReopenList(resources.reopenlogs || []);
        }
      })
      .catch(error => {
        console.error(error);
      })
  }, []);

  function viewDate(dates) {
    const today = new Date(dates);
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${month}/${date}/${year} ${today.getHours()}:${today.getMinutes()}`;
  }





  const goBack = () => {
    navigate(-1); // Goes back to the previous page
  };





  return (
    <Box m="20px">
      <Header title="View WorkOrder" subtitle="" />
      <Box
        m="40px 0px 0px 40px"
        height="70vh">
        <Button variant="contained" onClick={goBack} color="primary" startIcon={<ArrowBackIcon />} >
          Back
        </Button>
        {

          wo && <Card>
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Asset Id
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                    {wo['assetid']}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    WorkOrder Number
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                    {wo['wonumber']}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Type
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                    {wo['type']}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Assignee
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                    {wo['assignee']}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Department
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                    {wo['dept']}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Severity
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                    {wo['severity']}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    WR Number
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                    {wo['wrnumber']}
                  </Typography>
                </Grid>
                {wo['fpStatus'] && <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    FP Status
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                    {wo['fpStatus']}
                  </Typography>
                </Grid>}
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Create Date
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                    {viewDate(wo['createddate'])}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Start Date
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                    {viewDate(wo['startdate'])}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Finish Date
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                    {wo['finishdate'] != null && viewDate(wo['finishdate'])}
                  </Typography>
                </Grid>
                { wo['isWorkshop'] == true &&
                   <Grid item xs={3}>
                   <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Workshop
                   </Typography>
                   <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                     {wo['assetmovement']}
                   </Typography>
                 </Grid>
                }
                {<Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Total Working Hours
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                    {wo['totalworkorderhours']}
                  </Typography>
                </Grid>}
                {wo['type'] == 'Breakdown' && <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Break Down Code
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                    {wo['breakdowncode']}
                  </Typography>
                </Grid>}
                {<Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Affecting Production
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                    {wo['affectingproduction']}
                  </Typography>
                </Grid>}
                {wo['affectingproduction'] == 'Yes' && <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Production Loss Code
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                    {wo['productionlosscode']}
                  </Typography>
                </Grid>}
                {wo['task'] && <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Task
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                    {wo['task']}
                  </Typography>
                </Grid>}
                {wo['state'] && <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    State
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                    {wo['state']}
                  </Typography>
                </Grid>}
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Comments
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                    {wo['comments']}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Notes
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                    {wo['notes']}
                  </Typography>
                </Grid>
                {wo['totalresourcecost'] && <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Total Resource Cost
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                    {wo['totalresourcecost']}
                  </Typography>
                </Grid>}
                {wo['totalsparescost'] && <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Total Spare Cost
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                    {wo['totalsparescost']}
                  </Typography>
                </Grid>}
                {wo['externalcost'] && <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    External Cost
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                    {wo['externalcost']}
                  </Typography>
                </Grid>}
              </Grid>
            </CardContent>
          </Card>
        }
        {
          wo?.approvallogs?.length > 0 && <Typography sx={{ fontSize: 18, fontWeight: "bold", marginTop: 3 }} color="text.secondary" gutterBottom>
            Approval Logs
          </Typography>
        }
        {wo?.approvallogs?.length > 0 &&
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow style={{ textAlign: "left" }}>
                  <TableCell>Level</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Approver</TableCell>
                  <TableCell>Approved Date</TableCell>
                  <TableCell>Remarks</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {wo?.approvallogs.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.level}
                    </TableCell>
                    <TableCell > {row.status}</TableCell>
                    <TableCell > {row.approver}</TableCell>
                    <TableCell >{viewDate(row.approvedate)}
                    </TableCell>
                    <TableCell > {row.remarks}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
        {
          inspectionList.length > 0 && <Typography sx={{ fontSize: 18, fontWeight: "bold", marginTop: 3 }} color="text.secondary" gutterBottom>
            Inspection Details
          </Typography>
        }
        {inspectionList.length > 0 &&
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow style={{ textAlign: "left" }}>
                  <TableCell>Aspect</TableCell>
                  <TableCell>Parameter</TableCell>
                  <TableCell>Threshold Value</TableCell>
                  <TableCell>Measured Value</TableCell>
                  <TableCell>Threshold Beatch (Y/N)</TableCell>
                  <TableCell>Breach Level (Red/Amber)</TableCell>
                  <TableCell colSpan={2}>Remarks</TableCell>
                  <TableCell>Measured by</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inspectionList.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.aspect}
                    </TableCell>
                    <TableCell > {row.parameter}</TableCell>
                    <TableCell > {row.thresholdValue}</TableCell>
                    <TableCell >{row.measuredValue}
                    </TableCell>
                    <TableCell > {row.thresholdBeach}</TableCell>
                    <TableCell >
                      <Button type="button" style={{ backgroundColor: row.beachLevel }}>
                        {row.beachLevel}
                      </Button>
                    </TableCell>
                    <TableCell colSpan={2} style={{ flex: 'd-flex' }} >
                      {row.remarks}
                    </TableCell>

                    <TableCell > {row.department}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
        {
          resourceList.length > 0 && <Typography sx={{ fontSize: 18, fontWeight: "bold", marginTop: 3 }} color="text.secondary" gutterBottom>
            Resource Details
          </Typography>
        }
        {resourceList.length > 0 &&
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow style={{ textAlign: "left" }}>
                  <TableCell>Name</TableCell>
                  <TableCell>Department</TableCell>
                  <TableCell>Per Rate</TableCell>
                  <TableCell>Hours</TableCell>
                  <TableCell>Total Cost</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resourceList.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.resourcename}
                    </TableCell>
                    <TableCell > {row.department}</TableCell>
                    <TableCell > {row.payrate}</TableCell>
                    <TableCell > {row.hours}</TableCell>
                    <TableCell >{row.totalCost}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
        {
          spareList.length > 0 && <Typography sx={{ fontSize: 18, fontWeight: "bold", marginTop: 3 }} color="text.secondary" gutterBottom>
            Spares Details
          </Typography>
        }
        {spareList.length > 0 &&
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow style={{ textAlign: "left" }}>
                  <TableCell>Item Name</TableCell>
                  <TableCell>Item Code</TableCell>
                  <TableCell>Unit Cost</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Total Cost</TableCell>
                  <TableCell>Dispatched</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {spareList.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.itemname}
                    </TableCell>
                    <TableCell > {row.itemcode}</TableCell>
                    <TableCell >{row.unitcost}</TableCell>
                    <TableCell > {row.quantity}</TableCell>
                    <TableCell > {row.unitcost * row.quantity}</TableCell>
                    <TableCell > {row.isDispatched ? 'Dispatched' : 'Not Dispatched'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
        {
          taskList.length > 0 && <Typography sx={{ fontSize: 18, fontWeight: "bold", marginTop: 3 }} color="text.secondary" gutterBottom>
            Task Details
          </Typography>
        }
        {taskList.length > 0 &&
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow style={{ textAlign: "left" }}>
                  <TableCell>Task Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Department</TableCell>
                  <TableCell>Trade</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {taskList.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.taskname}
                    </TableCell>
                    <TableCell > {row.desc}</TableCell>
                    <TableCell >{row.dept}</TableCell>
                    <TableCell > {row.trade}</TableCell>
                    <TableCell > {row.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
        {
          reopenList.length > 0 && <Typography sx={{ fontSize: 18, fontWeight: "bold", marginTop: 3 }} color="text.secondary" gutterBottom>
            Reopen Details
          </Typography>
        }
        {reopenList.length > 0 &&
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow style={{ textAlign: "left" }}>
                  <TableCell>Reopen Date</TableCell>
                  <TableCell>Reopen By</TableCell>
                  <TableCell>Reason</TableCell>
                  <TableCell>Completed Date</TableCell>
                  <TableCell>Completed By</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reopenList.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.date}
                    </TableCell>
                    <TableCell > {row.reason}</TableCell>
                    <TableCell >{row.reopenby}</TableCell>
                    <TableCell > {row.completeddate}</TableCell>
                    <TableCell > {row.completedby}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </Box>
    </Box>
    // <Dialog
    //   open={open}
    //   onClose={handleClose}
    //   aria-labelledby="form-dialog-title"
    //   fullWidth={true}
    //   maxWidth="lg">
    //   <DialogTitle id="form-dialog-title">Workorder Details </DialogTitle>
    //   {/* {formCreator("simple", formFields, wo)} */}
    //   <DialogContent>
    //     <Grid container spacing={4}>
    //       <Grid item xs={3}>
    //         <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
    //           Asset Id
    //         </Typography>
    //         <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
    //           {wo['assetid']}
    //         </Typography>
    //       </Grid>
    //       <Grid item xs={3}>
    //         <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
    //           WorkOrder Number
    //         </Typography>
    //         <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
    //           {wo['wonumber']}
    //         </Typography>
    //       </Grid>
    //       <Grid item xs={3}>
    //         <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
    //           Type
    //         </Typography>
    //         <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
    //           {wo['type']}
    //         </Typography>
    //       </Grid>
    //       <Grid item xs={3}>
    //         <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
    //           Assignee
    //         </Typography>
    //         <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
    //           {wo['assignee']}
    //         </Typography>
    //       </Grid>
    //       <Grid item xs={3}>
    //         <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
    //           Department
    //         </Typography>
    //         <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
    //           {wo['dept']}
    //         </Typography>
    //       </Grid>
    //       <Grid item xs={3}>
    //         <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
    //           Severity
    //         </Typography>
    //         <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
    //           {wo['severity']}
    //         </Typography>
    //       </Grid>
    //       {wo['fpStatus'] && <Grid item xs={3}>
    //         <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
    //           FP Status
    //         </Typography>
    //         <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
    //           {wo['fpStatus']}
    //         </Typography>
    //       </Grid>}
    //       <Grid item xs={3}>
    //         <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
    //           Create Date
    //         </Typography>
    //         <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
    //           {viewDate(wo['createddate'])}
    //         </Typography>
    //       </Grid>
    //       <Grid item xs={3}>
    //         <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
    //           Start Date
    //         </Typography>
    //         <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
    //           {viewDate(wo['startdate'])}
    //         </Typography>
    //       </Grid>
    //       <Grid item xs={3}>
    //         <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
    //           Finish Date
    //         </Typography>
    //         <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
    //           {viewDate(wo['finishdate'])}
    //         </Typography>
    //       </Grid>
    //       {wo['dreakdowncode'] && <Grid item xs={3}>
    //         <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
    //           Break Down Code
    //         </Typography>
    //         <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
    //           {wo['dreakdowncode']}
    //         </Typography>
    //       </Grid>}
    //       {wo['task'] && <Grid item xs={3}>
    //         <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
    //           Task
    //         </Typography>
    //         <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
    //           {wo['task']}
    //         </Typography>
    //       </Grid>}
    //       {wo['state'] && <Grid item xs={3}>
    //         <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
    //           State
    //         </Typography>
    //         <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
    //           {wo['state']}
    //         </Typography>
    //       </Grid>}
    //       <Grid item xs={3}>
    //         <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
    //           Notes
    //         </Typography>
    //         <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
    //           {wo['notes']}
    //         </Typography>
    //       </Grid>
    //     </Grid>
    //     {
    //       inspectionList.length > 0 && <Typography sx={{ fontSize: 18, fontWeight: "bold", marginTop: 3 }} color="text.secondary" gutterBottom>
    //         Inspection Details
    //       </Typography>
    //     }
    //     {inspectionList.length > 0 &&

    //       <TableContainer component={Paper}>
    //         <Table sx={{ minWidth: 650 }} aria-label="simple table">
    //           <TableHead>
    //             <TableRow style={{ textAlign: "left" }}>
    //               <TableCell>Aspect</TableCell>
    //               <TableCell>Parameter</TableCell>
    //               <TableCell>Threshold Value</TableCell>
    //               <TableCell>Measured Value</TableCell>
    //               <TableCell>Threshold Beatch (Y/N)</TableCell>
    //               <TableCell>Breach Level (Red/Amber)</TableCell>
    //               <TableCell colSpan={2}>Remarks</TableCell>
    //               <TableCell>Measured by</TableCell>
    //             </TableRow>
    //           </TableHead>
    //           <TableBody>
    //             {inspectionList.map((row, index) => (
    //               <TableRow key={index}>
    //                 <TableCell component="th" scope="row">
    //                   {row.aspect}
    //                 </TableCell>
    //                 <TableCell > {row.parameter}</TableCell>
    //                 <TableCell > {row.thresholdValue}</TableCell>
    //                 <TableCell >{row.measuredValue}
    //                 </TableCell>
    //                 <TableCell > {row.thresholdBeach}</TableCell>
    //                 <TableCell >
    //                   <Button type="button" style={{ backgroundColor: row.beachLevel }}>
    //                     {row.beachLevel}
    //                   </Button>
    //                 </TableCell>
    //                 <TableCell colSpan={2} style={{ flex: 'd-flex' }} >
    //                   {row.remarks}
    //                 </TableCell>

    //                 <TableCell > {row.department}</TableCell>
    //               </TableRow>
    //             ))}
    //           </TableBody>
    //         </Table>
    //       </TableContainer>
    //     }
    //     <DialogActions>
    //       <Button type="button" onClick={handleClose}>
    //         Close
    //       </Button>
    //     </DialogActions>
    //   </DialogContent>
    // </Dialog>
  );
}
export default ViewWorkOrder;