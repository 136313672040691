import React, { useState, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { myConfig } from "../../settings";
import { workRequestModel } from "../../schema/schema.js";
import { apiService } from '../../features/apiService';
import { useSelector } from 'react-redux';
import { formCreator } from "../../features/helpers/formhelper";

// API End Point
const endPoint = myConfig.updateWREP;
let objindex = 0;
let fakecount = 0;

const UpdateWR = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(props.open.state);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [isSubmitionError, setSubmitionError] = useState(false);
  const [formFields, setFormFields] = useState(workRequestModel)
  const { user } = useSelector((state) => state.auth);
  const initValues = getInitialValues();
  const [initialValues, setInitialValues] = useState(getInitialValues());

  function getInitialValues() {
    let ivalues = {};
    const workrequest = props.open.workrequest;
    formFields.forEach((value) => {
      ivalues[value['field']] = workrequest[value['field']];
    });
    console.log("Initial Values->", ivalues);
    ++fakecount;

    return ivalues;
  }

  // Get Department list
  const [deptList, setDeptList] = useState([]);
  useEffect(() => {
    apiService(myConfig.getDeptEP)
      .then(response => {
        setDeptList(response.data)
        if (response.data.length == 0) {
          console.log("Dept List->", response.data.length);
          toast.error("Department list Empty, Please add department first");
        }
        console.log("Response->", response);
      })
      .catch(error => {
        console.error(error);
      })
  }, []);
  let modDeptList = [];
  for (const deptItem of deptList) {
    modDeptList.push(deptItem.name);
  }

  // Get USer list
  const [userList, setUserList] = useState([]);
  useEffect(() => {
    apiService(myConfig.getUsersEP)
      .then(response => {
        setUserList(response.data)
        if (response.data.length == 0) {
          console.log("User List->", response.data.length);
          toast.error("User list Empty...");
        }
        console.log("Response->", response);
      })
      .catch(error => {
        console.error(error);
      })
  }, []);
  let modUserList = [];
  for (const userItem of userList) {
    modUserList.push(userItem.lastname + ", " + userItem.firstname + ", " + userItem.email);
  }
  const valueDB = { "dept": modDeptList, "assignee": modUserList };

  // Handle States for the dialog box
  function handleClose() {
    console.log(["handleClose: Setting open to false", open, props.open.user]);
    setOpen(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);
    //setFormFields(genFormFields);
    props.open.setfn();
    objindex = 0;
  }

  // Handle retry when submit failed
  function handleRetry() {
    //console.log(["handleClose: Setting open to false", open, props.open.user]);
    //setOpen(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);
    //setFormFields(genFormFields);
    //props.open.setfn();
    //objindex = 0;
  }

  function handleClickOpen() {
    // console.log(["Comp: open->", true])
    setSubmitionCompleted(false);
    setOpen(true);
    objindex++;
  }

  // console.log(["Here open->", props.open.state, open, isSubmitionError]);
  if (props.open.state & !open) {
    setOpen(true);
    setInitialValues(initValues);
    //console.log(["OpenCheck: Setting open to false", open, props.open.user]);
  }

  function handleSelect(key, value) { }


  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {!isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">View/Update WorkRequest </DialogTitle>
            <DialogContent style={{ height: '500px' }}>
              <Formik
                //enableReinitialize 
                key={fakecount}
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {

                  const lworkrequest = props.open.workrequest;
                  let retobj = values;
                  setSubmitting(true);
                  {
                    (() => {
                      for (let key in lworkrequest) {
                        let tobj = {};
                        if (!retobj.hasOwnProperty(key)) {
                          retobj[key] = lworkrequest[key];
                        }
                      }
                    })()
                  };
                  retobj['tenantid'] = user.tenantid;
                  console.log(["Submitted->", retobj]);

                  apiService(endPoint, retobj)
                    .then(resp => {
                      console.log("WorkRequest updated ");
                      setSubmitionCompleted(true);
                    })
                    .catch(error => {
                      console.error(error);
                      setSubmitionError(true);
                      setSubmitionCompleted(true);
                    })
                }}
                // Need to expand the validation schema more later
                validationSchema={Yup.object().shape({})}
              >
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    resetForm
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <React.Fragment>
                        <Box height={425} >
                          {formCreator("Complex", 'edit', formFields, initialValues, values, handleChange, handleBlur, touched, errors, valueDB, handleSelect, true)}
                        </Box>
                      </React.Fragment>
                      <React.Fragment>
                        <DialogActions>

                          <Button type="button"  variant="contained" color="primary" onClick={handleClose}>
                            Cancel
                          </Button>
                          <Button type="button" variant="contained" color="primary"
                            onClick={() => resetForm({ values: setInitialValues(initValues) })}
                            disabled={!dirty || isSubmitting}
                          >
                            Reset
                          </Button>
                          <Button type="submit" variant="contained" color="primary" disabled={!dirty || isSubmitting}>
                            Submit
                          </Button>
                        </DialogActions>
                      </React.Fragment>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Work Request updated</DialogTitle>
            <DialogContent>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Back
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionError && (
          <React.Fragment
          >
            <DialogTitle id="form-dialog-title">Sorry! Work Request Update Failed</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Try Again Later</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleRetry}>
                  Back
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  );
}

export default UpdateWR;