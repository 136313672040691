import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useState } from "react";
import { useSelector } from "react-redux";
import React from "react";
import { apiService2 } from "../../features/apiService";
import { myConfig } from "../../settings";
import { Formik } from "formik";
import * as Yup from "yup";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

let fakecount = 0;

const AddItems = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(props.open.state);
    const [initialValues, setInitialValues] = useState({});
    const { user } = useSelector((state) => state.auth);
    const [itemList, setItemList] = useState([]);
    const [itemName, setItemName] = useState('');
    const [itemCost, setItemCost] = useState('');
    const [isEdit, setEdit] = useState(false);
    const [index, setIndex] = useState(-1);

    if (props.open.state & !open) {
        const contractor = props.open.contractor;
        setOpen(true);
        if(contractor.items){
            console.log(contractor.items);
            setItemList(contractor.items);
        }
    }

    function handleClose() {
        setOpen(false);
        props.open.setfn();
    }

    function handleEdit(index){
        var item = itemList[index];
        setItemName(item.itemName);
        setItemCost(item.itemCost);
        setEdit(true);
        setIndex(index)
    }

    function handleDelete(index){
        console.log('ssss');
        var item = itemList;
        item.splice(index, 1);
        setItemList([...item]);
    }

    function handleSave(){
        const contractor = props.open.contractor;
        console.log(contractor);
        let details = {_id: contractor._id, items: itemList}
        apiService2('POST', myConfig.updateItemContractor,details )
        .then(resp => {
            console.log(resp);
           handleClose();
        })
        .catch(error => {
            console.log(["Contractor Add Failed->", error]);

        });
    }

    return (<React.Fragment>
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={'xs'}
            onClose={handleClose}
            aria-labelledby="form-dialog-title">
            <React.Fragment>
                <DialogTitle id="form-dialog-title">Add Items</DialogTitle>
                <DialogContent>
                    <DialogContentText variant="h6"></DialogContentText>
                    <Formik
                        key={fakecount}
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={Yup.object().shape({

                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            if (isEdit) {
                                let item = itemList;
                                item[index].itemName = itemName;
                                item[index].itemCost = itemCost;
                                setItemList([...item]);
                                setEdit(false);
                                setIndex(-1);
                            } else {
                                setItemList([...itemList, { itemName: itemName, itemCost: itemCost }]);
                            }
                            setItemName('');
                            setItemCost('');
                        }}>
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                dirty,
                                isSubmitting,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                handleReset,
                                resetForm
                            } = props;
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Box >
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    id="outlined-basic"
                                                    error={errors.name && touched.name}
                                                    label={'Item Name'}
                                                    name={'itemName'}
                                                    value={itemName}
                                                    onChange={(e) => setItemName(e.target.value)}
                                                    onBlur={handleBlur}
                                                    helperText=""
                                                    margin="normal"
                                                    type="text"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    id="outlined-basic"
                                                    error={errors.name && touched.name}
                                                    label={'Cost'}
                                                    name={'cost'}
                                                    value={itemCost}
                                                    onChange={(e) => setItemCost(e.target.value)}
                                                    onBlur={handleBlur}
                                                    helperText=""
                                                    margin="normal"
                                                    type="text"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <DialogActions>
                                        <Button type="button" variant="contained" color="primary"
                                            onClick={() => resetForm()}
                                            disabled={!dirty || isSubmitting}>
                                            Reset
                                        </Button>
                                        <Button type="submit" variant="contained" color="primary">
                                            Submit
                                        </Button>
                                    </DialogActions>
                                </form>
                            );
                        }}
                    </Formik>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 300 }} aria-label="simple table">
                            <TableHead>
                                <TableRow style={{ textAlign: "left" }}>
                                    <TableCell>Item Name</TableCell>
                                    <TableCell>Cost</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {itemList.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            {row.itemName}
                                        </TableCell>
                                        <TableCell > {row.itemCost}</TableCell>
                                        <TableCell>
                                            <IconButton aria-label="edit" color="secondary" onClick={() =>handleEdit(index)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton aria-label="delete" color="secondary" onClick={() => handleDelete(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <DialogActions>
                        <Button type="button" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button type="submit" onClick={handleSave}>
                            Submit
                        </Button>
                    </DialogActions>
                </DialogContent>
            </React.Fragment>
        </Dialog>
    </React.Fragment>)
}

export default AddItems;