import { Box, Typography, useTheme, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { GridToolbarContainer } from '@mui/x-data-grid';
// import { useNavigate } from 'react-router-dom';
import AddUser from "./adduser";
import EditUser from "./edituser";
import { useFormik } from "formik";
import {
  withStyles,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
//import axios from "axios";
//import { Formik, Form, Field, ErrorMessage } from "formik";
//import * as Yup from "yup";
import { myConfig } from "../../settings.js";
import  { apiService } from '../../features/apiService';
import { useSelector } from 'react-redux';
import  { ToastContainer, toast } from 'react-toastify';

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [open, setOpen] = useState(false);
  const [teamlist, setTeam] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [taskopen, setTaskOpen] = useState(false);
  const [selectedUser, setUser] = useState({});
  const { user } = useSelector((state) => state.auth);
  const [selectedrow, setRow] = useState(-1);
  useEffect(() => {
    apiService(myConfig.getUsersEP)
      .then ( resp => {
        setTeam(resp.data);
      })
      .catch (error => {
        console.error(error);
      }) 
  }, [refresh]);

  function handleClose() {
    //console.log("Setting open to false");
    //console.log(["show=>", show]);
    setOpen(false);
    setRefresh(true);
    setTaskOpen(false);
  }

  function handleClickOpen() {
    //console.log("Setting open to true");
    setOpen(true);
   // setRefresh(false);
  }
  function handleEditUser() {
    console.log(["Selected User->", selectedUser]);
    if (selectedrow != -1) {setTaskOpen(true); } 
    else { toast.warning("Use CheckBox to Select the User First")}
  }
 function CustomToolbar() {
  return (
    <GridToolbarContainer className="mb-1"> 
      <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleClickOpen}>
        Add
      </Button>
      <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={handleEditUser}>
        Edit
      </Button> 
      <Button variant="contained" color="primary" startIcon={<DeleteIcon />}>
        Delete
      </Button> 
    </GridToolbarContainer>
  );
}

  const columns = [
    {
      field: "firstname",
      headerName: "FirstName",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "lastname",
      headerName: "LastName",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "dept",
      headerName: "Dept",
      flex: 1,
    },
    {
      field: "access",
      headerName: "Access Level",
      flex: 1,
      renderCell: ({ row: { access } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              access === "admin"
                ? colors.greenAccent[600]
                : access === "manager"
                ? colors.greenAccent[700]
                : colors.greenAccent[700]
            }
            borderRadius="4px"
          >
            {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {access === "manager" && <SecurityOutlinedIcon />}
            {access === "user" && <LockOpenOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {access}
            </Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Header title="Team" subtitle="Managing the Team Members" />
      <Box
        m="40px 0 0 0"
         height="70vh">
        <DataGrid checkboxSelection 
          rows={teamlist} 
          columns={columns} 
          components={{ Toolbar: CustomToolbar }}
          getRowId ={(row) => row._id}   
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const myArr = Array.from(selectedIDs);
            if (myArr.length >0) { 
              console.log(["User Selected: Row->",myArr]);
              teamlist.map((user) => {
                if (user._id == myArr[0]) {
                  setUser(user);
                  setRow(myArr[0]);
                }
              })
            }
          }}
          />
        <AddUser open={{state: open, setfn: handleClose}} />
        <EditUser open={{state: taskopen, setfn: handleClose, user: selectedUser}} />
      </Box>
    </Box>
    
  );
};

export default Team;
