import AWS from 'aws-sdk';
import * as S3 from 'aws-sdk/clients/s3';

const S3_BUCKET = 'alignererp161314-dev';
const REGION = 'ap-south-1';

export const uploadFile = async (file) => {

    // console.log('S3_BUCKET->', S3_BUCKET);
    // AWS.config.update({
    //     accessKeyId: 'AKIAU5GV5HY35PEFP3MS',
    //     secretAccessKey: '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',
    // });

    // const s3 = new AWS.S3({
    //     params: { Bucket: S3_BUCKET },
    //     region: REGION,
    // });

    // const params = {
    //     Bucket: S3_BUCKET,
    //     Key: file.name,
    //     Body: file,
    // };

    // var upload = s3
    //     .putObject(params)
    //     .on("httpUploadProgress", (evt) => {
    //         console.log(
    //             "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
    //         );
    //     })
    //     .promise();

    // await upload.then((err, data) => {
    //     console.log('err', err);
    //     console.log('data->', data);
    //     console.log(data.response);
    // });
    return await new Promise((resolve, reject) => {
        const contentType = file.type;
        const bucket = new S3(
            {
                accessKeyId: 'AKIAU5GV5HY35PEFP3MS',
                secretAccessKey: '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',
                region: 'ap-south-1'
            }
        );
        const params = {
            Bucket: 'alignererp161314-dev',
            Key: 'assets' + file.name,
            Body: file,
            ACL: 'public-read',
            ContentType: contentType
        };
        bucket.upload(params, function (err, data) {
            if (err) {
                reject(err);
                console.log('There was an error uploading your file: ', err);

            } else {
                resolve(data);
                console.log('Successfully uploaded file.', data);
                return data;
            }
        });
    });

};