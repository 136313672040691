import React, { useState, useEffect } from "react";
import { Grid, IconButton, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import axios from "axios";
import {
    Button,
    withStyles,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { myConfig } from "../../settings";
import { useSelector } from 'react-redux';
import { procurementModel } from "../../schema/schema";
import { apiService, apiService2 } from "../../features/apiService";
import { formCreator } from "../../features/helpers/formhelper";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

let fakecount = 0;
const ReceiveProduct = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(props.open.state);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [isSubmitionError, setSubmitionError] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const [errorMessage, setErrorMessage] = useState('');
    const [formFields, setFormFields] = useState(procurementModel);
    const [initialValues, setInitialValues] = useState({});
    const [itemList, setItemList] = useState([]);
    const [receiptNote, setReceiptNote] = useState('');
    const [isVerified, setIsVerified] = useState(false);
    const [goodsReceiptNote, setGoodsReceiptNote] = useState('');

    if (props.open.state & !open) {
        setOpen(true);
        const procurement = props.open.procurement;
        if (procurement.itemlist && procurement.itemlist.length > 0) {
            let data = [];
            for (const item of procurement.itemlist) {
                data.push({
                    itemname: item.itemname,
                    itemcode: item.itemcode,
                    quantity: item.quantity,
                    receivedQuantity: 0,
                })
            }
            setItemList(data);
        }

    }

    function handleSetItem() {
        const procurement = props.open.procurement;
        if (procurement.itemlist && procurement.itemlist.length > 0) {
            let data = [];
            for (const item of procurement.itemlist) {
                data.push({
                    itemname: item.itemname,
                    itemcode: item.itemcode,
                    quantity: item.quantity,
                    receivedQuantity: 0,
                })
            }
            setItemList(data);
        }
    }

    function handleClose() {
        setOpen(false);
        setSubmitionError(false);
        setSubmitionCompleted(false);
        props.open.setfn();
    }

    function handleTryAgain() {
        setSubmitionError(false);
        setErrorMessage('');
    }

    function handleVerify() {
        var i = 0;
        console.log(itemList);
        itemList.forEach((item) => {
            if (item.receivedQuantity != item.quantity) {
                i++;
            }
        })
        if (i == 0) {
            setIsVerified(true);
        } else {
            setIsVerified(false);
        }
    }

    return (
        <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'md'}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                {!isSubmitionCompleted && !isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Receive Product </DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6"></DialogContentText>
                            <Formik
                                key={fakecount}
                                enableReinitialize
                                initialValues={initialValues}
                                validationSchema={Yup.object().shape({
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(true);
                                    values['_id'] = props.open.procurement._id;
                                    values['itemlist'] = itemList;
                                    console.log(itemList);
                                    values['goodsReceiptNote'] = goodsReceiptNote;
                                    apiService2('POST', myConfig.receivedProcurementEP, values)
                                        .then(resp => {
                                            console.log(resp);
                                            if (resp.data.message) {
                                                setErrorMessage(resp.data.message);
                                                setSubmitionError(true);
                                            } else {
                                                setSubmitionCompleted(true);
                                            }
                                        })
                                        .catch(error => {
                                            console.log(["Procurement Add Failed->", error]);
                                            setSubmitionError(true);
                                            setSubmitionCompleted(true);
                                        });
                                }}
                            >
                                {props => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        dirty,
                                        isSubmitting,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        handleReset,
                                        resetForm,
                                        setFieldValue
                                    } = props;
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <Grid container spacing={2}>
                                                <Grid item sm="12">
                                                    <TextField
                                                        id="outlined-basic"
                                                        label={'Goods Receipt Note'}
                                                        name={'goodsReceiptNote'}
                                                        value={goodsReceiptNote}
                                                        onChange={(e) => {
                                                            setGoodsReceiptNote(e.target.value);
                                                        }}
                                                        onBlur={handleBlur}
                                                        helperText={errors.name && "Incorrect entry."}
                                                        margin="normal"
                                                        type="text"
                                                        sx={{ display: 'flex', width: "100%" }} />
                                                </Grid>
                                            </Grid>
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 400 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow style={{ textAlign: "left" }}>
                                                            <TableCell>Item Name</TableCell>
                                                            <TableCell>Order Quantity</TableCell>
                                                            <TableCell>Received Quantity</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {itemList.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell component="th" scope="row">
                                                                    {row.itemname}
                                                                </TableCell>
                                                                <TableCell > {row.quantity}</TableCell>
                                                                <TableCell >
                                                                    <TextField
                                                                        id="receivedQuantity"
                                                                        name="receivedQuantity"
                                                                        type="number"
                                                                        value={row.receivedQuantity}
                                                                        onChange={(e) => {
                                                                            if (e.target.value <= row.quantity) {
                                                                                let data = [...itemList];
                                                                                data[index]['receivedQuantity'] = e.target.value;
                                                                                data[index]['totalamount'] = e.target.value * row.unitprice;
                                                                                setItemList(data);
                                                                            }
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        error={errors.receivedQuantity && touched.receivedQuantity}
                                                                        helperText={(errors.receivedQuantity && touched.receivedQuantity) && errors.receivedQuantity}
                                                                        variant="outlined"

                                                                        required
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                            <DialogActions>
                                                <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                                                    Cancel
                                                </Button>
                                                <Button  type="button" variant="contained" color="primary"
                                                    onClick={() => {
                                                        setGoodsReceiptNote('')
                                                        handleSetItem()
                                                    }}
                                                    disabled={isSubmitting}>
                                                    Reset
                                                </Button>
                                                {
                                                    !isVerified && <Button type="button" variant="contained" color="primary" onClick={handleVerify}>
                                                        Verify
                                                    </Button>
                                                }
                                                {
                                                    isVerified && <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                                                        Submit
                                                    </Button>
                                                }
                                            </DialogActions>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionCompleted && !isSubmitionError && (
                    <React.Fragment>
                        {/* <DialogTitle id="form-dialog-title"> Updated Store Product</DialogTitle> */}
                        <DialogContent>
                            <DialogContentText variant="h6">Updated Store Product Successfully</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Sorry! Add Procurement Failed</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6"> {errorMessage ? errorMessage : 'Try Again Later'}</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleTryAgain}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
            </Dialog>
    );
}


export default ReceiveProduct;