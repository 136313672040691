import {
    Autocomplete,
    Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    IconButton, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, TextField, useTheme
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { tokens } from "../../theme";
import { apiService } from "../../features/apiService";
import { myConfig } from "../../settings";
import { useSelector } from "react-redux";
import { getAccess } from "../../features/helpers/authhelper";
import { TIMEZONE } from "../../data/timezone";
// import AddConfiguration from "./addconfiguration";

const Configuration = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user } = useSelector((state) => state.auth);
    const [configList, setConfigList] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [open, setOpen] = useState(false);
    const [aopen, setAddOpen] = useState(false);
    const [config, setConfig] = useState({});
    const [lable, setLable] = useState('');
    const [value, setValue] = useState('');
    const [enumlist, setEnumlist] = useState([]);
    const access = getAccess(user, 'setup');
    const [selectedrow, setRow] = useState(-1);
    const [rowSelection, setRowSelection] = useState({});
    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    useEffect(() => {
        apiService(myConfig.getConfiguration)
            .then(response => {
                console.log(response.data);
                setConfigList(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, [refresh]);

    function handleEdit(index) {
        return () => {
            setOpen(true);
            setLable(configList[index].name);
            setConfig(configList[index]);
            if (configList[index].field == 'select') {
                setEnumlist(configList[index].enumValues)
            } else if (configList[index].field == 'autocomplete') {
                var zone = TIMEZONE.map((item) => item.zone);
                console.log(zone);
                setEnumlist(zone)
            }
        }
    }

    function handleClose() {
        setOpen(false);
        setAddOpen(false);
        setRefresh(!refresh);
    }

    function handleChange(event) {
        console.log('event.target.value', event.target.value);
        setConfig({ ...config, value: event.target.value });
    }

    function handleUpdate() {
        console.log('config',config);
        apiService(myConfig.updateConfiguration, config)
            .then(response => {
                console.log(response.data);
                handleClose();
            })
            .catch(error => {
                console.error(error);
            });
    }

    function handleClickOpen() {
        setAddOpen(true);
        setRefresh(false);
    }

    function handleClickEdit() {
        if (selectedrow >= 0) {
            setOpenEdit(true);
            setRefresh(false);
        } else toast.warning("Select a configuration item first ");
    }

    function handleClickDelete() {
        if (selectedrow >= 1) {
            setOpenDelete(true);
            setRefresh(false);
        } else toast.warning("Select an assetfamily first ");
    }

    // Top Tool bar for Add/Edit
    function CustomToolbar() {
        return (
            <Box className="mb-1">
                {
                    access?.isCreate && <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleClickOpen}>
                        Add
                    </Button>
                }
            </Box>
        );
    }
    return (

        <Box m="20px">
            {CustomToolbar()}
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Fields</TableCell>
                            <TableCell>Value</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {configList.map((row, i) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.value}
                                </TableCell>
                                {
                                    access.isEdit && <TableCell component="th" scope="row" >
                                        <IconButton aria-label="delete" onClick={handleEdit(i)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton aria-label="delete" onClick={handleEdit(i)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'xs'}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                <React.Fragment>
                    <DialogTitle id="form-dialog-title">Update Configuration</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant="h6">
                            {config.field == 'select' &&
                                <TextField
                                    id="outlined-basic"
                                    label={lable}
                                    name={lable}
                                    value={config.value}
                                    onChange={handleChange}
                                    margin="normal"
                                    type="text"
                                    select
                                    defaultValue={''}
                                    sx={{ display: 'flex', width: "100%" }}>
                                    {enumlist.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            } {
                                config.field == 'autocomplete' &&
                                <Autocomplete
                                   
                                    id="combo-box-demo"
                                    options={enumlist}
                                    sx={{ width: 300 }}
                                    onChange={(event, value) =>  setConfig({ ...config, value:value })} // prints the selected value
                                    renderInput={(params) => <TextField id="outlined-basic"
                                        label={lable}
                                        {...params}
                                        fullWidth
                                    />}
                                />
                            }
                            {config.field == 'input' &&
                                <TextField
                                    id="outlined-basic"
                                    label={lable}
                                    name={lable}
                                    value={config.value}
                                    onChange={handleChange}
                                    margin="normal"
                                    type="text"
                                    defaultValue={''}
                                    sx={{ display: 'flex', width: "100%" }}>

                                </TextField>
                            }
                        </DialogContentText>
                        <DialogActions sx={{
                           
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}>
                            <Button type="button" variant="contained" color="secondary" onClick={handleUpdate}>
                                Update
                            </Button>
                            <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </React.Fragment>
            </Dialog>

        </Box>)
}

export default Configuration;

{/* <AddConfiguration open={{ state: aopen, setfn: handleClose }} /> */ }