import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Grid
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { myConfig } from "../../settings";
import { maintenanceLibModel } from "../../schema/schema.js";
import { apiService } from '../../features/apiService';
import { formCreator } from "../../features/helpers/formhelper";

// API End Point
const endPoint = myConfig.updateMainLibEP;
let objindex = 0;
let fakecount = 0;

const EditMaintenanceLib = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(props.open.state);

  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [isSubmitionCompleted2, setSubmitionCompleted2] = useState(false);
  const [isSubmitionError, setSubmitionError] = useState(false);
  const [formFields, setFormFields] = useState(maintenanceLibModel);
  const [status, setStatus] = useState('');
  const { user } = useSelector((state) => state.auth);
  const initValues = getInitialValues();
  const [initialValues, setInitialValues] = useState(initValues);

  function getInitialValues() {
    let ivalues = {};
    const maintlib = props.open.selectedlib;
    formFields.forEach((value) => {
      ivalues[value['field']] = maintlib[value['field']];
    });
    console.log("Initial Values->", ivalues);
    ++fakecount;
    return ivalues;
  }

  // Get Department list
  const [deptList, setDeptList] = useState([]);
  useEffect(() => {
    apiService(myConfig.getDeptEP)
      .then(response => {
        setDeptList(response.data)
        if (response.data.length == 0) {
          //console.log("Dept List->", response.data.length);
          toast.error("Department list Empty, Please add department first");
        }
        //console.log("Response->", response);
      })
      .catch(error => {
        console.error(error);
      })
  }, []);
  let modDeptList = [];
  for (const deptItem of deptList) {
    modDeptList.push(deptItem.name);
  }
  const valueDB = { "dept": modDeptList};

  // Handle States for the dialog box
  function handleClose() {
    console.log(["handleClose: Setting open to false", open, props.open.user]);
    setOpen(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);

    //setFormFields(genFormFields);
    props.open.setfn(status);
    objindex = 0;
  }

  // Handle retry when submit failed
  function handleRetry() {
    //console.log(["handleClose: Setting open to false", open, props.open.user]);
    setOpen(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);
    //setFormFields(genFormFields);
    props.open.setfn();
    //objindex = 0;
  }

  function handleClickOpen() {
    // console.log(["Comp: open->", true])
    setSubmitionCompleted(false);
    setOpen(true);
    setInitialValues(getInitialValues());
    objindex++;
  }

  // console.log(["Here open->", props.open.state, open, isSubmitionError]);
  if (props.open.state & !open) {
    setOpen(true);
    setInitialValues(getInitialValues());
    //console.log(["OpenCheck: Setting open to false", open, props.open.user]);
  }
  function handleSelect(key, value) { }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'xs'}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",  // Set your width here
            },
          },
        }}
      >
        {!isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Update Maintenance Library </DialogTitle>
            <DialogContent style={{height:'300px'}}>
              <DialogContentText variant="h6"></DialogContentText>
              <Formik
                //enableReinitialize 
                key={fakecount}
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                  const lmaintlib = props.open.selectedlib;
                  const retobj = values;
                  retobj._id = lmaintlib._id;
                  setSubmitting(true);
                  console.log(["Submitted->", retobj]);
                  if (retobj['state'] === "SUSPENDED" && (retobj.suspensionreason === "" || !retobj.suspensionreason)) {
                    toast.warning("Suspension reason is needed, pls update & try again");
                    setSubmitting(false);
                    return;
                  }

                  if (lmaintlib.state === "SUSPENDED" && retobj.state === "ACTIVE") {
                    retobj.suspendedto = new Date();
                  }
                  if (lmaintlib.state === "ACTIVE" && retobj.state === "SUSPENDED") {
                    retobj.suspendedto = "";
                  }

                  apiService(endPoint, retobj)
                    .then(resp => {
                      console.log("Maintenance Lib updated ",resp);
                      setSubmitionCompleted(true);
                    })
                    .catch(error => {
                      console.error(error);
                      setSubmitionError(true);
                      setSubmitionCompleted(true);
                    })
                }}
                // Need to expand the validation schema more later
                validationSchema={Yup.object().shape({})}
              >
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    resetForm,
                    setFieldValue
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box height={225} >
                        <Grid container spacing={2}>
                          {formCreator("Complex",'edit', formFields, initialValues, values, handleChange, handleBlur, touched, errors, valueDB, handleSelect, false, setFieldValue)}
                        </Grid>
                      </Box>
                      <DialogActions>
                        <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                          Cancel
                        </Button>
                        <Button type="button" variant="contained" color="primary"
                          onClick={() => resetForm({ values: setInitialValues(initValues) })}
                          disabled={!dirty || isSubmitting}
                        >
                          Reset
                        </Button>
                        <Button type="submit" variant="contained" color="primary" disabled={!dirty || isSubmitting}>
                          Submit
                        </Button>
                      </DialogActions>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionCompleted && !isSubmitionError && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Thanks! Maintenance Lib updated</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Thanks</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Back
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionError && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Sorry! Maintenance Lib Update Failed</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Check your entries..</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleRetry}>
                  Back
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  );
}

export default EditMaintenanceLib;