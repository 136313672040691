import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  InputLabel,
  Typography,
  MenuItem,
  Box,
  Grid,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { myConfig } from "../../settings";
import { maintenanceLibModel } from "../../schema/schema.js";
import { formCreator } from "../../features/helpers/formhelper";
import { apiService } from '../../features/apiService.js';

// API End Point
const endPoint = myConfig.addMainLibEP;
const getDeptEP = myConfig.getDeptEP;
let fakecount = 0
const AddMaintenanceLib = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(props.open.state);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [isSubmitionError, setSubmitionError] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [deptList, setDeptList] = useState([]);
  const [formFields, setFormFields] = useState(maintenanceLibModel);
  const { user } = useSelector((state) => state.auth);
  //let initialValues = {};
  let modDeptList = [];

  function getInitialValues() {
    let initialValues = {};
    for (const field of formFields) {
      initialValues[field.field] = '';
    }
    setInitialValues(initialValues);
    fakecount++;
    return initialValues;
  }
  // console.log(["Here open->", props.open.state, open, isSubmitionError]);
  if (props.open.state & !open) {
    setOpen(true);
    getInitialValues();
  }

  useEffect(() => {
    console.log("Get Deptartments...");
    apiService(getDeptEP)
      .then(response => {
        setDeptList(response.data)
        if (response.data.length == 0) {
          console.log("Dept List->", response.data.length);
          toast.error("Department list Empty, Please add department first");
        }
        console.log("Response->", response);
      })
      .catch(error => {
        console.error(error);
      })
  }, []);

  for (const deptItem of deptList) {
    modDeptList.push(deptItem.name);
  }
  const valueDB = { "dept": modDeptList };
  console.log("Inside:valueDB->", valueDB);

  // Handle States for the dialog box
  function handleClose() {
    // console.log("handleClose: Setting open to false");
    setOpen(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);
    props.open.setfn();
  }

  function handleClickOpen() {
    // console.log(["Comp: open->", true])
    setSubmitionCompleted(false);
    setOpen(true);
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {!isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Add Maintenance Library </DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6"></DialogContentText>
              <Formik
                key={fakecount}
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);

                  apiService(endPoint, values)
                    .then(resp => {
                      setSubmitionCompleted(true);
                      setSubmitting(false);
                    })
                    .catch(error => {
                      console.log(["Axios Sub - errored->", error]);
                      setSubmitionError(true);
                      toast.error(error.response.data.message);
                      setSubmitting(false);

                    });
                }}
                // Need to expand the validation schema more later
                validationSchema={Yup.object().shape({
                  code: Yup.string().required("Miantainance Code is required"),
                  name: Yup.string().required("Name is required"),
                  dept: Yup.string().required("Department is required"),
                  desc: Yup.string().required("Description is required"),
                  state: Yup.string().required("State is required"),
                })}
              >
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    isSubmitionError,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                    resetForm,
                    setFieldValue
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        {formCreator("Complex", 'add', maintenanceLibModel, initialValues, values, handleChange, handleBlur, touched, errors, valueDB, null, false, setFieldValue, 6)}
                      </Grid>
                      <DialogActions>
                        <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                          Cancel
                        </Button>
                        <Button type="button" variant="contained" color="primary"
                          onClick={() => resetForm({ values: setInitialValues(getInitialValues()) })}
                          disabled={!dirty || isSubmitting}
                        >
                          Reset
                        </Button>
                        <Button type="submit" variant="contained" color="primary" disabled={!dirty || isSubmitting}>
                          Submit
                        </Button>
                      </DialogActions>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Thanks! Maintenance Library Added</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Thanks</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionError && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Sorry! Add Maintenance Library Item Failed</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Check the entries...</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  );
}

export default AddMaintenanceLib;