import React, { useState, useEffect } from "react";
import { Grid, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import axios from "axios";
import {
  Button,
  withStyles,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { myConfig } from "../../settings";
import { useSelector } from 'react-redux';
import { apiService, apiService2 } from '../../features/apiService';
import { breakdowncodeModel } from "../../schema/schema";
import { formCreator } from "../../features/helpers/formhelper";
// API End Point

let fakecount = 0;

const EditBreakBownCode = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(props.open.state);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [isSubmitionError, setSubmitionError] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [initialValues, setInitialValues] = useState({});
  const [formFields, setFormFields] = useState(breakdowncodeModel);

  function getInitialValues() {
    let ivalues = {};
    const BreakBownCode = props.open.BreakBownCode;
    for (const item of breakdowncodeModel) {
      ivalues[item.field] = BreakBownCode[item.field];
    }
    fakecount++;
    console.log('sfhste stjswtjytd', ivalues);
    setInitialValues({ ...ivalues })
  }


  // Handle States for the dialog box
  function handleClose() {
    setOpen(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);
    props.open.setfn();
  }


  if (props.open.state & !open) {
    setOpen(true);

    getInitialValues();
  }

  function handleSelect() {

  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'xs'}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        {!isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Edit BD & PL Code</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6"></DialogContentText>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  const BreakBownCode = props.open.BreakBownCode;
                  values['_id'] = BreakBownCode._id;
                  apiService2('POST', myConfig.updateBreakBownCodeEP, values)
                    .then(resp => {
                      setSubmitionCompleted(true);
                    })
                    .catch(error => {
                      setSubmitionError(true);
                      setSubmitionCompleted(true);
                    });
                }}
                // Need to expand the validation schema more later
                validationSchema={Yup.object().shape({
                  code: Yup.string().required("Required"),
                  type: Yup.string().required("Required")
                })}
              >
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                    resetForm
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      {formCreator("Complex", 'add', formFields, initialValues, values, handleChange, handleBlur, touched, errors, {}, handleSelect)}
                      <DialogActions>
                        <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                          Cancel
                        </Button>
                        <Button
                          type="button"
                          variant="contained" color="primary"
                          onClick={() => resetForm()}
                          disabled={!dirty || isSubmitting}>
                          Reset
                        </Button>
                        <Button type="submit" variant="contained" color="primary" disabled={!dirty || isSubmitting} className="actionbutton">
                          Submit
                        </Button>
                      </DialogActions>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Thanks! BD & PL Updated</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Thanks</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionError && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Sorry! Updated BD & PL Failed</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Try Again Later</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  );
}

export default EditBreakBownCode;