import React, { useState, useEffect } from "react";
import { FormHelperText, Grid, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import axios from "axios";
import {
  Button,
  withStyles,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { myConfig } from "../../../settings";
import { deptModel } from "../../../schema/schema.js";
import { useSelector } from 'react-redux';
import { apiService } from '../../../features/apiService';
// API End Point
const endPoint = myConfig.addDeptEP;
let fakecount = 0;
const AddDept = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(props.open.state);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [isSubmitionError, setSubmitionError] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [errorMessage, setErrorMessage] = useState('');
  const [initialValues, setInitialValues] = useState({});

  function getInitialValues() {
    let ivalues = {};
    for (const item of deptModel) {
        ivalues[item.field] = '';
    }
    fakecount++;
    setInitialValues({ ...ivalues })
}
  // Handle States for the dialog box
  function handleClose() {
    setOpen(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);
    props.open.setfn();
  }


  if (props.open.state & !open) {
    setOpen(true);
    getInitialValues();
  }
  function handleTryAgain() {
    setSubmitionError(false);
    setErrorMessage('');
}

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'xs'}
 
        aria-labelledby="form-dialog-title">
        {!isSubmitionCompleted && !isSubmitionError && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Add Department</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6"></DialogContentText>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  apiService(endPoint, values)
                    .then(resp => {
                      if (resp.data.message) {
                        setErrorMessage(resp.data.message);
                        setSubmitionError(true);
                      } else {
                        setSubmitionCompleted(true);
                      }
                    })
                    .catch(error => {
                      setSubmitionError(true);
                      setSubmitionCompleted(true);
                    });
                }}
                // Need to expand the validation schema more later
                validationSchema={Yup.object().shape({
                  name: Yup.string().required("Department Name Required"),
                  costcentercode: Yup.string().required("Cost Center Code Required"),
                })}
              >
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                    resetForm
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={1}>
                        {(() => {
                          let result = [];
                          for (const item of deptModel.filter(item => !item.skip)) {
                            var key = item.field;
                            var label = item.required ? item.label + '*' : item.label;
                            result.push(
                              <Grid item xs={12}>
                                <TextField
                                  id="outlined-basic"
                                  error={ errors[key]}
                                  label={label}
                                  name={key}
                                  value={values[key]}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  style={{ width: "100%" }}
                                  helperText=""
                                  margin="normal"
                                  type="text"
                                />
                               <FormHelperText style={{color: 'red', fontSize: '15px'}}>{errors[key] && touched[key] ? errors[key] : ""}</FormHelperText>
                              </Grid>
                            )
                          }
                          return result;
                        })()}
                      </Grid>
                      <DialogActions>
                        <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                          Cancel
                        </Button>
                        <Button type="button" variant="contained" color="primary"
                          onClick={() => resetForm()}
                          disabled={!dirty || isSubmitting}>
                          Reset
                        </Button>
                        <Button type="submit" variant="contained" color="primary" disabled={!dirty || isSubmitting}>
                          Submit
                        </Button>
                      </DialogActions>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Thanks! Department Added</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Thanks</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionError && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Sorry! Add Department Failed</DialogTitle>
            <DialogContent>
            <DialogContentText variant="h6"> {errorMessage ? errorMessage : 'Try Again Later'}</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleTryAgain}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  );
}

export default AddDept;