import React, { useState, useEffect } from "react";
import { Grid, MenuItem, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import axios from "axios";
import {
    Button,
    withStyles,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { myConfig } from "../../settings";
import { useSelector } from 'react-redux';
import { resourceModel } from "../../schema/schema";
import { apiService, apiService2 } from "../../features/apiService";
import { toast } from "react-toastify";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { formCreator } from "../../features/helpers/formhelper";


const EditResource = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(props.open.state);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [isSubmitionError, setSubmitionError] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const [deptList, setDeptList] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const [contractorList, setContractorList] = useState([]);
    const [accessList, setAccessList] = useState([]);
    const [facilityList, setFacilityList] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [formFields, setFormFields] = useState(resourceModel);

    function getInitialValues() {
        let ivalues = {};
        const resource = props.open.resource;
        for (const item of resourceModel) {
            if (item.field == 'dob') {
                var date = new Date(resource[item.field]);
                let from = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
                ivalues[item.field] = dayjs(from)
            } else if(item.multiple) {
                if(resource[item.field] != null){
                    ivalues[item.field] = resource[item.field]
                } else {
                    ivalues[item.field] = []
                }
            } else {
                ivalues[item.field] = resource[item.field]
            }
            console.log(ivalues);
        }
        setInitialValues({ ...ivalues })
    }

    useEffect(() => {
        apiService(myConfig.getDeptEP)
            .then(response => {
                setDeptList(response.data)
                if (response.data.length == 0) {
                    toast.error("Department list Empty, Please add department first");
                }
            })
            .catch(error => {
                console.error(error);
            })
    }, []);


    let modDeptList = [];
    for (const deptItem of deptList) {
        modDeptList.push(deptItem.name);
    }

    useEffect(() => {
        let details = { contractType: 'Manpower Contractor' }
        apiService2('POST', myConfig.getContractorFilter, details)
            .then(response => {
                setContractorList(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, [open]);

    let modContList = [];
    for (const cont of contractorList) {
        modContList.push(cont.name);
    }

    function handleTryAgain() {
        setSubmitionError(false);
        setErrorMessage('');
    }

    useEffect(() => {
        apiService2('GET', myConfig.getAccessCategory)
            .then(response => {
                setAccessList(response.data)
                if (response.data.length == 0) {
                    //toast.error("Access Control list Empty, Please add access Control first");
                }
            })
            .catch(error => {
                console.error(error);
            })
    }, []);

    let modAccessList = [];
    for (const item of accessList) {
        modAccessList.push(item.name);
    }

    useEffect(() => {
        apiService2('POST', myConfig.getFacilitiesFilterEP, {},)
          .then(response => {
            setFacilityList(response.data);
          })
          .catch(error => {
            console.error(error);
          })
      }, [open]);
    
      let modSectionList = [];
      for (const fac of facilityList.filter(x => x.type == 'Section')) {
        modSectionList.push(fac.name);
      }

    const valueDB = { "department": modDeptList, "contract": modContList, "accesscategory": modAccessList, "section": modSectionList, };

    if (props.open.state & !open) {
        setOpen(true);
        setSubmitionError(false);
        setSubmitionCompleted(false);
        getInitialValues();
    }

    // Handle States for the dialog box
    function handleClose() {
        setOpen(false);
        setSubmitionError(false);
        setSubmitionCompleted(false);
        props.open.setfn();
    }


    function handleSelect() {

    }

    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required('Fistname is required'),
        lastname: Yup.string().required('Lastname is required'),
        userid: Yup.string().required('User ID is required'),
        email: Yup.string().required('Email is required').email('Enter a vaild email'),
        department: Yup.string().required('Department is required'),
        designation: Yup.string().required('Designation is required'),
        payrate: Yup.number('Only numeric characters are allowed.').required('Payrate is required'),
        employeetype: Yup.string().required('Employee Type is required'),
        contract: Yup.string()
            .when('employeetype', {
                is: (emp) => emp == 'Contractor',
                then: Yup.string().required('Contract is required'),
                otherwise: Yup.string().notRequired()
            }),
        accesscategory: Yup.string().required('Access Category is required'),
        dob: Yup.date()
            .required('Date of Birth is required')
            .test(
                'dob',
                'You must be at least 18 years old',
                value => {
                    return new Date(value).setFullYear(new Date(value).getFullYear() + 18) <= new Date();
                }
            ),
    })


    return (
        <React.Fragment>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'sm'}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                {!isSubmitionCompleted && !isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Edit Resource</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6"></DialogContentText>
                            <Formik

                                enableReinitialize
                                initialValues={initialValues}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(true);
                                    const resource = props.open.resource;
                                    values['_id'] = resource._id;
                                    var access = accessList.find(x => x.name == values['accesscategory']);
                                    values['accesslist'] = access.accesslist;
                                    apiService2('POST', myConfig.updateResource, values)
                                        .then(resp => {
                                            console.log("Resource Add Failed->", resp);
                                            if (resp.data == 'User already Exits!') {
                                                setErrorMessage(resp.data);
                                                setSubmitionError(true);
                                            } else {
                                                setSubmitionCompleted(true);
                                            }
                                        })
                                        .catch(error => {
                                            console.log(["Resource Add Failed->", error]);
                                            //handleClose();
                                            setSubmitionError(true);
                                            setSubmitionCompleted(true);
                                        });
                                }}
                                validationSchema={validationSchema} >
                                {props => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        dirty,
                                        isSubmitting,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        handleReset,
                                        resetForm,
                                        setFieldValue
                                    } = props;
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <Grid container spacing={2}>
                                                {formCreator("Complex", 'edit', formFields, initialValues, values, handleChange, handleBlur, touched, errors, valueDB, handleSelect, false, setFieldValue, 6)}
                                            </Grid>
                                            <DialogActions>
                                                <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                                                    Cancel
                                                </Button>
                                                <Button type="button" variant="contained" color="primary"
                                                    onClick={() => resetForm()}
                                                    disabled={!dirty || isSubmitting}>
                                                    Reset
                                                </Button>
                                                <Button type="submit" variant="contained" color="primary" disabled={!dirty || isSubmitting}>
                                                    Submit
                                                </Button>
                                            </DialogActions>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionCompleted && !isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Thanks! Resource Updated</DialogTitle>
                        <DialogContent>
                            {/* <DialogContentText variant="h6">Thanks</DialogContentText> */}
                            <DialogActions >
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Sorry! Update Resource Failed</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6"> {errorMessage ? errorMessage : 'Try Again Later'}</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleTryAgain}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
            </Dialog>
        </React.Fragment>
    );
}

export default EditResource;