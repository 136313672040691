import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
  Button,
  withStyles,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { myConfig } from "../../settings";
import { userModel } from "../../schema/schema.js";
import { apiService } from '../../features/apiService';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { getDepts } from '../../features/helpers/genhelper';
import { formCreator } from "../../features/helpers/formhelper";

// API End Point
const contactFormEndpoint = myConfig.addUserEndPoint;
let fakecount = 0;

const AddUser = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(props.open.state);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [isSubmitionError, setSubmitionError] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const formFields = userModel;
  //const [fakecount, setFC] = useState(0);
  const initValues = getInitialValues();
  const [initialValues, setInitialValues] = useState(initValues);
  const [deptList, setDeptList] = useState([]);
  //let deptList = [];
  let modDeptList = [];

  function getInitialValues() {
    let ivalues = {};
    for (let item of userModel) {
      ivalues[item['field']] = "";
    }
    ++fakecount;
    return ivalues;
  }

  // Get Department list
  useEffect(() => {
    getDepts(user).then( resp=> { setDeptList(resp[1]);})
  }, [])
  const valueDB = { "dept": deptList};
  // Handle States for the dialog box
  function handleClose() {
    // console.log("handleClose: Setting open to false");
    setOpen(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);
    props.open.setfn();
  }

  function handleClickOpen() {
    // console.log(["Comp: open->", true])
    setSubmitionCompleted(false);
    setOpen(true);
  }

  // console.log(["Here open->", props.open.state, open, isSubmitionError]);
  if (props.open.state & !open) {
    setOpen(true);
  }

  return (
    <React.Fragment>
      <Dialog
        open = {open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        >
      {!isSubmitionCompleted && (
        <React.Fragment>
          <DialogTitle id="form-dialog-title">Add User</DialogTitle>
          <DialogContent>
            <DialogContentText variant="h6"></DialogContentText>
            <Formik
              enableReinitialize
              key={fakecount}
              initialValues= {initialValues}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                //console.log(values);
                values['tenantid'] = user.tenantid;
                apiService(contactFormEndpoint, values)
                  .then ( resp => {
                    console.log("User added ");
                    toast.success("User added ");
                    setSubmitionCompleted(true);
                  })
                  .catch (error => {
                    console.error(error);
                    setSubmitionError(true);
                  }) 
              }}
              
              // Need to expand the validation schema more later
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email()
                  .required("Required"),
                firstname: Yup.string().required("Required")
              })}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                  resetForm
                } = props;
                console.log("Form values->", values);
                //setformValues(values);
                return (
                  
                  <form onSubmit={handleSubmit}>
                    {formCreator("Complex", 'add',formFields, initialValues, values, handleChange, handleBlur, touched, errors, valueDB)}
                    
                    <DialogActions>
                      <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button  type="button" variant="contained" color="primary"
                        onClick={()=>resetForm({ values: setInitialValues(getInitialValues()) })}
                        disabled={!dirty || isSubmitting}
                      >
                        Reset
                      </Button>
                      <Button type="submit" variant="contained" color="primary" disabled={!dirty || isSubmitting}>
                        Submit
                      </Button>
                    </DialogActions>
                  </form>
                );
              }}
            </Formik>
            <ToastContainer />
          </DialogContent>
          
        </React.Fragment>
      )}
      {isSubmitionCompleted && (
        <React.Fragment>
          <DialogTitle id="form-dialog-title">User Added!</DialogTitle>
          <DialogContent>
            <DialogContentText variant="h6">Thanks</DialogContentText>
            <DialogActions>
              <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                Back to app
              </Button>
            </DialogActions>
          </DialogContent>
        </React.Fragment>
      )}
      {isSubmitionError && (
        <React.Fragment>
          <DialogTitle id="form-dialog-title">Sorry! Add User Failed</DialogTitle>
          <DialogContent>
            <DialogContentText variant="h6">Try Again Later</DialogContentText>
            <DialogActions>
              <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                Back to app
              </Button>
            </DialogActions>
          </DialogContent>
        </React.Fragment>
      )}
    </Dialog>
  </React.Fragment>
  );
}

export default AddUser;