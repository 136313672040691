import { Box, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState, useEffect } from "react";
import { GridToolbarContainer } from '@mui/x-data-grid';
import { myConfig } from "../../settings.js";
import { workOrderModel } from "../../schema/schema.js";
import { useSelector } from 'react-redux';
import  { apiService } from '../../features/apiService.js';

const Analytics = () => {
  
  const { user } = useSelector((state) => state.auth);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
 
  return (
    <Box m="20px">
      <Header title="Analytics" subtitle="" />
      <Box
        m="40px 0 0 0"
         height="70vh"
      >
        UNDER DEVELOPMENT
      </Box>
    </Box>
  );
};

export default Analytics;
 