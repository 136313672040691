import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataCorp } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { GridToolbarContainer } from '@mui/x-data-grid';

function CustomToolbar() {
  return (
    <GridToolbarContainer className="mb-1">
      <Button variant="contained" color="primary" startIcon={<AddIcon />}>
        Add
      </Button>
      <Button variant="contained" color="primary" startIcon={<EditIcon />}>
        Edit
      </Button> 
      <Button variant="contained" color="primary" startIcon={<DeleteIcon />}>
        Delete
      </Button> 
    </GridToolbarContainer>
  );
}

const Corp = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "head",
      headerName: "Head",
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
    },
    {
      field: "subscription",
      headerName: "Subscription Type",
      flex: 1,
    }
  ];

  return (
    <Box m="20px">
      <Header title="CORP" subtitle="Managing the Corporation" />
      <Box
        m="40px 0 0 0"
         height="70vh"
      >
        <DataGrid checkboxSelection 
          rows={mockDataCorp} 
          columns={columns} 
          components={{ Toolbar: CustomToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Corp;
