import React, { useState, useEffect } from "react";
import { Grid, MenuItem, Select, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
    Button,
    withStyles,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { myConfig } from "../../settings";
import { facilityModel } from "../../schema/schema.js";
import { apiService2 } from '../../features/apiService';
import { useSelector } from 'react-redux';
import { CURRENCYLIST } from "../../data/currency";
import { formCreator } from "../../features/helpers/formhelper";

// API End Point

let fakecount = 0;

const EditFacility = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(props.open.state);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [isSubmitionError, setSubmitionError] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const [facilityList, setFacility] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const [typeList, setTypeList] = useState([]);
    const [formFields, setFormFields] = useState(facilityModel);
    function getInitialValues() {
        let ivalues = {};
        const facility = props.open.facility;
        for (const item of facilityModel) {
            ivalues[item.field] = facility[item.field];
        }
        fakecount++;
        setInitialValues({ ...ivalues })
    }


    let modFacilityList = [];
    facilityList.forEach((item) => {
        modFacilityList.push(item.name);
    })

    let modCurrencyList = [];
    CURRENCYLIST.forEach((item) => {
        modCurrencyList.push(item.currency);
        modCurrencyList.sort();
    })

    function getFacilityList() {
        apiService2('GET', myConfig.getFacilitiesEP, { tenantid: user.tenantid },)
            .then(response => {
                setFacility(response.data);
            })
            .catch(error => {
                console.error(error);
            })
    }

    // Handle States for the dialog box
    function handleClose() {
        setOpen(false);
        setSubmitionError(false);
        setSubmitionCompleted(false);
        props.open.setfn();

    }

    if (props.open.state & !open) {
        setOpen(true);
        getInitialValues()
        getFacilityList();
        if (user.access == 'ADMIN') {
            setTypeList(["Facility", "Section", "Sub Section"])
        } else {
            setTypeList(["Section", "Sub Section"])
        }
    }

    function handleSelect() {

    }

    const valueDB = { "type": typeList, "currency": modCurrencyList, "parent": modFacilityList };


    return (
        <React.Fragment>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'xs'}
                aria-labelledby="form-dialog-title">
                {!isSubmitionCompleted && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Edit Facility</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6"></DialogContentText>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(true);
                                    const facility = props.open.facility;
                                    values['_id'] = facility._id;
                                    apiService2('POST', myConfig.updateFacilityEP, values)
                                        .then(response => { setSubmitionCompleted(true); })
                                        .catch(error => {
                                            console.error(error);
                                            setSubmitionError(true);
                                            setSubmitionCompleted(false);
                                        })
                                }}
                                // Need to expand the validation schema more later
                                validationSchema={Yup.object().shape({
                                    name: Yup.string().required("Facility Name Required"),
                                    type: Yup.string().required("Facility Type Required"),
                                })} >
                                {props => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        dirty,
                                        isSubmitting,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        handleReset
                                    } = props;
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            {formCreator("Complex", 'add', formFields, initialValues, values, handleChange, handleBlur, touched, errors, valueDB, handleSelect)}
                                            <DialogActions>
                                                <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="contained" color="primary" disabled={!dirty || isSubmitting}>
                                                    Submit
                                                </Button>
                                            </DialogActions>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionCompleted && (
                    <React.Fragment>
                        {/* <DialogTitle id="form-dialog-title">Update Facility</DialogTitle> */}
                        <DialogContent>
                            <DialogContentText variant="h6">Thanks! Facility successfully Updated</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Sorry! Update Facility Failed</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">Try Again Later</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
            </Dialog>
        </React.Fragment>
    );
}

export default EditFacility;