import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import React, { useState } from "react";

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PreviewIcon from '@mui/icons-material/Preview';
import { useSelector } from "react-redux";
import { apiService2 } from "../../features/apiService";
import { myConfig } from "../../settings";
import AddCategory from "./add";
import { useEffect } from "react";
import { accessCategoryModel } from "../../schema/schema";
import EditCategory from "./edit";
import AddAccessControl from "./addaccess";
import { getAccess } from "../../features/helpers/authhelper";
import { timeZoneConverter } from "../../features/helpers/commonhelper";
import { tableView } from "../../features/helpers/table";


const AccessControl = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const columns = [];

    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openAccess, setOpenAccess] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [category, setCatgeory] = useState({});
    const { user } = useSelector((state) => state.auth);
    const [selectedrow, setRow] = useState(-1);
    const [rowSelection, setRowSelection] = useState({});
    const [message, setMessage] = useState('');
    const access = getAccess(user, 'security');

    for (const item of accessCategoryModel) {
        if (item.hasOwnProperty("skip")) {
            if (item.skip) continue;
        }
        columns.push({ accessorKey: item['field'], header: item['label'], size: 150 });
    }


    useEffect(() => {
        apiService2('GET', myConfig.getAccessCategory,)
            .then(response => {
                var list = response.data;
                list?.forEach(res => { res.cdate = timeZoneConverter(res.createdOn) })
                setCategoryList(list);
            })
            .catch(error => {
                console.error(error);
            });
    }, [refresh]);

    useEffect(() => {
        const wrnumber = Object.keys(rowSelection)[0];
        const selected = Object.values(rowSelection)[0];
        let index = 0;
        if (selected) {
            for (const temp of categoryList) {
                if (temp._id == wrnumber) {
                    setCatgeory(temp);
                    setRow(index);
                    break;
                }
                ++index;
            }
        } else {

            setCatgeory({});
        }
    }, [rowSelection]);


    function handleClose() {
        setOpen(false);
        setOpenEdit(false)
        setOpenDelete(false)
        setOpenAccess(false)
        setRefresh(!refresh);
        //setRow(-1);
        //setCatgeory({});
    }

    function handleClickOpen() {
        setOpen(true);
        setRefresh(false);
    }

    function handleDelete() {
        apiService2('POST', myConfig.deleteAccessCategory, { "_id": category._id, message: message })
            .then(resp => {
                handleClose();
            })
            .catch(error => {
                console.log(["Resource Add Failed->", error]);
                handleClose();
            });
    }

    function CustomToolbar() {
        return (
            <Box className="mb-1">
                {
                    access.isCreate && <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleClickOpen}>
                        Add
                    </Button>
                }
            </Box>
        );
    }
    return (
        <Box m="10px 20px 20px 20px">
            <Box
                m="20px 0 0 0"
                height="70vh"
            >
                {CustomToolbar()}
                {tableView(
                    false, columns, categoryList, setRowSelection,
                    rowSelection, '_id',
                    true,
                    ({ row, table }) => [
                        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                            {
                                access.isEdit && <Tooltip title="Edit">
                                    <IconButton
                                        color="secondary"
                                        onClick={() => {
                                            setCatgeory({ ...row.original })
                                            setOpenEdit(true);
                                        }}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            {
                                access.isDeleted && <Tooltip title="Delete">
                                    <IconButton
                                        color="error"
                                        onClick={() => {
                                            setCatgeory({ ...row.original })
                                            setOpenDelete(true);
                                        }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            <Tooltip title="View Access">
                                <IconButton
                                    onClick={() => {
                                        setCatgeory({ ...row.original })
                                        setOpenAccess(true);
                                    }}>
                                    <PreviewIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    ])
                }
                <AddCategory open={{ state: open, setfn: handleClose }} />
                <EditCategory open={{ state: openEdit, setfn: handleClose, category: category }} />
                <AddAccessControl open={{ state: openAccess, setfn: handleClose, category: category }} />
                <Dialog
                    open={openDelete}
                    fullWidth={true}
                    maxWidth={'xs'}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title">
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Delete Access Category</DialogTitle>

                        <DialogContent>
                            <DialogContentText variant="h6">Are you sure you want to delete?</DialogContentText>
                            <DialogActions sx={{
                               
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}>
                                <Button type="button" variant="contained" color="secondary" onClick={handleDelete}>
                                    Confirm
                                </Button>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                </Dialog>
            </Box>
        </Box>
    );
};

export default AccessControl;