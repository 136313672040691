import { Box, Typography, useTheme, Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog, Tooltip, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyIcon from '@mui/icons-material/Key';
import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify'
import { myConfig } from "../../settings.js";
import { useSelector } from 'react-redux';
import { apiService, apiService2 } from '../../features/apiService';
import { MaterialReactTable } from "material-react-table";
import { VendorModel, vendorModel } from "../../schema/schema";

import { getAccess } from "../../features/helpers/authhelper";
import AddVendor from "./add";
import EditVendor from "./edit";
import { timeZoneConverter } from "../../features/helpers/commonhelper.js";
import { tableView } from "../../features/helpers/table.js";

const Vendor = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const columns = [];

    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [vendorList, setVendorList] = useState([]);
    const [vendor, setVendor] = useState([]);
    const { user } = useSelector((state) => state.auth);
    const [selectedrow, setRow] = useState(-1);
    const [rowSelection, setRowSelection] = useState({});
    const [message, setMessage] = useState('');
    const access = getAccess(user, 'setup');


    for (const item of vendorModel) {
        if (item.hasOwnProperty("view")) {
            if (!item.view) continue;
        }
        columns.push({ accessorKey: item['field'], header: item['label'], size: 150 });
    }

    useEffect(() => {
        apiService2('GET', myConfig.getVendorEP)
            .then(response => {
                var vendorList = response.data;
                vendorList?.forEach(res => {
                    res.cdate = timeZoneConverter(res.createdOn)
                })
                setVendorList(vendorList);
            })
            .catch(error => {
                console.error(error);
            });
    }, [refresh]);

    useEffect(() => {
        const wrnumber = Object.keys(rowSelection)[0];
        const selected = Object.values(rowSelection)[0];
        let index = 0;
        if (selected) {
            for (const temp of vendorList) {
                if (temp._id == wrnumber) {
                    console.log(temp);
                    setVendor(temp);
                    setRow(index);
                    break;
                }
                ++index;
            }
        } else {

            setVendor({});
        }
    }, [rowSelection]);

    function handleClose() {
        setOpen(false);
        setOpenEdit(false)
        setOpenDelete(false)
        setRefresh(!refresh);
    }

    function handleClickOpen() {
        setOpen(true);
        setRefresh(false);
    }

    function handleDelete() {

        apiService2('POST', myConfig.deleteVendorEP, { "_id": vendor._id })
            .then(resp => {
                handleClose();
            })
            .catch(error => {
                console.log(["Vendor Add Failed->", error]);
                handleClose();
            });
    }


    function CustomToolbar() {
        return (
            <Box className="mb-1">
                {
                    access.isCreate && <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleClickOpen}>
                        Add
                    </Button>
                }
            </Box>
        );
    }
    return (
        <Box m="10px 20px 20px 20px">
            <Box
                m="20px 0 0 0"
                height="70vh">
                {CustomToolbar()}
                {tableView(
                    false, columns, vendorList, setRowSelection,
                    rowSelection, '_id',
                    true,
                    ({ row, table }) => [
                        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                            {
                                access.isEdit && <Tooltip title="Edit">
                                    <IconButton
                                        color="secondary"
                                        onClick={() => {
                                            setVendor({ ...row.original })
                                            setOpenEdit(true);
                                        }}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            {
                                access.isDeleted && <Tooltip title="Delete">
                                    <IconButton
                                        color="error"
                                        onClick={() => {
                                            setVendor({ ...row.original })
                                            setOpenDelete(true);
                                        }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Box>
                    ])
                }
                <AddVendor open={{ state: open, setfn: handleClose }} />
                <EditVendor open={{ state: openEdit, setfn: handleClose, vendor: vendor }} />
                <Dialog
                    open={openDelete}
                    fullWidth={true}
                    maxWidth={'xs'}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title">
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">{message} Vendor</DialogTitle>

                        <DialogContent>
                            <DialogContentText variant="h6">Are you sure you want to {message}?</DialogContentText>
                            <DialogActions sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}>
                                <Button type="button" variant="contained" color="secondary" onClick={handleDelete}>
                                    Confirm
                                </Button>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                </Dialog>
            </Box>
        </Box>
    );
};

export default Vendor;
