
export const VERSION = 6; 
const MODE = "PRODUCTION";    // PRODUCTION or DEV backend
const EP_BASE_DEV = "http://localhost:5050/api";
//const EP_BASE_PROD = "http://127.0.0.1:5050/api";
//const EP_BASE_PROD = "http://54.208.226.244/api";
const EP_BASE_PROD = "https://eam.arvamtech.com/api";
//const EP_BASE_PROD = "http://192.168.56.84:5050/api";
const EP_BASE = (MODE === "PRODUCTION") ? EP_BASE_PROD : EP_BASE_DEV;

export const myConfig = {
    // API End points   
    registerUserEP: EP_BASE + "/users/register",
    loginUserEP: EP_BASE + "/users/login",
    fogotPasswordEP: EP_BASE + "/users/forgot",
    resetPasswordEP: EP_BASE + "/users/resetpassword",
    changePasswordEP: EP_BASE + "/users/changepassword",
    createPasswordEP: EP_BASE + "/users/createpassword",
    addUserEndPoint: EP_BASE + "/users/add",
    editUserEndPoint: EP_BASE + "/users/edit",
    getUsersEP: EP_BASE + "/users",
    getUserCountEP: EP_BASE + "/users/count",
    addAssetEP: EP_BASE + "/assets/add",
    getAssetsEP: EP_BASE + "/assets",
    getLogicalAssetsEP: EP_BASE + "/assets/logicalassets",
    getAssetCountEP: EP_BASE + "/assets/count",
    updateAssetEP: EP_BASE + "/assets/update",
    getAssetsFilterEP: EP_BASE + "/assets/filter",
    updateAssetMeterValueEP: EP_BASE + "/assets/updateMeterValue",
    updateAssetParamEP: EP_BASE + "/assets/updateparam",

    addFacilityEP: EP_BASE + "/facility",
    getFacilitiesEP: EP_BASE + "/facility",
    getFacilitiesFilterEP: EP_BASE + "/facility/filter",
    updateFacilityEP: EP_BASE + "/facility/update",
    deleteFacilityEP: EP_BASE + "/facility/delete",
    getFacilityCountEP: EP_BASE + "/facility/count",

    addDeptEP: EP_BASE + "/dept/add",
    getDeptEP: EP_BASE + "/dept",
    updateDeptEP: EP_BASE + "/dept/update",
    deleteDeptEP: EP_BASE + "/dept/delete",
    getDeptCountEP: EP_BASE + "/dept/count",

    addInventoryEP: EP_BASE + "/inventory/add",
    getInventoryEP: EP_BASE + "/inventory",
    getInventoryCountEP: EP_BASE + "/inventory/count",
    updateInventoryEP: EP_BASE + "/inventory/update",
    deleteInventoryEP: EP_BASE + "/inventory/delete",

    addMainLibEP: EP_BASE + "/mainlib/add",
    updateMainLibEP: EP_BASE + "/mainlib/update",
    addMainLibTaskEP: EP_BASE + "/mainlib/addtask",
    getMainLibTaskEP: EP_BASE + "/mainlib/gettask",
    getMainLibEP: EP_BASE + "/mainlib",
    getMainLibCountEP: EP_BASE + "/mainlib/count",
    deleteMainLibEP: EP_BASE + "/mainlib/delete",

    addSparesMaintenanceEP: EP_BASE + "/mainlib/addspares",
    addMTaskEP: EP_BASE + "/mtask/add",
    getMTaskEP: EP_BASE + "/mtask",
    getMTaskCountEP: EP_BASE + "/mtask/count",
    getMaintenanceEP: EP_BASE + "/maintenance",
    getMaintenanceFilterEP: EP_BASE + "/maintenance/filter",
    addMaintenanceEP: EP_BASE + "/maintenance/add",
    editMaintenanceEP: EP_BASE + "/maintenance/update",
    deleteMaintenanceEP: EP_BASE + "/maintenance/delete",
   
    generateWorkOrderEP: EP_BASE + "/maintenance/generate",
    getWorkOrderEP: EP_BASE + "/workorder",
    getWorkOrderFilterEP : EP_BASE + "/workorder/filter",
    getWorkOrderSingleEP: EP_BASE + "/workorder/single",
    getWorkOrderById: EP_BASE + "/workorder/getWorkOrderById",
    getWOOpenCountEP: EP_BASE + "/workorder/opencount",
    editWOEndPoint: EP_BASE + "/workorder/update",
    viewWOEndPoint: EP_BASE + "/workorder/view",
    suspendWOEndPoint: EP_BASE + "/workorder/suspend",
    addAccessorWOEndPoint: EP_BASE + "/workorder/addaccessor",
    addResourceWOEndPoint: EP_BASE + "/workorder/addresource",
    workorderReopenEP: EP_BASE + "/workorder/reopen",
    approveWorkOrderEP: EP_BASE + "/workorder/approve",
    rejectWorkOrderEP: EP_BASE + "/workorder/reject",
    approveWorkOrderWithLoginEP: EP_BASE + "/workorder/approvalwithlogin",
  
    rejectWorkOrderWithLoginEP: EP_BASE + "/workorder/rejectwithlogin",
    
    getMTTREndPoint: EP_BASE + "/workorder/mttrreport",
    getPOCReportEP: EP_BASE + "/workorder/pocreport", 

    getWorkRequestEP: EP_BASE + "/workrequest",
    addWorkRequestEP: EP_BASE + "/workrequest/add",
    updateWREP: EP_BASE + "/workrequest/update",
    getWorkRequestFilterEP: EP_BASE + "/workrequest/filter",
    getWorkRequestSingleEP: EP_BASE + "/workrequest/single",
    
    getFailurePreventionEP: EP_BASE + "/failureprevention",
    addFailurePreventionEP: EP_BASE + "/failureprevention/update",

    updateInspectionEP: EP_BASE + "/assets/update",
    getInspectionConfigEP: EP_BASE + "/assets/getInspectionConfigEP",
    addInspectionEP: EP_BASE + "/inspection/",
    getInspectionEP: EP_BASE + "/inspection/",
    getInspectionFilterEP: EP_BASE + "/inspection/filter",
    updateInspectEP: EP_BASE + "/inspection/update",
    updateInspectionAspectEP: EP_BASE + "/inspection/updateInspectionAspect",
    updateSuspensionEP: EP_BASE + "/inspection/updateSuspension",
    deleteInspectionEP: EP_BASE + "/inspection/deleteInspection",
    updateWOInspectionEP: EP_BASE + "/workorder/updateWOInspection",
    

    getUnitEP: EP_BASE + "/unit",
    addUnitEP: EP_BASE + "/unit",
    updateUnitEP: EP_BASE + "/unit/update",
    deleteUnitEP: EP_BASE + "/unit/delete",
    
    getResource: EP_BASE + "/resource",
    addResource: EP_BASE + "/resource",
    updateResource: EP_BASE + "/resource/update",
    deleteResource: EP_BASE + "/resource/delete",
    uploadFileResource: EP_BASE + "/resource/upload",
    updateResourceAccess: EP_BASE + "/resource/updateAccess",
    getResourceUsersEP: EP_BASE + "/resource/getusers",
   
    getAssetfamily: EP_BASE + "/assetfamily",
    addAssetfamily: EP_BASE + "/assetfamily",
    updateAssetfamily: EP_BASE + "/assetfamily/update",
    deleteAssetfamily: EP_BASE + "/assetfamily/delete",

    getConfiguration: EP_BASE + "/configuration",
    addConfiguration: EP_BASE + "/configuration/add",
    getConfigurationFilter: EP_BASE + "/configuration/filter",
    updateConfiguration: EP_BASE + "/configuration/update",

    getContractor: EP_BASE + "/contractor",
    addContractor: EP_BASE + "/contractor",
    updateContractor: EP_BASE + "/contractor/update",
    deleteContractor: EP_BASE + "/contractor/delete",
    updateItemContractor: EP_BASE + "/contractor/updateItem",
    getContractorFilter: EP_BASE + "/contractor/filter",

    getAccessCategory: EP_BASE + "/accessCategory",
    addAccessCategory: EP_BASE + "/accessCategory",
    updateAccessCategory: EP_BASE + "/accessCategory/update",
    deleteAccessCategory: EP_BASE + "/accessCategory/delete",
    updateItemAccessCategory: EP_BASE + "/accessCategory/updateAccess",

    getBreakBownCodeEP: EP_BASE + "/breakdowncode",
    addBreakBownCodeEP: EP_BASE + "/breakdowncode",
    updateBreakBownCodeEP: EP_BASE + "/breakdowncode/update",
    deleteBreakBownCodeEP: EP_BASE + "/breakdowncode/delete",


    getVendorEP: EP_BASE + "/vendor",
    addVendorEP: EP_BASE + "/vendor",
    updateVendorEP: EP_BASE + "/vendor/update",
    deleteVendorEP: EP_BASE + "/vendor/delete",


    getStoreConfigEP: EP_BASE + "/storeconfig",
    addStoreConfigEP: EP_BASE + "/storeconfig",
    updateStoreConfigEP: EP_BASE + "/storeconfig/update",
    deleteStoreConfigEP: EP_BASE + "/storeconfig/delete",


    getStoreEP: EP_BASE + "/store",
    addStoreEP: EP_BASE + "/store",
    updateStoreEP: EP_BASE + "/store/update",
    transferStoreEP: EP_BASE + "/store/transfer",

    getProcurementEP: EP_BASE + "/procurement",
    addProcurementEP: EP_BASE + "/procurement",
    updateProcurementEP: EP_BASE + "/procurement/update",
    deleteProcurementEP: EP_BASE + "/procurement/delete",
    receivedProcurementEP: EP_BASE + "/procurement/received",

    getProcurementRequestEP: EP_BASE + "/procurementrequest",
    addProcurementRequestEP: EP_BASE + "/procurementrequest",
    updateProcurementRequestEP: EP_BASE + "/procurementrequest/update",
    deleteProcurementRequestEP: EP_BASE + "/procurementrequest/delete",
    approveProcurementRequestEP: EP_BASE + "/procurementrequest/approve",

    getStoreRequestEP: EP_BASE + "/storerequest",
    updateStoreRequestEP: EP_BASE + "/storerequest/update",
    dispatchStoreRequestEP: EP_BASE + "/storerequest/dispatch",

    getSensorDataEP: EP_BASE + "/sensordata",
    getSensorStateDataEP: EP_BASE + "/sensordata/assetstate",

    getNotificationSettEP: EP_BASE + "/notificationsettings",
    addNotificationSettEP: EP_BASE + "/notificationsettings",
    updateNotificationSettEP: EP_BASE + "/notificationsettings/update",
    deleteNotificationSettEP: EP_BASE + "/notificationsettings/delete",

    getWorkshopEP: EP_BASE + "/workshop",
    updateWorkshopEP: EP_BASE + "/workshop/update",
    updateDispatchEP: EP_BASE + "/workshop/dispatch",
    updateReceiveEP: EP_BASE + "/workshop/receive",
   
};

