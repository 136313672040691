import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";
import { apiService, apiService2 } from "../../features/apiService";
import { myConfig } from "../../settings";
import { useSelector } from "react-redux";
import { MaterialReactTable } from "material-react-table";
import { Box, Button, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import Header from "../../components/Header";
import { failurePreventionModel } from "../../schema/schema";
import FailurePreventionUpdate from "./failurePrevention";
import { toast } from "react-toastify";
import FailurePreventionView from "./view";
import { getAccess } from "../../features/helpers/authhelper";
import { timeZoneConverter } from "../../features/helpers/commonhelper";
import { tableView } from "../../features/helpers/table";


const FailurePrevention = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const columns = [];
    const [open, setOpen] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [failurePreventionList, setFailurePreventionList] = useState([]);
    const { user } = useSelector((state) => state.auth);
    const [selectedrow, setRow] = useState(-1);
    const [rowSelection, setRowSelection] = useState({});
    const [selectedFailurePrevention, setFP] = useState({});
    const access = getAccess(user, 'Failure Prevention');
    for (const item of failurePreventionModel) {
        if (item.hasOwnProperty("skip")) {
            if (item.skip) continue;
        }
        if (item.type == Boolean) columns.push({ accessorKey: item['field'], header: item['label'], size: 150, accessorFn: d => { return d.approvalrequired ? 'yes' : 'no' } })
       else if (item.populate == true) { columns.push({ accessorKey: item['field'] + '.' + item['field2'], header: item['label'], size: 150 }); }
        else { columns.push({ accessorKey: item['field'], header: item['label'], size: 150 }); }
    }

    useEffect(() => {
        apiService2('GET', myConfig.getFailurePreventionEP)
            .then(response => {
                response.data.forEach((item) => {
                    item.cdate = timeZoneConverter(item.createdOn);
                    item.mdate = timeZoneConverter(item.modifiedOn);
                });
                setFailurePreventionList(response.data);
                
            })
            .catch(error => {
                console.error(error);
            })
    }, [refresh]);

    useEffect(()=> {
        console.log(Object.keys(rowSelection));
        const wonumber = Object.keys(rowSelection)[0];
        const selected = Object.values(rowSelection)[0];
        let index = 0;
        if (selected) {
        for (const tempwo of failurePreventionList){
          if (tempwo._id == wonumber) {
            setFP(tempwo);
            setRow(index);
            break;
          }
          ++index;
        } } else {
          
          setFP({});
        }
      },[rowSelection]);

    function handleUpdateFP() {
        if (selectedrow != -1) {
            setOpen(true);
          } else toast.warning("Select a row/WO first ");
    }

    function handleView() {
        if (selectedrow != -1) {
            setOpenView(true);
          } else toast.warning("Select a row/WO first ");
    }

    function handleClose(){
        setOpen(false);
        setOpenView(false);
        setRefresh(!refresh);
        
        setRowSelection({});
    }

    function CustomToolbar() {
        return (
            <Box className="mb-1">
                    {
                    access?.isEdit && <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={handleUpdateFP}>
                        Update
                    </Button>
                }

                <Button variant="contained" color="primary"  onClick={handleView}>
                    View
                </Button>
            </Box>
        );
    }



    return (
        <Box m="20px">
            <Header title="Failure Prevention" subtitle="" />
            <Box
                m="40px 0 0 0"
                 height="70vh">
                {CustomToolbar()}
                {tableView(false, columns, failurePreventionList, setRowSelection, rowSelection, '_id', false, null, 'assetId.assetid', { id: 'assetId.assetid', desc: false })}
                 <FailurePreventionUpdate open={{state: open, setfn: handleClose, rowid: selectedrow, failurePrevention:selectedFailurePrevention}}   />
                 <FailurePreventionView  open={{state: openView, setfn: handleClose, rowid: selectedrow, failurePrevention:selectedFailurePrevention}}/>
            </Box>
        </Box>
    );

}
export default FailurePrevention;