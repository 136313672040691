import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputLabel, ListItemText, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { inspectionAspectsModel } from "../../../schema/schema";
import { apiService, apiService2 } from "../../../features/apiService";
import { myConfig } from "../../../settings";
import { toast } from "react-toastify";
let fakecount = 0;
const AddInspection = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user } = useSelector((state) => state.auth);
    const [open, setOpen] = useState(props.open.state);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [isSubmitionError, setSubmitionError] = useState(false);
    const [inspectionList, setInspectionList] = useState([])
    const initValues = getInitialValues();
    const [initialValues, setInitialValues] = useState(initValues);
    const [editValues, setEditValues] = useState({});
    const [maxWidth, setMaxWith] = useState('lg');
    const [asset, setAsset] = useState({});
    const [deptList, setDeptList] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [isEdit, setEdit] = useState(false);
    const [isChange, setChange] = useState(false);
    const [isMessageView, setMessageView] = useState(false);
    const [measuredValue, setMeasuredValue] = useState({ greenCategory: '', green: '', yellowMin: '', yellowMinLabel: '', yellowMax: '', redMin: '', redMinLabel: '', redMax: '' });
    var enumList = ['Greater than Equal', 'Less than Equal'];
    function getInitialValues() {
        let ivalues = {};
        for (const item of inspectionAspectsModel) {
            if (item.hasOwnProperty("fkey")) {
                if (!item["fkey"]) { ivalues[item.field] = ""; }
            } else {
                ivalues[item.field] = "";
            }
        }
        ++fakecount;
        return ivalues;
    }

    useEffect(() => {
        apiService(myConfig.getDeptEP)
            .then(response => {
                setDeptList(response.data)
                if (response.data.length == 0) {
                    toast.error("Department list Empty, Please add department first");
                }
            })
            .catch(error => {
                console.error(error);
            })
    }, []);


    let modDeptList = [];
    for (const deptItem of deptList) {
        modDeptList.push(deptItem.name);
    }

    useEffect(() => {
        apiService2('GET', myConfig.getUnitEP)
            .then(response => {
                setUnitList(response.data)
            })
            .catch(error => {
                console.error(error);
            })
    }, []);

    let modUnitList = [];
    
    for (const unit of unitList.filter(x => x.unitType == 'Measurement')) {
        modUnitList.push(unit.parameter);
    }

    if (props.open.state & !open) {
        setOpen(true);
        setMaxWith('lg');
        const asset = props.open.asset;
        setAsset(asset);
        setEdit(false);
        setMeasuredValue({ greenCategory: '', green: '', yellowMin: '', yellowMinLabel: '', yellowMax: '', redMin: '', redMinLabel: '', redMax: '' });
        if (asset['inspectionList'] && asset['inspectionList'].length > 0) {
            let data = asset['inspectionList'];
            setInspectionList([...data])
        } else {
            let data = [];
            setInspectionList([...data])
        }
    }

    function handleClose() {
        console.log(["handleClose: Setting open to false", open, props.open.user]);
        if (!isChange) {
            setOpen(false);
            setSubmitionError(false);
            setSubmitionCompleted(false);
            props.open.setfn();
        } else {
            setMessageView(true)
            setMaxWith('sm')
        }

    }

    function handleDeleteRow(index) {
        inspectionList.splice(index, 1)
        setInspectionList([...inspectionList]);
    }

    function handleEditRow(index, data) {
        let ivalues = {};
        let greenCatgeory;
        for (const [key, item] of Object.entries(data)) {
            if (key == 'greenCatgeory') {
                greenCatgeory = item;
            }
            ivalues[key] = item
        }
        if (greenCatgeory == 'Greater than Equal') {
            ivalues['yellowMinLabel'] = 'Less than ' + ivalues['yellowMin'];
            ivalues['redMinLabel'] = 'Less than ' + ivalues['redMin'];
        } else {
            ivalues['yellowMinLabel'] = 'Greater than ' + ivalues['yellowMin'];
            ivalues['redMinLabel'] = 'Greater than ' + ivalues['redMin'];
        }
        setEditValues({ ...ivalues });
        measuredValue['greenCategory'] = ivalues['greenCategory'];
        measuredValue['green'] = editValues['green'];
        measuredValue['yellowMinLabel'] = ivalues['yellowMinLabel'];
        measuredValue['yellowMax'] = ivalues['yellowMax'];
        measuredValue['redMinLabel'] = ivalues['redMinLabel'];
        measuredValue['redMax'] = ivalues['redMax'];
        setMeasuredValue({ ...measuredValue });
        setEdit(true);
    }


    function handleSave() {
        if (inspectionList.length > 0) {
            let values = {
                _id: asset['_id'],
                inspectionList: inspectionList
            }
            apiService2('POST', myConfig.updateInspectionEP, values)
                .then(resp => {
                    setChange(false);
                    setMessageView(false)
                    setSubmitionCompleted(true);
                    setMaxWith('sm')
                })
                .catch(error => {
                    console.log(["Add WR - Errored->", error]);
                    setMaxWith('sm')
                    setSubmitionError(true);
                    setSubmitionCompleted(true);
                });
        } else toast.warning("Please add the minimum one record");

    }

    function handleValue(values, event, index, type) {
        console.log(event.target.value);
        if (values['greenCategory'] == 'Greater than Equal') {
            if (index == 1) {
                measuredValue['green'] = event.target.value;
                measuredValue['yellowMin'] = event.target.value;
                measuredValue['yellowMinLabel'] = 'Less than ' + event.target.value;
                measuredValue['yellowMax'] = '';
                measuredValue['redMin'] = '';
                measuredValue['redMax'] = '';
                setMeasuredValue(measuredValue);
            } else if (index == 2) {
                measuredValue['yellowMax'] = event.target.value;
                measuredValue['redMin'] = event.target.value;
                measuredValue['redMinLabel'] = 'Less than ' + event.target.value;
                measuredValue['redMax'] = '';
                setMeasuredValue(measuredValue);
            } else {
                measuredValue['redMax'] = event.target.value;
                setMeasuredValue(measuredValue);
            }
        } else {
            if (index == 1) {
                measuredValue['green'] = event.target.value;
                measuredValue['yellowMin'] = event.target.value;
                measuredValue['yellowMinLabel'] = 'Greater than ' + event.target.value;
                measuredValue['yellowMax'] = '';
                measuredValue['redMin'] = '';
                measuredValue['redMax'] = '';
                setMeasuredValue(measuredValue);
            } else if (index == 2) {
                measuredValue['yellowMax'] = event.target.value;
                measuredValue['redMin'] = event.target.value;
                measuredValue['redMinLabel'] = 'Greater than ' + event.target.value;
                measuredValue['redMax'] = '';
                setMeasuredValue(measuredValue);
            } else {
                measuredValue['redMax'] = event.target.value;
                setMeasuredValue(measuredValue);
            }
        }
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                maxWidth={maxWidth}
                fullWidth={true}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                {!isSubmitionCompleted && !isMessageView && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Add Asset Inspection Aspects </DialogTitle>
                        <DialogContent>
                            {
                                !isEdit && <Formik
                                    enableReinitialize
                                    key={fakecount}
                                    initialValues={initialValues}
                                    onSubmit={(values, { setSubmitting }) => {
                                        let data = inspectionList;
                                        var unit;
                                        var valueType, final;
                                        setChange(true);
                                        values['greenCategory'] == 'Greater than Equal' ? valueType = 'dc' : valueType = 'in';
                                        valueType == 'in' ? Number(measuredValue['yellowMax']) > Number(measuredValue['yellowMin']) ? final = true : final = false : Number(measuredValue['yellowMin']) > Number(measuredValue['yellowMax']) ? final = true : final = false
                                        valueType == 'in' ? Number(measuredValue['redMax']) > Number(measuredValue['redMin']) ? final = true : final = false : Number(measuredValue['redMin']) > Number(measuredValue['redMax']) ? final = true : final = false
                                        if (final || values['inspectionType'] == 'Visual') {
                                            if (values['parameter'] != 'Leakage') {
                                                console.log(values['parameter']);
                                                var units = unitList.find(x => x.parameter == values['parameter']);
                                                console.log("UNITS->", units);
                                                if (!units) {
                                                    toast.warning("Unit of measurement not found...cannot add this aspect");
                                                    return;
                                                } else unit = units['uom']['metric'];
                                            } else {
                                                unit = '-'
                                            }
                                            let green, yellow, red;
                                            if (values['inspectionType'] == 'Visual') {
                                                green = 'Ok'
                                                yellow = '';
                                                red = 'Not-ok';
                                            } else {
                                                green = measuredValue['green'];
                                                yellow = measuredValue['yellowMin'] + '-' + measuredValue['yellowMax'];
                                                red = measuredValue['redMin'] + '-' + measuredValue['redMax'];
                                            }
                                            data.push({
                                                aspect: values['aspect'], parameter: values['parameter'], inspectionType: values['inspectionType'], inspectionBasic: values['inspectionBasic'],
                                                type: values['type'], department: values['department'], green: green, yellow: yellow, red: red, unit: unit, greenCategory: values['greenCategory'],
                                                yellowMin: measuredValue['yellowMin'], yellowMax: measuredValue['yellowMax'], redMin: measuredValue['redMin'], redMax: measuredValue['redMax'], isActive: true
                                            })
                                            setInspectionList([...data])
                                            var datas = { greenCategory: '', green: '', yellowMin: '', yellowMax: '', redMin: '', redMax: '' }
                                            setMeasuredValue(datas)
                                        } else {
                                            toast.warning("Please enter correct values");
                                        }
                                    }
                                    }
                                    // Need to expand the validation schema more later
                                    validationSchema={
                                        Yup.object().shape({
                                            aspect: Yup.string().required("Required"),
                                            parameter: Yup.string().required("Required"),
                                            inspectionType: Yup.string().required("Required"),
                                            inspectionBasic: Yup.string().required("Required"),
                                            type: Yup.string().required("Required"),
                                            department: Yup.string().required("Required"),
                                            green: Yup.number(),
                                            yellow: Yup.number(),
                                            red: Yup.number(),
                                        })}>
                                    {props => {
                                        const {
                                            values,
                                            touched,
                                            errors,
                                            dirty,
                                            isSubmitting,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            handleReset,
                                            resetForm
                                        } = props;
                                        return (
                                            <form onSubmit={handleSubmit}>
                                                {/*  */}
                                                <Grid container spacing={3}>
                                                    {(() => {
                                                        let result = [];
                                                        let enumlist = [];
                                                        let label;
                                                        result.push(<Grid container spacing={3}></Grid>)
                                                        for (const item of inspectionAspectsModel.filter(x => x.measuredValue == false)) {
                                                            label = item.label
                                                            if (item.hasOwnProperty("required")) { label = item.required ? label + "*" : label } else label = label;
                                                            if (item.select == true) {
                                                                if (item.field == 'department') {
                                                                    enumlist = modDeptList;
                                                                } else if(item.field == 'parameter') {
                                                                    enumlist = modUnitList;
                                                                } else {
                                                                    enumlist = item.enum;
                                                                }
                                                                result.push(
                                                                    <Grid item xs={4}>
                                                                        <TextField
                                                                            id="outlined-basic"
                                                                            label={label}
                                                                            name={item.field}
                                                                            value={values.key}
                                                                            error={errors[item.field] && touched[item.field]}
                                                                            onBlur={handleBlur}
                                                                            disabled={values['inspectionType'] == 'Measured' && item.field == 'value'}
                                                                            onChange={handleChange}
                                                                            margin="normal"
                                                                            type="text"
                                                                            select
                                                                            defaultValue={''}
                                                                            sx={{ display: 'flex', width: "100%" }}>
                                                                            {enumlist.map((option) => (
                                                                                <MenuItem key={option} value={option}>
                                                                                    {option}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </TextField>
                                                                    </Grid>)
                                                            } else {
                                                                result.push(
                                                                    <Grid item xs={4}>
                                                                        <TextField
                                                                            id="outlined-basic"
                                                                            label={label}
                                                                            error={errors[item.field] && touched[item.field]}
                                                                            name={item.field}
                                                                            value={values.key}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            helperText={errors[item.field] && "Incorrect entry."}
                                                                            margin="normal"
                                                                            type="text"
                                                                            sx={{ display: 'flex', width: "100%" }} />

                                                                    </Grid>)
                                                            }
                                                        }
                                                        return result;
                                                    })()}
                                                    {
                                                        values['inspectionType'] == 'Measured' && <Grid item xs={2}>
                                                            <TextField
                                                                id="outlined-basic"
                                                                label={'Green Category'}
                                                                name={'greenCategory'}
                                                                value={values.key}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                margin="normal"
                                                                type="text"
                                                                select
                                                                defaultValue={''}
                                                                sx={{ display: 'flex', width: "100%" }}>
                                                                {enumList.map((option) => (
                                                                    <MenuItem key={option} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                    }
                                                    {
                                                        values['inspectionType'] == 'Measured' &&
                                                        <Grid item xs={2}><TextField
                                                            id="outlined-basic"
                                                            label={'Green'}
                                                            name='green'
                                                            value={measuredValue.key}
                                                            onChange={(e) => {
                                                                console.log(measuredValue.key);
                                                                handleValue(values, e, 1)
                                                            }}
                                                            onBlur={handleBlur}
                                                            helperText={errors.name && "Incorrect entry."}
                                                            margin="normal"
                                                            type="text"
                                                            sx={{ display: 'flex', width: "100%" }} />
                                                        </Grid>
                                                    }
                                                    {values['inspectionType'] == 'Measured' &&
                                                        <Grid item xs={2}><TextField
                                                            id="outlined-basic"
                                                            label={'Yellow From'}
                                                            name={'yellowMin'}
                                                            value={measuredValue['yellowMinLabel']}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            helperText={errors.name && "Incorrect entry."}
                                                            margin="normal"
                                                            type="text"
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            sx={{ display: 'flex', width: "100%" }} />
                                                        </Grid>

                                                    }
                                                    {
                                                        values['inspectionType'] == 'Measured' &&
                                                        <Grid item xs={2}><TextField
                                                            id="outlined-basic"
                                                            label={'Yellow To'}
                                                            name={'yellowMax'}
                                                            value={measuredValue.key}
                                                            onChange={(e) => {
                                                                handleValue(values, e, 2)
                                                            }}
                                                            onBlur={handleBlur}
                                                            helperText={errors.name && "Incorrect entry."}
                                                            margin="normal"
                                                            type="text"
                                                            sx={{ display: 'flex', width: "100%" }} />
                                                        </Grid>

                                                    }
                                                    {
                                                        values['inspectionType'] == 'Measured' &&
                                                        <Grid item xs={2}><TextField
                                                            id="outlined-basic"
                                                            label={'Red From'}
                                                            name={'redMin'}
                                                            value={measuredValue['redMinLabel']}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            helperText={errors.name && "Incorrect entry."}
                                                            margin="normal"
                                                            type="text"
                                                            sx={{ display: 'flex', width: "100%" }} />
                                                        </Grid>
                                                    }
                                                    {
                                                        values['inspectionType'] == 'Measured' &&
                                                        <Grid item xs={2}><TextField
                                                            id="outlined-basic"
                                                            label={'Red To'}
                                                            name={'redMax'}
                                                            value={measuredValue.key}
                                                            onChange={(e) => {
                                                                handleValue(values, e, 3)
                                                            }}
                                                            onBlur={handleBlur}
                                                            helperText={errors.name && "Incorrect entry."}
                                                            margin="normal"
                                                            type="text"
                                                            sx={{ display: 'flex', width: "100%" }} />
                                                        </Grid>
                                                    }
                                                </Grid>
                                                <DialogActions>
                                                    <Button type="reset" variant="contained" color="primary" onClick={() => resetForm({ values: setInitialValues(getInitialValues()) })}
                                                        disabled={!dirty || isSubmitting}>
                                                        Reset
                                                    </Button>
                                                    <Button type="submit" variant="contained" color="primary" >
                                                        Add
                                                    </Button>
                                                </DialogActions>

                                            </form>
                                        )
                                    }}
                                </Formik>
                            }
                            {
                                isEdit && <Formik
                                    enableReinitialize
                                    key={fakecount}
                                    initialValues={editValues}
                                    onSubmit={(values, { setSubmitting }) => {
                                        setSubmitting(true);
                                        setChange(true);
                                        let data = inspectionList;
                                        var valueType, final;
                                        values['greenCategory'] == 'Greater than Equal' ? valueType = 'dc' : valueType = 'in';
                                        console.log(valueType);
                                        console.log(measuredValue['yellowMin']);
                                        console.log(measuredValue['yellowMax']);
                                        valueType == 'in' ? Number(measuredValue['yellowMax']) > Number(measuredValue['yellowMin']) ? final = true : final = false : Number(measuredValue['yellowMin']) > Number(measuredValue['yellowMax']) ? final = true : final = false
                                        valueType == 'in' ? Number(measuredValue['redMax']) > Number(measuredValue['redMin']) ? final = true : final = false : Number(measuredValue['redMin']) > Number(measuredValue['redMax']) ? final = true : final = false
                                        console.log(final, values['inspectionType']);
                                        if (final) {
                                            let green, yellow, red;
                                            green = measuredValue['green'];
                                            yellow = measuredValue['yellowMin'] + '-' + measuredValue['yellowMax'];
                                            red = measuredValue['redMin'] + '-' + measuredValue['redMax'];
                                            var index = data.findIndex(x => x._id == values['_id'])
                                            data[index].greenCategory = values['greenCategory']
                                            data[index].green = green
                                            data[index].yellow = yellow
                                            data[index].red = red
                                            data[index].yellowMax = values['yellowMax']
                                            data[index].yellowMin = values['yellowMin']
                                            data[index].redMax = values['redMax']
                                            data[index].redMin = values['redMin']
                                            setInspectionList([...data])
                                            setEdit(false);
                                        } else {
                                            toast.warning("Please enter correct values");
                                        }
                                    }
                                    }
                                    // Need to expand the validation schema more later
                                   >
                                    {props => {
                                        const {
                                            values,
                                            touched,
                                            errors,
                                            dirty,
                                            isSubmitting,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            handleReset,
                                            resetForm
                                        } = props;
                                        return (
                                            // Edit Aspect 
                                            <form onSubmit={handleSubmit}>
                                                {/*  */}
                                                <Grid container spacing={3}>

                                                    <Grid item xs={2}>
                                                        <TextField
                                                            id="outlined-basic"
                                                            label={'Green Category'}
                                                            name={'greenCategory'}
                                                            value={values['greenCategory']}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            margin="normal"
                                                            type="text"
                                                            select
                                                            defaultValue={''}
                                                            sx={{ display: 'flex', width: "100%" }}>
                                                            {enumList.map((option) => (
                                                                <MenuItem key={option} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    {
                                                        values['inspectionType'] == 'Measured' &&
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                id="outlined-basic"
                                                                label={'Green'}
                                                                name='green'
                                                                value={measuredValue['green']}
                                                                onChange={(e) => {
                                                                    handleChange(e)
                                                                    handleValue(values, e, 1)
                                                                }}
                                                                margin="normal"
                                                                type="text"
                                                                sx={{ display: 'flex', width: "100%" }} />
                                                        </Grid>
                                                    }

                                                    {values['inspectionType'] == 'Measured' &&
                                                        <Grid item xs={2}><TextField
                                                            id="outlined-basic"
                                                            label={'Yellow From'}
                                                            name={'yellowMin'}
                                                            value={measuredValue['yellowMinLabel']}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            helperText={errors.name && "Incorrect entry."}
                                                            margin="normal"
                                                            type="text"
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            sx={{ display: 'flex', width: "100%" }} />
                                                        </Grid>
                                                    }

                                                    {
                                                        values['inspectionType'] == 'Measured' &&
                                                        <Grid item xs={2}><TextField
                                                            id="outlined-basic"
                                                            label={'Yellow To'}
                                                            name={'yellowMax'}
                                                            value={measuredValue['yellowMax']}
                                                            onChange={(e) => {
                                                                handleChange(e)
                                                                handleValue(values, e, 2)
                                                            }}
                                                            onBlur={handleBlur}
                                                            helperText={errors.name && "Incorrect entry."}
                                                            margin="normal"
                                                            type="text"
                                                            sx={{ display: 'flex', width: "100%" }} />
                                                        </Grid>

                                                    }
                                                    {
                                                        values['inspectionType'] == 'Measured' &&
                                                        <Grid item xs={2}><TextField
                                                            id="outlined-basic"
                                                            label={'Red From'}
                                                            name={'redMin'}
                                                            value={measuredValue['redMinLabel']}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            helperText={errors.name && "Incorrect entry."}
                                                            margin="normal"
                                                            type="text"
                                                            sx={{ display: 'flex', width: "100%" }} />
                                                        </Grid>
                                                    }
                                                    {
                                                        values['inspectionType'] == 'Measured' &&
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                id="outlined-basic"
                                                                label={'Red To'}
                                                                name={'redMax'}
                                                                value={measuredValue['redMax']}
                                                                onChange={(e) => {
                                                                    handleChange(e)
                                                                    handleValue(values, e, 3)
                                                                }}
                                                                onBlur={handleBlur}
                                                                helperText={errors.name && "Incorrect entry."}
                                                                margin="normal"
                                                                type="text"
                                                                sx={{ display: 'flex', width: "100%" }} />
                                                        </Grid>
                                                    }
                                                </Grid>
                                                <DialogActions>
                                                    <Button type="reset" variant="contained" color="primary" onClick={() => resetForm({ values: setInitialValues(getInitialValues()) })}
                                                        disabled={!dirty || isSubmitting}>
                                                        Reset
                                                    </Button>
                                                    <Button type="submit" variant="contained" color="primary" >
                                                        Update
                                                    </Button>
                                                </DialogActions>

                                            </form>
                                        )
                                    }}
                                </Formik>
                            }
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{ textAlign: "left" }}>
                                            <TableCell>Aspect</TableCell>
                                            <TableCell>Parameter</TableCell>
                                            <TableCell>Inspection Type</TableCell>
                                            <TableCell>Inspection Basis</TableCell>
                                            <TableCell>Inspection Method</TableCell>
                                            <TableCell colSpan={2}>WO Threshold (Eg)</TableCell>
                                            <TableCell>Department</TableCell>
                                            <TableCell>Unit</TableCell>
                                            <TableCell>State</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {inspectionList.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    {row.aspect}
                                                </TableCell>
                                                <TableCell > {row.parameter}</TableCell>
                                                <TableCell > {row.inspectionType}</TableCell>
                                                <TableCell > {row.inspectionBasic}</TableCell>
                                                <TableCell > {row.type}</TableCell>
                                                <TableCell colSpan={2} style={{ flex: 'd-flex' }} >
                                                    <Button type="button" style={{ backgroundColor: 'green' }}>
                                                        {row.green}
                                                    </Button>
                                                    {
                                                        row.yellow &&
                                                        <Button type="button" style={{ backgroundColor: 'orange' }}>
                                                            {row.yellow}
                                                        </Button>
                                                    }
                                                    {
                                                        row.red &&
                                                        <Button type="button" style={{ backgroundColor: 'red' }}>
                                                            {row.red}
                                                        </Button>
                                                    }

                                                </TableCell>
                                                <TableCell > {row.department}</TableCell>
                                                <TableCell > {row.unit}</TableCell>
                                                <TableCell>
                                                    <Switch color="secondary"
                                                        id={index}
                                                        name={index}
                                                        checked={row.isActive ? true : false}
                                                        onChange={() => {
                                                            let data = inspectionList;
                                                            data[index].isActive = !row.isActive;
                                                            setInspectionList([...data])
                                                        }}
                                                    /></TableCell>
                                                <TableCell >

                                                    <IconButton onClick={() => handleEditRow(index, row)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    {!asset.isInspection && <IconButton onClick={() => handleDeleteRow(index)}>
                                                        <DeleteIcon />
                                                    </IconButton>}

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <DialogActions>
                                <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button type="button" variant="contained" color="primary" onClick={handleSave}>
                                    Save
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionCompleted && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Thanks!</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">Inspection Aspects updated</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Sorry! Update Asset Inspection Aspects Failed</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">Try Again Later</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isMessageView && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Alert Message</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">Do you wand save the Change data?</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleSave}>
                                    Yes
                                </Button>
                                <Button type="button" variant="contained" color="secondary" onClick={() => {
                                    setChange(false);
                                    setMessageView(false)
                                    handleClose()
                                }}>
                                    NO
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
            </Dialog>
        </React.Fragment>
    )

}

export default AddInspection;



// <Grid item xs={4}>
// <TextField
//     id="outlined-basic"
//     value={values.key}
//     onBlur={handleBlur}
//     onChange={handleChange}
//     margin="normal"
//     type="text"
//     select
//     defaultValue={''}
//     sx={{ display: 'flex', width: "100%" }}>
//     {enumlist.map((option) => (
//         <MenuItem key={option} value={option}>
//             {option}
//         </MenuItem>
//     ))}
// </TextField>
// </Grid>
// <Grid item xs={4}>
// <TextField
//     id="outlined-basic"
//     value={values.key}
//     onChange={handleChange}
//     onBlur={handleBlur}
//     helperText={errors.name && "Incorrect entry."}
//     margin="normal"
//     type="text"
//     sx={{ display: 'flex', width: "100%" }} />
// </Grid>