import React from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import {
    Dialog,
    Button
  } from "@mui/material";
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import { myConfig } from "../../settings.js";
import { useSelector } from 'react-redux';
import  { apiService } from '../../features/apiService.js';

const ViewTasks = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const columns = [];
    const [open, setOpen] = useState(props.open.state);
    const { user } = useSelector((state) => state.auth);
    const [taskList, setTaskList] = useState([]);
    const mlid = props.open.rowid;
    const endpoint = myConfig.getMainLibTaskEP;

    useEffect(() => {
        if (mlid != -1) {
        apiService(endpoint, {mlid:mlid})
        .then (response=> {setTaskList(response.data);
            console.log("Response->", response.data);
        })
        .catch (error => {
            console.error(error);
        }) 
        }
    }, [mlid]);

    if (taskList.length > 0) {
        for (const [key, value] of Object.entries(taskList[0])) {
            if (Array.isArray(value)) continue;
            const hdr = key.charAt(0).toUpperCase() + key.slice(1);
            columns.push({field: key, headerName: hdr , flex: 1})
        }
    }

    function handleClose() {
        //console.log("Setting open to false");
        //console.log(["show=>", show]);
        setOpen(false);
        props.open.setfn();
        //setRow(-1);
    }

    function handleClickOpen() {
        //console.log("Setting open to true");
        setOpen(true);
    }
  
    if (props.open.state & !open) {
        setOpen(true);
      }
  return (
  
    <React.Fragment>
      <Dialog
        open = {open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        width="md"
        maxWidth="true"
        >
    
      { taskList.length > 0 && (
        <React.Fragment>
           
      <Header title="Task List" subtitle="" />
      <Box
        m="20px 0 0 0"
      >
     
        <DataGrid  
            rows={taskList} 
            columns={columns} 
            getRowId ={(row) => row.name} 
            
        />
        <Button  variant="contained" type="button" onClick={handleClose}>
            Back
        </Button>
        </Box>
        </React.Fragment>
      )}
      {taskList.length == 0 && (
        <React.Fragment>
        <Box m="20px">
          <h3>No Tasks Configured/Retrieved</h3>
        <Button type="button" variant="contained" color="primary" onClick={handleClose}>
            Back
        </Button>
        </Box>
        </React.Fragment>
    )}
        </Dialog>
    
  
  </React.Fragment>
  );
}

export default ViewTasks;
