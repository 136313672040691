import axios from "axios";
import { logout, reset } from '../features/auth/authSlice';
import { redirect } from "react-router-dom";
import { store } from "../app/store";


// export const apiService =  async (endPoint, data, user) => {
export const apiService =  async (endPoint, data=null) => {
    const luser = JSON.parse(localStorage.getItem('user'));
    let commondata = {'tenantid': luser.tenantid }
    console.log("calling the API ->", endPoint);
    const config = {
        headers: {
          'Access-Control-Allow-Origin': true,          
          authorization: `Bearer ${luser["token"]}`,
          facility: localStorage.getItem('facility'),
        },
      };
    
    if (data) {
      //const fulldata = {...commondata, ...data};
      const rpromise = axios.post(endPoint, data, config)
        .catch (error => {
          console.log("Error->", error);
          if (error.response.status === 401) {
            console.log("Auth 401 Error -Logout & redirect to login screen");
            store.dispatch(logout());
            store.dispatch(reset());
            redirect("/");
          }
          throw error;
        }) 
        return rpromise;
    } else {
      const rpromise = axios.get(endPoint, config)
        .catch (error => {
          if (error.response.status === 401) {
            console.log("Auth 401 Error -Logout & redirect to login screen");
            store.dispatch(logout());
            store.dispatch(reset());
            redirect("/");
          }
          throw error;
       })
       return rpromise;
    }
  
}


export const apiService2 = async (type, endPoint, data,) => {
  //const navigate = useNavigate()
  //const dispatch = useDispatch()
  //const dispatch = localStorage.getDispatch();
  const luser = JSON.parse(localStorage.getItem('user'));
  const config = {
    headers: {
      'Access-Control-Allow-Origin': true,
      Authorization: `Bearer ${luser["token"]}`,
      facility: localStorage.getItem('facility'),
    },
  }
  if (type == 'POST') {
    const rpromise = await axios.post(endPoint, data, config)
      //.then ( response=>  {return response;})
      .catch(error => {
        if (error.response.status == 401) {
          //Session TokenExpiredError, logout
          console.log("Auth 401 Error -Logout & redirect to login screen");
          store.dispatch(logout());
          store.dispatch(reset());
          redirect("/");
          //throw "LOGOUT"; 
        }
      })
    return rpromise;
  } else if(type == 'GET'){
    console.log(endPoint);
    const rpromise = await axios.get(endPoint, config)
      //.then ( response=>  {return response;})
      .catch(error => {
        if (error.response.status == 401) {
          //Session TokenExpiredError, logout
          console.log("Auth 401 Error -Logout & redirect to login screen");
          store.dispatch(logout());
          store.dispatch(reset());
          redirect("/");
          //throw "LOGOUT"; 
        }
      })
      console.log(rpromise);
    return rpromise;
  }
}

// With out token and facility
export const apiService3 = async (type, endPoint, data,) => {
  //const navigate = useNavigate()
  //const dispatch = useDispatch()
  //const dispatch = localStorage.getDispatch();
  const luser = JSON.parse(localStorage.getItem('user'));
  const basicAuth = 'Basic ' + btoa('arvam:DAF87DSFDSFDSA98FSADKJE324KJL32HFD7FDSFB24343J49DSF')
  const config = {
    headers: {
      'Access-Control-Allow-Origin': true,
      'Content-Type': 'application/json',
      'Authorization' : basicAuth,
    },
  }
  if (type == 'POST') {
    const rpromise = await axios.post(endPoint, data, config)
      //.then ( response=>  {return response;})
      .catch(error => {
        console.log("Error->", error);
        if (error.response.status == 401) {
          //Session TokenExpiredError, logout
          console.log("Auth 401 Error -Logout & redirect to login screen");
          store.dispatch(logout());
          store.dispatch(reset());
          redirect("/");
          //throw "LOGOUT"; 
        }
      })
    return rpromise;
  } else if(type == 'GET'){
    console.log(endPoint);
    const rpromise = await axios.get(endPoint, config)
      //.then ( response=>  {return response;})
      .catch(error => {
        if (error.response.status == 401) {
          //Session TokenExpiredError, logout
          console.log("Auth 401 Error -Logout & redirect to login screen");
          store.dispatch(logout());
          store.dispatch(reset());
          redirect("/");
          //throw "LOGOUT"; 
        }
      })
      console.log(rpromise);
    return rpromise;
  }
}