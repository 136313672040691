import { MaterialReactTable } from "material-react-table";
import { table, tokens } from "../../theme";
import { useTheme } from "@mui/material";


export const tableView = (isPagination, columns, dataList, setRowSelection, rowSelection, rowId, isRowAction = false, renderRowActions, grouping = null, isRefetching, pagination = false, setPagination = null, sort = null, setSorting = null, rowCount, columnFilters, setColumnFilters, globalFilter, setGlobalFilter) => {
    const mode  = localStorage.getItem('mode');
    return (
        <>
            {isPagination ? <MaterialReactTable
                columns={columns}
                data={dataList}
                enablePagination={true}
                enableMultiRowSelection={false}
                enableRowSelection={isRowAction ? false : true}
                getRowId={(row) => row[rowId]} //give each row a more useful id
                enableStickyFooter
                enableRowActions={isRowAction}
                renderRowActions={isRowAction ? renderRowActions : null}
                initialState={{
                    density: 'compact',
                    expanded: true, //expand all groups by default
                    grouping: grouping ? [grouping] : null,
                    sorting: sort ? [sort] : [{ id: 'cdate', desc: false }], //sort by state by default
                }}
                manualFiltering={pagination ? true : false}
                manualSorting={true}
                muiTopToolbarProps={{
                    sx: {
                        backgroundColor: mode === 'dark' ? table.dark.backgroundColor : table.light.backgroundColor,
                    }
                }}
                muiTableHeadCellProps={{
                    sx: {
                        fontSize: '16px',
                        backgroundColor:  mode === 'dark' ?  table.dark.backgroundColor : table.light.backgroundColor,
                    }
                }}
                muiTableBodyCellProps={{
                    sx: {
                        fontSize: '16px',
                        backgroundColor:  mode === 'dark' ?  table.dark.backgroundColor : table.light.backgroundColor,
                    },
                }}
                muiBottomToolbarProps={{
                    sx: {
                        backgroundColor: mode === 'dark' ? table.dark.backgroundColor : table.light.backgroundColor,
                    }
                }}
                onGlobalFilterChange={setGlobalFilter}
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{
                    rowSelection,
                    columnFilters,
                    pagination,
                    globalFilter,
                    sort,
                    showProgressBars: isRefetching
                }} //pass our managed row selection state to the table to use
                cell={(value) => String(value)}
                muiSelectCheckboxProps={{ color: 'primary' }}
                manualPagination={true}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={rowCount}
                enableColumnFilterModes={true}
                onColumnFiltersChange={setColumnFilters}
            /> : <MaterialReactTable
                columns={columns}
                data={dataList}
                enablePagination={true}
                enableGrouping={grouping ? true : false}
                enableMultiRowSelection={false}
                enableRowSelection={isRowAction ? false : true}
                getRowId={(row) => row[rowId]}  //give each row a more useful id
                enableRowActions={isRowAction}
                renderRowActions={isRowAction ? renderRowActions : null}
                enableStickyFooter
                initialState={{
                    density: 'compact',
                    expanded: true, //expand all groups by default
                    grouping: grouping ? [grouping] : null,
                    pagination: pagination ? pagination : { pageIndex: 0, pageSize: 10 },
                    sorting: sort ? [sort] : [{ id: 'cdate', desc: false }], //sort by state by default
                }}
                manualFiltering={pagination ? true : false}
                manualSorting={true}
                muiTopToolbarProps={{
                    sx: {
                        backgroundColor: mode === 'dark' ? table.dark.backgroundColor : table.light.backgroundColor,
                    }
                }}
                muiTableHeadCellProps={{
                    sx: {
                        fontSize: '16px',
                        backgroundColor:  mode === 'dark' ?  table.dark.backgroundColor : table.light.backgroundColor,
                    }
                }}
                muiTableBodyCellProps={{
                    sx: {
                        fontSize: '16px',
                        backgroundColor:  mode === 'dark' ?  table.dark.backgroundColor : table.light.backgroundColor,
                    },
                }}
                muiBottomToolbarProps={{
                    sx: {
                        backgroundColor: mode === 'dark' ? table.dark.backgroundColor : table.light.backgroundColor,
                    }
                }}
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{
                    rowSelection,
                }} //pass our managed row selection state to the table to use
                cell={(value) => String(value)}
                muiSelectCheckboxProps={{ color: 'primary' }}
            />
            }
        </>
    )
};