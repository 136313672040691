import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import PersonIcon from '@mui/icons-material/Person';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import ErrorIcon from '@mui/icons-material/Error';
import HandymanIcon from '@mui/icons-material/Handyman';
import { myConfig } from "../../settings.js";

import { apiService } from '../../features/apiService';
const assetMaintStates = ["Faulty", "Breakdown"];

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [teamcount, setTeamCount] = useState(0);
  const [assetcount, setAssetCount] = useState(0);
  const [assetInMaintCount, setAssetInMaint] = useState(0);
  const [assetNotOperCount, SetAssetNotOper] = useState(0);
  const [openWOCount, setOpenWOCount] = useState(0);
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate()
  const dispatch = useDispatch()


  //const { isLoading, isError, message } = useSelector(
  // (state) => state.goals
  //)
  /*
   useEffect(() => {
     //if (isError) {
     //  console.log(message)
     //}
     console.log("User->", user);
     if (!user) {
       navigate('/login')
     }
 
     //dispatch(getGoals())
 
     return () => {
       dispatch(reset())
     }
   }, [user])
 
 */
  // Get total no of users
  useEffect(() => {
    apiService(myConfig.getUserCountEP)
      .then(response => { setTeamCount(response.data['count']) })
      .catch(error => {
        console.error(error);
      })
  }, []);

  // Check for Assets in Maintenance
  function findAssetinMaint(assetList) {
    let assetMaintCount = 0;
    let assetNotOper = 0;
    assetList.forEach((asset) => {
      if (assetMaintStates.indexOf(asset.state) >= 0) {
        assetMaintCount += 1;
      }
      if (asset.state !== "Operational" && asset.state !== "Uncommissioned") {
        assetNotOper += 1;
      }
    });
    setAssetInMaint(assetMaintCount);
    SetAssetNotOper(assetNotOper);
  }

  //Get total no of assets
  useEffect(() => {
    apiService(myConfig.getAssetsEP)
      .then(response => {
        setAssetCount(response.data.length);
        findAssetinMaint(response.data);
        console.log("Response->", response);
      })
      .catch(error => {
        console.error(error);
      })
  }, []);

  //Get total open workorders
  useEffect(() => {
    apiService(myConfig.getWOOpenCountEP)
      .then(response => {
        setOpenWOCount(response.data.count);
        //findAssetinMaint(response.data);
        console.log("Response->", response);
      })
      .catch(error => {
        console.error(error);
      })
  }, []);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Dashboard" />
        <Box>
          <Button variant="contained" color="primary" >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="10px"
      >
        {/* ROW 1 */}
        <Box gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          className="box">
          <StatBox
            title={assetcount}
            subtitle="Assets"
            progress="-1"
            icon={
              <WebAssetIcon  sx={{
                fontSize: "46px",
              }} />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          className="box">
          <StatBox
            title={assetInMaintCount}
            subtitle="Under Maintenance"
            progress="-1"
            icon={
              <HandymanIcon sx={{
                fontSize: "46px",
              }} />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          className="box">
          <StatBox
            title={assetNotOperCount}
            subtitle="Assets: Not Operational"
            progress="-1"
            icon={
              <ErrorIcon sx={{
                fontSize: "46px",
              }} />
            }
          />
        </Box>
        <Box gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          className="box">
          <StatBox
            title={assetcount}
            subtitle="Assets"
            progress="-1"
            icon={
              <WebAssetIcon sx={{
                fontSize: "46px",
              }}/>
            }
          />
        </Box>
        
 

        {/* ROW 2 */}
        <Box
          gridColumn="span 4"
          //gridRow="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={openWOCount}
            subtitle="WorkOrders"
            progress="0.75"
            increase="+14%"
            icon={
              <WebAssetIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="4"
            subtitle="Maintenance vs Dept"
            progress="0.50"
            increase="+21%"
            icon={
              <HandymanIcon
                sx={{ color: colors.redAccent[100], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 3 */}

        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="4"
            subtitle="Maintenance vs Dept"
            progress="0.50"
            increase="+21%"
            icon={
              <HandymanIcon
                sx={{ color: colors.redAccent[100], fontSize: "26px" }}
              />
            }
          />
        </Box>
        {/* ROW 3 */}
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Productivity
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                200 Days
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Recent Transactions
            </Typography>
          </Box>
          {mockTransactions.map((transaction, i) => (
            <Box
              key={`${transaction.txId}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.txId}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.user}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.date}</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                ${transaction.cost}
              </Box>
            </Box>
          ))}
        </Box>

        {/* ROW 3 */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography variant="h5" fontWeight="600">
            Campaign
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle size="125" />
            <Typography
              variant="h5"
              color={colors.greenAccent[500]}
              sx={{ mt: "15px" }}
            >
              $48,352 revenue generated
            </Typography>
            <Typography>Includes extra misc expenditures and costs</Typography>
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Sales Quantity
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          padding="30px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}
          >
            Geography Based Traffic
          </Typography>
          <Box height="200px">
            <GeographyChart isDashboard={true} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
