import { Box, Typography, useTheme, Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog, IconButton, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import KeyIcon from '@mui/icons-material/Key';
import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify'
import { myConfig } from "../../settings.js";
import { useSelector } from 'react-redux';
import { apiService, apiService2 } from '../../features/apiService';
import { MaterialReactTable } from "material-react-table";
import { resourceModel } from "../../schema/schema";
import AddResource from "./addresource";
import EditResource from "./editresource";
import TradeCertification from "./tradecer";
import ViewAccessControl from "./accesscontrol";

import { getAccess } from "../../features/helpers/authhelper";
import { Person } from "@mui/icons-material";
import { timeZoneConverter, timeZoneConverterDate } from "../../features/helpers/commonhelper.js";
import { tableView } from "../../features/helpers/table.js";
const Resource = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const columns = [];

    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openTC, setOpenTC] = useState(false);
    const [openViewAccess, setViewAcess] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [resourceList, setResourceList] = useState([]);
    const [resource, setResource] = useState({});
    const { user } = useSelector((state) => state.auth);
    const [selectedrow, setRow] = useState(-1);
    const [rowSelection, setRowSelection] = useState({});
    const [message, setMessage] = useState('');
    const [disableMsg, setDisableMsg] = useState('Disable');
    const access = getAccess(user, 'setup');
    const access2 = getAccess(user, 'security');


    for (const item of resourceModel) {
        if (item.hasOwnProperty("skip")) {
            if (item.skip) continue;
        }
        columns.push({ accessorKey: item['field'], header: item['label'], size: 150 });
    }

    useEffect(() => {
        apiService2('GET', myConfig.getResource)
            .then(response => {
                var resourceList = response.data;
                resourceList?.forEach(res => {
                    res.dob = timeZoneConverterDate(res.dob)
                    res.isActive = res.isActive ? "Active" : "Inactive"
                    res.cdate = timeZoneConverter(res.createdOn)
                })
                setResourceList(resourceList);
            })
            .catch(error => {
                console.error(error);
            });
    }, [refresh]);

    useEffect(() => {
        const wrnumber = Object.keys(rowSelection)[0];
        const selected = Object.values(rowSelection)[0];
        let index = 0;
        if (selected) {
            for (const temp of resourceList) {
                if (temp._id == wrnumber) {
                    setResource(temp);
                    console.log("Resource->", temp);
                    if (temp.isActive == 'Active') {
                        setDisableMsg('Disable')
                    } else {
                        setDisableMsg('Enable')
                    }
                    setRow(index);
                    break;
                }
                ++index;
            }
        } else {

            setResource({});
        }
    }, [rowSelection]);

    function handleClose() {
        setOpen(false);
        setOpenEdit(false)
        setOpenDelete(false)
        setOpenTC(false);
        setViewAcess(false);
        setRefresh(!refresh);
        setResource({});
    }

    function handleClickOpen() {
        setOpen(true);
        setRefresh(false);
    }

    function handleDelete() {
        var isActive = resource.isActive == 'Active' ? false : true;
        apiService2('POST', myConfig.deleteResource, { "_id": resource._id, isActive: isActive, message: message })
            .then(resp => {
                handleClose();
            })
            .catch(error => {
                console.log(["Resource Add Failed->", error]);
                handleClose();
            });
    }

    function CustomToolbar() {
        return (
            <Box className="mb-1">
                {
                    access.isCreate && <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleClickOpen}>
                        Add
                    </Button>
                }
            </Box>
        );
    }
    return (
        <Box m="20px 20px 20px 20px">
            {/* <Header title="Resource" subtitle="" /> */}
            <Box height="70vh">
                {CustomToolbar()}
                {tableView(
                    false, columns, resourceList, setRowSelection,
                    rowSelection, '_id',
                    true,
                    ({ row, table }) => [
                        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                            {
                                access.isEdit && <Tooltip title="Edit"> <IconButton
                                    color="secondary"

                                    onClick={() => {
                                        setResource({ ...row.original })
                                        setOpenEdit(true);
                                    }}>
                                    <EditIcon />
                                </IconButton> </Tooltip>
                            }
                            {
                                access.isDeleted && <Tooltip title="Delete"> <IconButton
                                    color="error"
                                    onClick={() => {
                                        setResource({ ...row.original })
                                        setOpenDelete(true);
                                    }}>
                                    <DeleteIcon />
                                </IconButton> </Tooltip>
                            }
                            {
                                access.isDeleted && <Tooltip title="User Enable and Disable"><IconButton
                                    onClick={() => {
                                        console.log("Row-> call", row.original.isActive);
                                        setResource({ ...row.original })
                                        if (row.original.isActive == 'Active') {
                                            setMessage("Disable");
                                        } else {
                                            setMessage("Enable");
                                        }
                                        setOpenDelete(true);
                                    }}>
                                    {row.original.isActive == 'Active' ? <Person /> : <PersonOffIcon />}
                                </IconButton></Tooltip>
                            }
                            {
                                access.isCreate && <Tooltip title="Upload File"> <IconButton
                                    onClick={() => {
                                        setResource({ ...row.original });
                                        setOpenTC(true);
                                    }}>
                                    <UploadFileIcon />
                                </IconButton></Tooltip>
                            }
                            {
                                access.isCreate && <Tooltip title="Access Control"> <IconButton
                                    onClick={() => {
                                        setResource({ ...row.original });
                                        setViewAcess(true);
                                    }}>
                                    <KeyIcon />
                                </IconButton></Tooltip>
                            }

                        </Box>
                    ])
                }
                <AddResource open={{ state: open, setfn: handleClose }} />
                <EditResource open={{ state: openEdit, setfn: handleClose, resource: resource }} />
                <TradeCertification open={{ state: openTC, setfn: handleClose, resource: resource }} />
                <ViewAccessControl open={{ state: openViewAccess, setfn: handleClose, resource: resource }} />
                <Dialog
                    open={openDelete}
                    fullWidth={true}
                    maxWidth={'xs'}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title">
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">{message} Resource</DialogTitle>

                        <DialogContent>
                            <DialogContentText variant="h6">Are you sure you want to {message}?</DialogContentText>
                            <DialogActions sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}>
                                <Button type="button" variant="contained" color="secondary" onClick={handleDelete}>
                                    Confirm
                                </Button>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                </Dialog>
            </Box>
        </Box>
    );
};

export default Resource;
