import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";

const DeleteDialog = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(props.open.state);
    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');

    if (props.open.state & !open) { 
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
        props.open.setfn(status);
    }

    function handleDelete() {
        setOpen(false);
        props.open.setdl(status);
    }
    return (
        <React.Fragment>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'xs'}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                <React.Fragment>
                    <DialogTitle id="form-dialog-title">Delete {message}</DialogTitle>

                    <DialogContent>
                        <DialogContentText variant="h6">Are you sure you want to delete?</DialogContentText>
                        <DialogActions sx={{
                            
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}>
                            <Button type="button" variant="contained" color="primary" onClick={handleDelete}>
                                Confirm
                            </Button>
                            <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </React.Fragment>
            </Dialog>
        </React.Fragment>);
}

export default DeleteDialog;