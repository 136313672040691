import React, { useState, useEffect } from "react";
import { Grid, MenuItem, TextField, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { myConfig } from "../../settings";
import { useSelector } from 'react-redux';
import { apiService2 } from "../../features/apiService";
// API End Point
const endPoint = myConfig.addDeptEP;
let fakecount = 0;
const StockTransfer = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(props.open.state);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [isSubmitionError, setSubmitionError] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const [errorMessage, setErrorMessage] = useState('');
    const [storeConfigList, setStoreConfigList] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const [storeConfig, setStoreConfig] = useState({});
    const [quantity, setQuantity] = useState('');


    if (props.open.state & !open) {
        setOpen(true);
        setStoreConfig('');
        setQuantity('');
    }

    useEffect(() => {
        apiService2('GET', myConfig.getStoreConfigEP)
            .then(response => {
                var storeConfigList = response.data;
                setStoreConfigList(storeConfigList);
            })
            .catch(error => {
                console.error(error);
            });
    }, [open]);

    let modStoreConfigList = [];
    for (const cont of storeConfigList.filter((x) => x.type == 'Sub Store')) {
        modStoreConfigList.push(cont.name);
    }

    // Handle States for the dialog box
    function handleClose() {
        setOpen(false);
        setSubmitionError(false);
        setSubmitionCompleted(false);
        props.open.setfn();
    }

    function handleTryAgain() {
        setSubmitionError(false);
        setErrorMessage('');
    }

    function handleSelect() {

    }


    return (
        <React.Fragment>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'xs'}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                {!isSubmitionCompleted && !isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Stock  Transfer</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6"></DialogContentText>
                            <Formik
                                key={fakecount}
                                enableReinitialize
                                initialValues={initialValues}
                                validationSchema={Yup.object().shape({
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(true);
                                    let custore = storeConfigList.find((x) => x.name === storeConfig);
                                    let mainStore = props.open.store
                                    if (quantity <= mainStore.quantity) {
                                        values['store'] = custore._id;
                                        values['quantity'] = quantity;
                                        values['mainStore'] = mainStore._id;
                                        values['itemname'] = mainStore.itemname;
                                        values['itemcode'] = mainStore.itemcode;
                                        values['unit'] = mainStore.unit;
                                        apiService2('POST', myConfig.transferStoreEP, values)
                                            .then(resp => {
                                                if (resp.data.message) {
                                                    setErrorMessage(resp.data.message);
                                                    setSubmitionError(true);
                                                } else {
                                                    setSubmitionCompleted(true);
                                                }
                                            })
                                            .catch(error => {
                                                console.log(["StoreConfig Add Failed->", error]);
                                                setSubmitionError(true);
                                                setSubmitionCompleted(true);
                                            });
                                    } else {
                                        setErrorMessage('Quantity is greater than available stock');
                                        setSubmitionError(true);
                                        return;
                                    }

                                }}
                            >
                                {props => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        dirty,
                                        isSubmitting,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        handleReset,
                                        resetForm,
                                        setFieldValue
                                    } = props;
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <Grid container spacing={2}>
                                                <Grid item sm={6}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label={'Store'}
                                                        name={'redMax'}
                                                        value={storeConfig}
                                                        onChange={(e) => {
                                                            setStoreConfig(e.target.value)
                                                        }}
                                                        onBlur={handleBlur}
                                                        helperText={errors.name && "Incorrect entry."}
                                                        margin="normal"
                                                        type="text"
                                                        select
                                                        defaultValue={''}
                                                        sx={{ display: 'flex', width: "100%" }}>
                                                        {modStoreConfigList.map((option) => (
                                                            <MenuItem key={option} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                                <Grid item sm={6}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label={'Quantity'}
                                                        name={'quantity'}
                                                        value={quantity}
                                                        onChange={(e) => {
                                                            setQuantity(e.target.value)
                                                        }}
                                                        onBlur={handleBlur}
                                                        helperText={errors.name && "Incorrect entry."}
                                                        margin="normal"
                                                        type="text"
                                                        sx={{ display: 'flex', width: "100%" }} />
                                                </Grid>
                                            </Grid>
                                            <DialogActions>
                                                <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                                                    Submit
                                                </Button>
                                            </DialogActions>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionCompleted && !isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title"></DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">Stock Transfered Successfully</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Sorry! Add Store Config Failed</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6"> {errorMessage ? errorMessage : 'Try Again Later'}</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleTryAgain}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
            </Dialog>
        </React.Fragment>
    );
}

export default StockTransfer;