import * as React from "react";

import { useState, useEffect, useMemo } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TableViewIcon from '@mui/icons-material/TableView';
import { useDispatch } from 'react-redux';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { toast } from 'react-toastify';
import AddInspection from "./inspection";
import AssetView from "./assetview";
import AssetEdit from "./assetedit";
import { myConfig } from "../../settings.js";
import AddAsset from "./assetadd";
import UpdateAssetParam from "./assetparam";
import { apiService } from '../../features/apiService';
import AddMeter from "./meter";
import { getAccess } from "../../features/helpers/authhelper";
import { tableView } from "../../features/helpers/table.js";
import { timeZoneConverter } from "../../features/helpers/commonhelper.js";
const Assets = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const [clickedIndex, setClickedIndex] = React.useState(-1);
  const [open, setOpen] = useState(false);
  const [vopen, setViewOpen] = useState(false);
  const [eopen, setEditOpen] = useState(false);
  const [openassetparam, setAssetParamOpen] = useState(false);
  const [openInspection, setInspection] = useState(false);
  const [openM, setMeterOpen] = useState(false);
  const [openInspect, setInspectOpen] = useState(false);
  const [assetlist, setAsset] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [selectedrow, setRow] = useState(-1);
  const [treeAssetList, setTree] = useState([]);
  const [tooltipContent, setTooltipContent] = useState('Initial tooltip content');
  //const dispatch = useDispatch();
  //const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.hierarchy;
  // const fakecount = 0;
  const [rowSelection, setRowSelection] = useState({});
  const [asset, setAssets] = useState({});
  const access = getAccess(user, 'asset');

  //console.log('access', access);
  //console.log('user', user);


  // Get all the Assets 
  useEffect(() => {
    apiService(myConfig.getAssetsEP)
      .then(response => {
        setAsset(response.data);
        //console.log("Response->", response);
      })
      .catch(error => {
        console.error("Received Error->", error);
      })
  }, [refresh]);


  useEffect(() => {
    let tree = [], arrayDictionary = {};

    // First map the nodes of the array to an object/dictionary where the key is their id
    assetlist?.forEach((asset) => {
      arrayDictionary[asset.assetid] = asset;
      arrayDictionary[asset.assetid]["subRows"] = [];
    });
    // for each entry in the dictionary
    for (var entry in arrayDictionary) {
      // get all the data for this entry in the dictionary
      const mappedElem = arrayDictionary[entry];
      // console.log('mappedElem',mappedElem);
      // console.log(arrayDictionary[mappedElem["parentassetid"]]);
      // if the element has a parent, add it
      if (
        mappedElem.parentassetid && // the dictionary has a parent
        arrayDictionary[mappedElem["parentassetid"]] // and that parent exists
      ) {
        arrayDictionary[mappedElem["parentassetid"]]["subRows"].push(mappedElem);
      }
      // else is at the root level (parentid = null or 0)
      else {
        tree.push(mappedElem);
      }
    }
    setTree(tree);
  }, [assetlist]);

  // Get the selected Asset 
  useEffect(() => {
    console.log("rowsel->", rowSelection);
    const assetid = Object.keys(rowSelection)[0];
    const selected = Object.values(rowSelection)[0];
    let index = 0;
    if (selected) {
      for (const tempasset of assetlist) {
        if (tempasset.assetid == assetid) {
          // setAsset(tempasset);
          setRow(index);
          setAssets(tempasset)
          break;
        }
        ++index;
      }
    } else {

      //setAsset({});
    }
  }, [rowSelection]);

  function handleClose() {
    //console.log("Setting open to false");
    //console.log(["show=>", show]);
    setOpen(false);
    setViewOpen(false);
    setRefresh(!refresh);
    setAssetParamOpen(false);
    setInspection(false);
    setRowSelection({});
    setViewOpen(false);
    setEditOpen(false);
    setMeterOpen(false)
    setInspectOpen(false);
    //setRow(-1);
  }

  function handleCloseInspection() {
    setInspectOpen(false);
  }

  function handleClickOpen() {
    //console.log("Setting open to true");
    setOpen(true);
    setRefresh(!refresh);
  }

  function handleClickView() {
    if (selectedrow != -1) {
      setViewOpen(true);
      //console.log("Setting view open to true");
    } else toast.warning("Select a row/asset first ");
  }

  // Handle the asset 
  function handleClickEdit() {
    //toast.warning("** UNDER DEVELOPMENT **");
    //return;
    if (selectedrow != -1) {
      if (asset.state != 'Decommissioned') {
        setEditOpen(true);
      } else {
        toast.warning("Asset is Decommissioned, cannot be edited");
      }

      //console.log("Setting view open to true");
    } else toast.warning("Select a row/asset first ");
  }

  // Handle asset paramters update
  function handleUpdateParams() {
    //console.log("Selected Row->", selectedrow);
    if (selectedrow != -1) { setAssetParamOpen(true); }
    else toast.warning("Select the Asset first..");
  }

  function handleAddInspection() {
    //console.log("Selected Row->", selectedrow);
    if (selectedrow != -1) {

      if (asset.state != 'Decommissioned') {
        setInspection(true);
      } else {
        toast.warning("Asset is Decommissioned, cannot be Metered");
      }
    }
    else toast.warning("Select the Asset first..");
  }

  const handleRowClick = (param, event) => {
    navigate("assetview", { state: param['row'] });
  };

  function handleDeleteAsset() {
    toast.warning("Not Implemented - Under Development")
  }

  function handleAddMeterValue() {
    if (selectedrow != -1) {
      if (asset.state != 'Decommissioned') {
        setMeterOpen(true);
      } else {
        toast.warning("Asset is Decommissioned, cannot be Metered");
      }
    } else toast.warning("Select the Asset first..");

  }

  function viewAsept(value) {
    console.log('value', value);
    setInspectOpen(true);
    setAssets(value);
  }

  function CustomToolbar() {
    return (
      <Box className="mb-1">
        {
          access.isCreate && <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleClickOpen}>
            Add
          </Button>
        }
        {
          access.isCreate && <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddInspection}>
            Add/View Aspect
          </Button>
        }
        {
          access.isEdit && <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddMeterValue}>
            Meter Update
          </Button>
        }
        {
          access.isEdit && <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={handleClickEdit}>
            Edit
          </Button>
        }
        <Button variant="contained" color="primary" startIcon={<TableViewIcon />} onClick={handleClickView}>
          View
        </Button>
      </Box>
    );
  }

  const columns = useMemo(() => [
    {
      accessorKey: "name",
      header: "Name",

    },
    {
      accessorKey: "assetid",
      header: "Asset ID",
    },
    {
      accessorKey: "assettype",
      header: "Asset Type",
    },
    {
      accessorKey: "state",
      header: "Asset State",
      Cell: ({ cell }) => {
        return (
          <Box
            component={"span"}
            sx={{
             
              display: "flex",
              padding: "5px",
              textAlign: "center",
            }}>
            {cell?.row?.original?.state}
            <Box sx={{
              backgroundColor: cell?.row?.original?.state == 'Operational' ? 'green' :
                cell?.row?.original?.state == 'Corrective' || cell?.row?.original?.state == 'Faulty' ? 'yellow' :
                  cell?.row?.original?.state == 'Breakdown' ? 'red' :
                    cell?.row?.original?.state == 'Suspended' ? 'blue' : 'grey',
              borderRadius: "50%",
              padding: "5px",
              width: "15px",
              height: "15px",
              marginLeft: "5px"
            }}>
            </Box>
          </Box>
        );
      }
    },
    {
      accessorKey: "inspectionState",
      header: "Inspection State",
      Cell: ({ cell }) => {
        return (
          <>
            <Box
              component={"span"}
              data-tip data-for="dynamicTooltip"
              onClick={() => viewAsept(cell?.row?.original)}
              sx={{
                color: 'white',
                display: "flex",
                padding: "5px",
                textAlign: "center",
              }}>
              {/* {cell?.row?.original?.inspectionState} */}
              {/* <Tooltip title={{cell?.row?.original?.inspectionState }}> */}
              <Box sx={{
                backgroundColor: cell?.row?.original?.inspectionList.length > 0 ? cell?.row?.original?.inspectionState == 'Green' ? 'green' :
                  cell?.row?.original?.inspectionState == 'Yellow' ? 'yellow' : 'red' : 'grey',
                borderRadius: "50%",
                padding: "5px",
                width: "15px",
                height: "15px",
                marginLeft: "5px"
              }}>
              </Box>
            </Box>
          </>
        );
      }
    },
    {
      accessorKey: "assetclass",
      header: "Asset Class",
    },
    {
      accessorKey: "family",
      header: "Asset Family",
    },
    {
      accessorKey: "section",
      header: "Section",
    },
    {
      accessorKey: "subsection",
      header: "Sub-Section",
    },
    {
      accessorKey: "parentassetid",
      header: "Parent Asset ID",
    },
  ]);
  return (
    <Box m="20px">
      <Header
        title="Assets"
        subtitle="Configure Assets"
      />
      <Box
        m="40px 0 0 0"
        height="70vh">
        <CustomToolbar />
        {tableView(false, columns, treeAssetList, setRowSelection, rowSelection, 'assetid', null)}
        <AddAsset open={{ state: open, setfn: handleClose }} />
        <AssetView open={{ state: vopen, setfn: handleClose, rowid: selectedrow, asset: asset }}></AssetView>
        <AssetEdit open={{ state: eopen, setfn: handleClose, rowid: selectedrow, asset: asset }}></AssetEdit>
        <UpdateAssetParam open={{ state: openassetparam, setfn: handleClose, rowid: selectedrow }} />
        <AddInspection open={{ state: openInspection, setfn: handleClose, rowid: selectedrow, asset: asset }}></AddInspection>
        <AddMeter open={{ state: openM, setfn: handleClose, rowid: selectedrow, asset: asset }} />
        <Dialog
          open={openInspect}
          onClose={handleClose}
          maxWidth={'xs'}
          aria-labelledby="form-dialog-title"
          fullWidth>
          <DialogTitle id="form-dialog-title">Aspect Values</DialogTitle>
          <DialogContent>
            <DialogContentText variant="h6">
              {asset?.lastinspection?.length > 0 ? <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Aspect</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    asset?.lastinspection?.map((aspect, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{aspect.aspect}</TableCell>
                          <TableCell>
                            <Box
                              component={"span"}
                              sx={{
                                display: "flex",
                                padding: "5px",
                                textAlign: "center",
                              }}>
                              {aspect.value}
                              <Box sx={{
                                backgroundColor: aspect?.beachLevel == 'yellow' ? 'yellow' :
                                  (aspect?.beachLevel == 'Red' || aspect?.beachLevel == 'red') ? 'red' : 'green',
                                borderRadius: "50%",
                                padding: "5px",
                                width: "15px",
                                height: "15px",
                                marginLeft: "15px"
                              }}>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>{timeZoneConverter(aspect.date)}</TableCell>
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              </Table> : asset?.inspectionList?.length > 0 ? "No Aspect Values Found" : "No Inspection Found"}
            </DialogContentText>
            <DialogActions>
              <Button type="button" variant="contained" color="secondary" onClick={handleCloseInspection}>
                Back to app
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Assets;
