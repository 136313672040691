import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, MenuItem, TextField } from "@mui/material";
import { apiService } from "../../features/apiService";
import { myConfig } from "../../settings";
import { toast } from "react-toastify";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from "dayjs";
const UpdateInspection = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(props.open.state);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [maxWidth, setMaxWith] = useState('sm');
    const [isSubmitionError, setSubmitionError] = useState(false);
    const { user } = useSelector((state) => state.auth);
    var date = new Date();
    let from = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
    const [fromDate, setFromDate] = useState(dayjs(from));
    const [toDate, setToDate] = useState(dayjs(from));
    const [suspensionReason, setReason] = useState();

    function handleClose() {
        setOpen(false);
        setSubmitionError(false);
        setSubmitionCompleted(false);
        props.open.setfn();
    }

    if (props.open.state & !open) {
        setOpen(true);
        setMaxWith('sm');
    }

    function handleRetry() {
        setSubmitionError(false);
        setSubmitionCompleted(false);
    }

    function handleUpdate() {
        const inspection = props.open.inspection;
        if(suspensionReason === undefined && suspensionReason === '' && suspensionReason.length > 0){
        inspection['suspendedfrom'] = fromDate.toDate();
        inspection['suspendedto'] = toDate.toDate();
        inspection['suspensionreason'] = suspensionReason;
        apiService(myConfig.updateSuspensionEP, inspection)
            .then(resp => {
                setSubmitionCompleted(true);
            })
            .catch(error => {
                console.log(["Config Maintenance - Errored->", error]);
                setSubmitionError(true);
                setSubmitionCompleted(true);
            });
        } else {
            toast.error("Please provide a reason for suspension");
        }
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={maxWidth}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                {!isSubmitionCompleted && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Update Suspension </DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6"></DialogContentText>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="From Date"
                                            value={fromDate}
                                            sx={{ width: '100%' }}
                                            onChange={(newValue) => setFromDate(newValue)}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="To Date"
                                            value={toDate}
                                            sx={{ width: '100%' }}
                                            onChange={(newValue) => setToDate(newValue)}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-basic"
                                        label='Suspension Reason'
                                        name='reason'
                                        value={suspensionReason}
                                        onChange={(event) => setReason(event.target.value)}
                                        helperText=""
                                        margin="normal"
                                        type="text"
                                        sx={{ display: 'flex', width: "100%" }}
                                        multiline
                                        rows={4} />
                                </Grid>
                            </Grid>
                            <DialogActions>
                                <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button type="button" variant="contained" color="primary" onClick={handleUpdate}>
                                    Submit
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionCompleted && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Thanks!</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">Inspection Updated</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Sorry! Update Inspection Failed</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">Try Again Later</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleRetry}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
            </Dialog>
        </React.Fragment>
    );
}
export default UpdateInspection;