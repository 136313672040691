import React, { useState, useEffect } from "react";
import { Grid, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import axios from "axios";
import {
    Button,
    withStyles,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { myConfig } from "../../settings";
import { useSelector } from 'react-redux';
import { assetfamilyModel } from "../../schema/schema";
import { apiService, apiService2 } from "../../features/apiService";

let fakecount = 0;
const ViewAssetFamily = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(props.open.state);
    const { user } = useSelector((state) => state.auth);
    const [assetFamilyList, setAssetFamilyList] = useState([])
    const [assetFamily, setAssetFamily] = useState({});
    const assetfamily = props.open.assetfamily;

    if (props.open.state & !open) {
        setOpen(true);
        var family = assetfamily.assetfamilyList;
        console.log(assetfamily);
        setAssetFamily(assetfamily)
        if (family && family.length > 0) {
            setAssetFamilyList([...family])
        } else {
            setAssetFamilyList([])
        }

    }


    // Handle States for the dialog box
    function handleClose() {
        setOpen(false);
        props.open.setfn();
    }


    return (
        <React.Fragment>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'md'}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">

                <React.Fragment>
                    <DialogTitle id="form-dialog-title">View Asset Family</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{
                            marginBottom: "20px"
                        }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4}>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Asset Family Name
                                    </Typography>
                                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                                        {assetFamily?.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Asset Family Code
                                    </Typography>
                                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                                        {assetFamily?.code}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Description
                                    </Typography>
                                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                                        {assetFamily?.description}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow style={{ textAlign: "left" }}>
                                        <TableCell>Field Name</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Data Type</TableCell>
                                        <TableCell>Units</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {assetFamilyList.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {row.fieldname}
                                            </TableCell>
                                            <TableCell> {row.description}</TableCell>
                                            <TableCell> {row.datatype}</TableCell>
                                            <TableCell> {row.units}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <DialogActions>
                            <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                                Close
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </React.Fragment>
            </Dialog>
        </React.Fragment>
    );
}

export default ViewAssetFamily;