/* eslint-disable react-hooks/exhaustive-deps */
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TableViewIcon from '@mui/icons-material/TableView';
import { useState, useEffect, useMemo } from "react";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

import { myConfig } from "../../settings.js";
import EditWorkOrder from './editwo.jsx';
import AddNotes from "./addnotes";
import InspectionForm from "./inspectionForm";
import Accessor from "./accessor";
import Resource from "./resource";
import ApprovalDialog from "./approval.jsx";

import { apiService, apiService2 } from '../../features/apiService.js';
import { getAccess } from "../../features/helpers/authhelper";
import { timeZoneConverter } from "../../features/helpers/commonhelper.js";
import { tableView } from "../../features/helpers/table.js";
import "react-datepicker/dist/react-datepicker.css";
import { filter } from "../../features/helpers/filter.js";
import WorkorderReopen from "./workorderReopen.jsx";
import { Filter } from "../../features/helpers/filter.js";


const WorkOrder = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const suspendEP = myConfig.suspendWOEndPoint;
  // React Hooks
  const [open, setOpen] = useState(false);
  const [iopen, setInspectOpen] = useState(false);
  const [nopen, setNotesOpen] = useState(false);
  const [vopen, setViewOpen] = useState(false);
  const [aopen, setAccessorOpen] = useState(false);
  const [ropen, setResourceOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [approvalOpen, setApprovalOpen] = useState(false);
  const [workOrderReopen, setWorkOrderReopen] = useState(false);
  const [workOrderList, setWorkOrderList] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [selectedrow, setRow] = useState(-1);
  const [selectedWorkOrder, setWO] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const access = getAccess(user, 'workorder');
  const timeZone = localStorage.getItem('timeZone');

  // React Table Hooks
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);

  // Date Range Hooks
  const [fromDate, setFromDate] = useState(dayjs().subtract(1, 'month'))
  const [toDate, setToDate] = useState(dayjs());
  const [deptList, setDeptList] = useState([]);
  const [assetList, setAssetList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [sectionList, setSectionList] = useState([]);

  // Constants for dropdowns
  const typeList = ['Breakdown', 'Inspection', 'Preventive', 'Corrective'];
  const stateList = ['Created', 'Scheduled', 'Completed', 'Cancelled', 'Suspended'];
  const severityList = ['1', '2', '3']



  useEffect(() => {
    handleFilter();
  }, [pagination, pagination.pageIndex, pagination.pageSize, sorting, refresh, columnFilters, globalFilter]);

  function handleClose(ls) {
    console.log("selectedrow->", selectedrow);
    console.log("ls->", approvalOpen);
    var row = selectedrow;
    window.localStorage.setItem("workOrdorRow", row);
    setOpen(false);
    setNotesOpen(false);
    setViewOpen(false);
    setInspectOpen(false)
    setAccessorOpen(false);
    setResourceOpen(false);
    setApprovalOpen(false);
    setRowSelection(row);
    setWorkOrderReopen(false);
    if (ls == 'open-inspection') {
      setInspectOpen(true)
    } else {
      setInspectOpen(false)
      setRefresh(!refresh);
    }

  }

  useEffect(() => {
    const wonumber = Object.keys(rowSelection)[0];
    const selected = Object.values(rowSelection)[0];
    let index = 0;
    if (selected && workOrderList?.length > 0) {
      for (const tempwo of workOrderList) {
        if (tempwo.wonumber == wonumber) {
          setWO(tempwo);
          setRow(index);
          break;
        }
        ++index;
      }
    } else {
      setWO({});
    }
  }, [rowSelection]);

  useEffect(() => {
    apiService(myConfig.getDeptEP)
      .then(response => {
        let modDeptList = [];
        for (const deptItem of response.data) {
          modDeptList.push(deptItem.name);
        }
        setDeptList(modDeptList)
      })
      .catch(error => {
        console.error(error);
      })
  }, [rowSelection]);

  useEffect(() => {
    apiService(myConfig.getResource)
      .then(response => {
        let modUserList = [];
        for (const userItem of response.data) {
          modUserList.push(userItem.email);
        }
        setUserList(modUserList)
      })
      .catch(error => {
        console.error(error);
      })
  }, [rowSelection]);

  useEffect(() => {
    apiService2('POST', myConfig.getFacilitiesFilterEP, {},)
      .then(response => {
        let modSectionList = [];
        for (const fac of response.data?.filter(x => x.type == 'Section')) {
          modSectionList.push(fac.name);
        }
        setSectionList(modSectionList);
      })
      .catch(error => {
        console.error(error);
      })
  }, [open]);



  // Open Add notes component
  function handleAddNotes() {
    if (selectedWorkOrder.state == 'Completed') {
      toast.error("Workorder is Completed");
      return;
    }
    if (selectedrow != -1) {
      setNotesOpen(true);
    } else toast.warning("Select a row/WO first ");
  }

  // Open update workorder component
  function handleUpdateWO() {
    if (selectedrow === -1) {
      toast.warning("Select a row/WO first");
      return;
    }
    const { state, type, assetid, approvalrequired, safetyclearance, isApproved, currentApproverLevel, dept, inspection, isWorkshop, assetmovement } = selectedWorkOrder;
    const breakdownExists = workOrderList.some(x => x.assetid === assetid && x.type === 'Breakdown' && x.state !== 'Completed');
    if (state === 'Completed') {
      toast.error("Workorder is Completed");
      return;
    }
    if (isWorkshop && assetmovement == 'External') {
      toast.error("Workorder is in external workshop and cannot be updated");
      return;
    }
    if (type !== 'Breakdown' && breakdownExists) {
      toast.error("Breakdown workorder already exists for this asset");
      return;
    }
    if ((approvalrequired || safetyclearance) && !isApproved) {
      if (user.approvallevel === currentApproverLevel) {
        setApprovalOpen(true);
      } else {
        toast.error(`Workorder is not approved. Wait for approval ${currentApproverLevel}`);
      }
      return;
    }
    if (user.access !== 'ADMIN' && dept !== user.dept) {
      toast.error("Workorder is not assigned to your dept");
      return;
    }
    if (inspection && type === 'Inspection') {
      setInspectOpen(true);
    } else {
      setOpen(true);
    }
  }


  // Open suspend/Revoke workorder component
  function handleSuspend() {
    if (selectedrow != -1) {
      if (user.access != 'ADMIN' && selectedWorkOrder.dept != user.dept) {
        toast.error("Workorder is not assigned to your dept");
        return;
      }
      const sWorkOrder = selectedWorkOrder;
      sWorkOrder['tenantid'] = user.tenantid;
      apiService(suspendEP, sWorkOrder)
        .then(response => {
          if (sWorkOrder.state == 'Suspended') {
            toast("WorkOrder Revoked");
          } else {
            toast("WorkOrder Suspended");
          }
          setRefresh(!refresh);
        })
        .catch(error => { console.error(error); })
    } else toast.warning("Select a row/WO first ");
  }

  function handleAccessor() {
    if (selectedWorkOrder.state == 'Completed') {
      toast.error("Workorder is Completed");
      return;
    }
    if (selectedrow != -1) {
      setAccessorOpen(true);
    } else toast.warning("Select a row/WO first ");
  }

  function handleResource() {
    if (selectedWorkOrder.state == 'Completed') {
      toast.error("Workorder is Completed");
      return;
    }
    if (selectedrow != -1) {
      setResourceOpen(true);
    } else toast.warning("Select a row/WO first ");
  }

  function handleReOpenWorkOrder() {
    if (!selectedWorkOrder.isWorkshop) {
      setWorkOrderReopen(true);
    } else {
      toast.error("Workorder is completed in workshop and cannot be reopened");
      return;
    }
  }

  function CustomToolbar() {
    return (
      <Box className="mb-1">
        {
          selectedWorkOrder && <Button variant="contained" color="primary" startIcon={<TableViewIcon />} >
            <Link to={`/workorder/view/${selectedWorkOrder._id}`} style={{ textDecoration: 'none', color: 'white' }}>View </Link>
          </Button>
        }
        {
          access?.isEdit && <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={handleUpdateWO}>
            Update
          </Button>
        }
        {
          access?.isDeleted && <Button variant="contained" color="primary" startIcon={<DeleteIcon />} onClick={handleSuspend}>
            Suspend/Revoke
          </Button>
        }
        {
          access?.isCreate && <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddNotes}>
            Add Notes
          </Button>
        }
        {
          access?.isCreate && <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAccessor}>
            Add Spares
          </Button>
        }
        {
          access?.isCreate && <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleResource}>
            Add Resource
          </Button>
        }
        {
          selectedWorkOrder && selectedWorkOrder.state == 'Completed' && <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleReOpenWorkOrder}>
            Reopen
          </Button>
        }
      </Box>
    );
  }

  function handleFilter() {
    console.log("Filter method called");
    if (workOrderList.length == 0) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }
    let data = {
      limit: pagination.pageSize,
      page: pagination.pageSize * pagination.pageIndex,
      sorting: sorting,
      filter: columnFilters,
      fromDate: fromDate,
      toDate: toDate,
      globalFilter: globalFilter,
    }
    apiService2('POST', myConfig.getWorkOrderFilterEP, data)
      .then(response => {
        console.log('response.data', response.data);
        var list = response.data?.workorder || [];
        list?.forEach(res => {
          res.cdate = timeZoneConverter(res.createddate);
          res.sdate = timeZoneConverter(res.startdate);
          res.section = res?.asset != null ? res.asset.section : '';
          res.subsection = res?.asset != null ? res.asset.subsection : '';
          if (res.finishdate) {
            res.fdate = timeZoneConverter(res.finishdate);
          }
        })
        setWorkOrderList(list);
        setRowCount(response.data?.count)
        if (window.localStorage.getItem("workOrdorRow")) {
          setRow(window.localStorage.getItem("workOrdorRow"))
          setWO(response.data[window.localStorage.getItem("workOrdorRow")])
          setRowSelection({ [response.data[window.localStorage.getItem("workOrdorRow")]?.wonumber]: true })
        }
        setIsLoading(false);
        setIsRefetching(false);
      })
      .catch(error => {
        console.error(error);
      })
  }

  function handleResetFilter() {
    console.log("reset filter called");
    setFromDate(dayjs().subtract(1, 'month'));
    setToDate(dayjs());
    setGlobalFilter('');
    setColumnFilters([]);
    setPagination({ pageIndex: 0, pageSize: 10 });
  }

  const columns = useMemo(() => [
    {
      accessorKey: "assetid",
      header: "Asset Id",
      enableColumnFilterModes: false,
    },
    {
      accessorKey: "wonumber",
      header: "WO Number",
      enableColumnFilterModes: false,
    },
    {
      accessorKey: "type",
      header: "Type",
      filterVariant: 'select',
      filterSelectOptions: typeList,
      filterFn: 'customFilterFn',
      enableColumnFilterModes: false,
    },
    {
      accessorKey: "wrnumber",
      header: "WR Number",
      enableColumnFilterModes: false,
    },
    {
      accessorKey: "assignee",
      header: "Assignee",
      filterVariant: 'select',
      filterSelectOptions: userList,
      enableColumnFilterModes: false,
    },
    {
      accessorKey: "dept",
      header: "Dept",
      filterVariant: 'select',
      filterSelectOptions: deptList,
      enableColumnFilterModes: false,
    },
    {
      accessorKey: "state",
      header: "State",
      filterVariant: 'select',
      filterSelectOptions: stateList,
      enableColumnFilterModes: false,
    },
    {
      accessorKey: "severity",
      header: "Severity",
      filterVariant: 'select',
      filterSelectOptions: severityList,
      enableColumnFilterModes: false,
    },
    {
      accessorKey: "section",
      header: "Section",
      filterVariant: 'select',
      filterSelectOptions: sectionList,
      enableColumnFilterModes: false,
    },
    {
      accessorKey: "cdate",
      header: "Created Date",
      enableColumnFilter: false,
    },
    {
      accessorKey: "createdBy",
      header: "Created By",
      enableColumnFilter: false,
    },
  ]);


  return (
    <Box m="20px">
      <Header title="Work Orders" subtitle="" />
      <Box
        m="40px 0 0 0"
        height="70vh"
      >
        {CustomToolbar()}
        <Filter params={{ fromDate, setFromDate, toDate, setToDate, handleFilter, handleResetFilter }} />
        {tableView(true, columns, workOrderList, setRowSelection, rowSelection, 'wonumber', false, null, null, isRefetching, pagination, setPagination, sorting, setSorting, rowCount, columnFilters, setColumnFilters, globalFilter, setGlobalFilter)}
        <EditWorkOrder open={{ state: open, setfn: handleClose, rowid: selectedrow, workorder: selectedWorkOrder }} />
        <AddNotes open={{ state: nopen, setfn: handleClose, rowid: selectedrow, workorder: selectedWorkOrder }} />
        <InspectionForm open={{ state: iopen, setfn: handleClose, rowid: selectedrow, workorder: selectedWorkOrder }} ></InspectionForm>
        <Accessor open={{ state: aopen, setfn: handleClose, rowid: selectedrow, workorder: selectedWorkOrder }}></Accessor>
        <Resource open={{ state: ropen, setfn: handleClose, rowid: selectedrow, workorder: selectedWorkOrder }}></Resource>
        <ApprovalDialog open={{ state: approvalOpen, setfn: handleClose, rowid: selectedrow, workorder: selectedWorkOrder }}></ApprovalDialog>
        <WorkorderReopen open={{ state: workOrderReopen, setfn: handleClose, rowid: selectedrow, workorder: selectedWorkOrder }}></WorkorderReopen>
      </Box>
    </Box>
  );
};

export default WorkOrder;
