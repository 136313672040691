import React, { useState, useEffect } from "react";
import { Grid, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { myConfig } from "../../settings";
import { inventoryModel } from "../../schema/schema.js";
import { useSelector } from 'react-redux';
import { formCreator } from "../../features/helpers/formhelper";
import { apiService2 } from "../../features/apiService";

// API End Point

const EditInventory = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(props.open.state);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [isSubmitionError, setSubmitionError] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [initialValues, setInitialValues] = useState({});
  const [formFields, setFormFields] = useState(inventoryModel);
  const [vendorList, setVendorList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [storeConfigList, setStoreConfigList] = useState([]);

  function getInitialValues() {
    let ivalues = {};
    const inventory = props.open.inventory;
    for (const item of inventoryModel) {
        if(item.field == 'autoreorder'){
            ivalues[item.field] = inventory[item.field] ? 'Yes' : 'No';
        } else {
            ivalues[item.field] = inventory[item.field];
        }
    }
    setInitialValues({ ...ivalues })
    return ivalues;
}


  // Handle States for the dialog box
  function handleClose() {
    setOpen(false);
    setSubmitionError(false);
    props.open.setfn();
  }


  // console.log(["Here open->", props.open.state, open, isSubmitionError]);
  if (props.open.state & !open) {
    setOpen(true);
    getInitialValues();
    setSubmitionCompleted(false);
    setSubmitionError(false);
  }

  function handleSelect() {

  }

  useEffect(() => {
    apiService2('GET', myConfig.getVendorEP)
      .then(response => {
        var vendorList = response.data;
        vendorList?.forEach(res => {
          res.cdate = new Date(res.createdOn).toLocaleDateString()
        })
        setVendorList(vendorList);
      })
      .catch(error => {
        console.error(error);
      });
  }, [open]);

  let modVendorList = [];
  for (const cont of vendorList) {
    modVendorList.push(cont.supplierName);
  }

  useEffect(() => {
    apiService2('GET', myConfig.getUnitEP)
      .then(response => {
        setUnitList(response.data)
      })
      .catch(error => {
        console.error(error);
      })
  }, [open]);


  let modUnitList = [];
  for (const fac of unitList.filter(x => x.unitType == 'Inventory')) {
    modUnitList.push(fac.parameter);
  }

  useEffect(() => {
    apiService2('GET', myConfig.getStoreConfigEP)
      .then(response => {
        var storeConfigList = response.data;
        setStoreConfigList(storeConfigList);
      })
      .catch(error => {
        console.error(error);
      });
  }, [open]);

  let modStoreList = [];
  for (const fac of storeConfigList) {
    modStoreList.push(fac.name);
  }


  const valuesVB = { "vendor": modVendorList, "unit": modUnitList, "store": modStoreList };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {!isSubmitionCompleted && !isSubmitionError && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Edit Inventory Item</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6"></DialogContentText>
              <Formik

                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  const inventory = props.open.inventory;
                  values['_id'] =inventory._id;
                  values['autoreorder'] = values['autoreorder'] == 'Yes' ? true : false;
                  var store = storeConfigList.find(x => x.name == values['store']);
                  values['store'] = store._id;
                  var vendor = vendorList.find(x => x.supplierName == values['vendor']);
                  values['vendor'] = vendor._id;
                  apiService2('POST', myConfig.updateInventoryEP, values)
                    .then(response => {
                      if (response.data.message) {
                        //setErrorMessage(response.data.message);
                        setSubmitionError(true);
                      } else {
                        setSubmitionCompleted(true);
                      }
                    })
                    .catch(error => {
                      setSubmitionError(true);
                      console.error(error);
                    })

                }}
                // Need to expand the validation schema more later
                validationSchema={Yup.object().shape({
                  itemname: Yup.string().required("Required"),
                  itemcode: Yup.string().required("Required")
                })}
              >
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    resetForm,
                    setFieldValue
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={3}>
                        {formCreator("Complex", 'add', formFields, initialValues, values, handleChange, handleBlur, touched, errors, valuesVB, handleSelect, {}, setFieldValue, 6)}
                      </Grid>
                      <DialogActions>
                        <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                          Cancel
                        </Button>
                        <Button type="button" variant="contained" color="primary"
                          onClick={() => resetForm({ values: setInitialValues(getInitialValues()) })}
                          disabled={!dirty || isSubmitting}
                        >
                          Reset
                        </Button>
                        <Button type="submit" variant="contained" color="primary" disabled={!dirty || isSubmitting}>
                          Submit
                        </Button>
                      </DialogActions>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionCompleted && (
          <React.Fragment>
            <DialogContent>
              <DialogContentText variant="h6">Inventory Item Updated Successfully</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionError && (
          <React.Fragment
          >
            <DialogTitle id="form-dialog-title">Sorry! Edit Inventory Failed</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Try Again Later</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  );
}

export default EditInventory;