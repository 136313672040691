import React, { useState, useEffect } from "react";
import { FormHelperText, Grid, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import axios from "axios";
import {
  Button,
  withStyles,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { myConfig } from "../../settings";
import { deptModel } from "../../schema/schema.js";
import { useSelector } from 'react-redux';
import { apiService, apiService2 } from '../../features/apiService';
// API End Point
const endPoint = myConfig.addDeptEP;

let fakecount = 0;

const EditDept = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(props.open.state);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [isSubmitionError, setSubmitionError] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [initialValues, setInitialValues] = useState({});

  function getInitialValues() {
    let ivalues = {};
    const dept = props.open.dept;
    for (const item of deptModel) {
      ivalues[item.field] = dept[item.field];
    }
    fakecount++;
    console.log('sfhste stjswtjytd', ivalues);
    setInitialValues({ ...ivalues })
  }


  // Handle States for the dialog box
  function handleClose() {
    setOpen(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);
    props.open.setfn();
  }


  if (props.open.state & !open) {
    setOpen(true);

    getInitialValues();
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'xs'}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        {!isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Add Department</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6"></DialogContentText>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  const dept = props.open.dept;
                  values['_id'] =  dept._id;
                  apiService2('POST', myConfig.updateDeptEP, values)
                    .then(resp => {
                      setSubmitionCompleted(true);
                    })
                    .catch(error => {
                      setSubmitionError(true);
                      setSubmitionCompleted(true);
                    });
                }}
                // Need to expand the validation schema more later
                validationSchema={Yup.object().shape({
                  name: Yup.string().required("Department Name Required"),
                  costcentercode: Yup.string().required("Cost Center Code Required"),
                })}
              >
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                    resetForm
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={1}>
                        {(() => {
                          let result = [];
                          for (const item of deptModel.filter(item => !item.skip)) {
                            var key = item.field;
                            result.push(
                              <Grid item xs={12}>
                                <TextField
                                  id="outlined-basic"
                                  error={errors[key]}
                                  label={item.label}
                                  name={key}
                                  value={values[key]}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  style={{ width: "100%" }}
                                  helperText=""
                                  margin="normal"
                                  type="text"
                                />
                                <FormHelperText style={{color: 'red', fontSize: '15px'}}>{errors[key] && touched[key] ? errors[key] : ""}</FormHelperText>
                              </Grid>
                            )
                          }
                          return result;
                        })()}
                      </Grid>
                      <DialogActions>
                        <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                          Cancel
                        </Button>
                        <Button
                          type="button"
                          variant="contained" color="primary"
                          onClick={() => resetForm()}
                          disabled={!dirty || isSubmitting}>
                          Reset
                        </Button>
                        <Button type="submit" variant="contained" color="primary" disabled={!dirty || isSubmitting}>
                          Submit
                        </Button>
                      </DialogActions>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Thanks! Department Updated</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Thanks</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionError && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Sorry! Updated Department Failed</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Try Again Later</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  );
}

export default EditDept;