import * as React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, CardContent, CardHeader, Dialog, Grid, Typography, useTheme } from "@mui/material";
import { Box, Button } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { tokens } from "../../theme";
import { useEffect } from "react";
import Header from "../../components/Header";
import { workRequestModel } from "../../schema/schema.js";



const ViewWR = (props) => {
  const location = useLocation();
  const asset = location.state;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const formFields = workRequestModel;
  const [open, setOpen] = useState(props.open.state);
  
  const [wr, setWR] = useState({});
  //const initValues = getInitialValues();
  const [initialValues, setInitialValues] = useState({});
 
  let gdata = [];
  var idval = 1;
  var fakecount = 0;

  function getInitialValues() {
    let ivalues = {};
    const workrequest = props.open.workrequest;
    formFields.forEach((value) => {
        var retValue = workrequest[value['field']];
        if (value['field'].toLowerCase().indexOf("date") >= 0) {
            retValue = viewDate(workrequest[value['field']]);
        }
      ivalues[value['field']] = retValue;
    });
    console.log("Initial Values->", ivalues);
    ++fakecount;
    setWR(ivalues);
    return ivalues;
  }
  console.log("Entering viewWR->", open,props);
  if (props.open.state & !open) {
    setOpen(true);
    setInitialValues(getInitialValues());
  }

  function viewDate(dateip) {
    if (dateip == null) return ''
    const today = new Date(dateip);
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${month}/${date}/${year} ${today.getHours()}:${today.getMinutes()}`;
  }

   // Handle States for the dialog box
   function handleClose() {
        // console.log("handleClose: Setting open to false");
        setOpen(false);
        //setSubmitionError(false);
        //setSubmitionCompleted(false);
        props.open.setfn();
    }

  function CustomToolbar() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
      <Box
        sx={{
          "& .MuiButton-outlined": {
            border: "1px solid",
            borderColor: colors.primary[500],
            color: colors.primary[100]
          },
          "& .MuiButton-root": {
            backgroundColor: colors.blueAccent[500]
          }
        }}>
        <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={handleClose}>
          Back
        </Button>
      </Box>
    );
  }

  return (
    <React.Fragment >
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={'lg'}
      >
        {
          <Card sx={{ width: 1000, height: 520 }} >
            <Header title="Work Request" subtitle="" />
            <CardContent>
              <Grid container spacing={3} >
                {
                 (()=> {  
                    var result = [];
                    formFields.forEach((value) => {
                      result.push (                                    
                          <Grid item xs={4}>
                              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {value['label']}
                              </Typography>
                              <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                                {wr[value['field']]}
                              </Typography>
                          </Grid>  
                      )               
                    }
                    )
                 return result;
                })()
                }
              </Grid>
            </CardContent>              
          </Card>
        }
  </Dialog>
</React.Fragment>
 )
}

export default ViewWR;