import * as React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useTheme, Grid, DialogTitle, DialogContent, Typography, DialogActions, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from "@mui/material";
import Header from "../../components/Header";
import { Box, IconButton, Button, Dialog } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { GridToolbarContainer } from '@mui/x-data-grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { tokens } from "../../theme";
import { myConfig } from "../../settings";
import { useSelector } from 'react-redux';
import { apiService } from '../../features/apiService.js';
import { toast } from 'react-toastify';
import { formCreator } from "../../features/helpers/formhelper";
import { maintenanceLibModel } from "../../schema/schema.js";


const ViewMaintenanceLib = (props) => {
  const location = useLocation();
  const asset = location.state;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(props.open.state);
  const formFields = maintenanceLibModel;
  //const columns = [{field: "attr", headerName:"WO Attribute", flex: 1}, {field: "avalue", headerName: "Value", flex: 1,editable: true}];
  let gdata = [];
  var idval = 1;
  //const { user } = useSelector((state) => state.auth);
  const maint = props.open.selectedlib;
  const titellist = ["_id", "__v", "isDeleted", "tasklist", "itemlist", "facility", "cdate","preferedstore"];
  for (let key in maint) {
    if (titellist.includes(key)) continue;
    var label = maintenanceLibModel.find((item) => item.field === key)?.label;
    console.log(label);
    if (key.includes("date") || key.includes("createdOn")) {
      gdata.push({ 'id': idval, 'attr': key, 'label': label, 'avalue': new Date(maint[key]).toLocaleDateString() });
    } else {
      gdata.push({ 'id': idval, 'attr': key, 'label': label, 'avalue': maint[key] });
    }
    idval += 1;
  }

  if (props.open.state & !open) {
    setOpen(true);
    //console.log(["OpenCheck: Setting open to false", open, props.open.user]);
  }

  function handleClose() {
    console.log(["handleClose: Setting open to false", open, props.open.user]);
    setOpen(false);
    props.open.setfn();
    // objindex = 0;
  }



  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'md'}
      sx={{
        "&.MuiGrid-item": {
          paddingTop: 10,
        }
      }}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <React.Fragment>
        <DialogTitle id="form-dialog-title">View Tasks </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {
              gdata.map((item, index) =>
                <Grid item xs={4}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {item.label}
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                    {item.avalue}
                  </Typography>
                </Grid>
              )
            }
          </Grid>
          {
            maint['tasklist'] && maint['tasklist'].length > 0 &&
            <>
              <Typography sx={{ fontSize: 20, marginTop: 2, marginLeft: 0 }}>
                Task List
              </Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow style={{ textAlign: "left" }}>
                      <TableCell>Task Name</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Department</TableCell>
                      <TableCell>Trade</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {maint['tasklist'].map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {row.taskname}
                        </TableCell>
                        <TableCell > {row.desc}</TableCell>
                        <TableCell > {row.dept}</TableCell>
                        <TableCell > {row.trade}</TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          }
          {
            maint['itemlist'] && maint['itemlist'].length > 0 &&
            <>
              <Typography sx={{ fontSize: 20, marginTop: 2, marginLeft: 0 }}>
                Spares List
              </Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow style={{ textAlign: "left" }}>
                      <TableCell>Item Name</TableCell>
                      <TableCell>Quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {maint['itemlist'].map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {row.itemname}
                        </TableCell>
                        <TableCell > {row.quantity}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          }

          <DialogActions>
            <Button type="button" variant="contained" color="primary" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </DialogContent>

      </React.Fragment>
    </Dialog>
  );
}
export default ViewMaintenanceLib;