import { TIMEZONE } from "../../data/timezone";

export const timeZoneConverter = (date) => {
    var timezone = JSON.parse(localStorage.getItem('timeZone'));
    timezone = timezone ? timezone : 'America/Mexico_City';
    var language = TIMEZONE.find(x => x.zone === timezone);
    language = language ? language?.local : 'en-US';
    return new Date(date).toLocaleString(language, { timeZone: timezone });
};

export const timeZoneConverterDate = (date) => {
    var timezone = JSON.parse(localStorage.getItem('timeZone'));
    timezone = timezone ? timezone : 'America/Mexico_City';
    var language = TIMEZONE.find(x => x.zone === timezone);
    language = language ? language?.local : 'en-US';
    return new Date(date).toLocaleDateString(language, { timeZone: timezone });
};

export const differTwoDates = (date1,date2)  =>{
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const timeDifference = Math.abs(d2 - d1);
    const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return dayDifference;
}
export const isDateGreater = (date1, date2) => {
    // Parse the dates
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    
    // Compare the dates
    return d1 > d2;
}