import React, { useState, useEffect } from "react";
import { Grid, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
    Button,
    withStyles,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper
} from "@mui/material";

import { useSelector } from 'react-redux';


let fakecount = 0;
const ViewData = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const [sensorDataList, setSensorDataList] = useState([])
    const sensordata = props.open.sensordata;
    var assetname = props.open.sensordata.name;

    if (props.open.state & !open) {
        setOpen(true);
        var sdata = props.open.sensordata;
        console.log(sdata);
        setSensorDataList(sensordata.sensordata);
    }

    // Handle States for the dialog box
    function handleClose() {
        setOpen(false);
        props.open.setfn();
    }


    return (
        <React.Fragment>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'md'}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">

                <React.Fragment>
                    <DialogTitle id="form-dialog-title">Sensor Data History For Asset: {assetname}   </DialogTitle>
                    <DialogContent>
                        <DialogContentText variant="h6"></DialogContentText>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow style={{ textAlign: "left" }}>
                                        <TableCell>Time Stamp</TableCell>
                                        <TableCell>State</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sensorDataList.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell> {row.tstamp}</TableCell>
                                            <TableCell> {row.state}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <DialogActions>
                            <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                                Close
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </React.Fragment>
            </Dialog>
        </React.Fragment>
    );
}

export default ViewData;