import React, { useState, useEffect } from "react";
import { Grid, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { myConfig } from "../../settings";
import { maintenanceModel } from "../../schema/schema.js";
import { apiService, apiService2 } from '../../features/apiService';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify'
import { formCreator } from "../../features/helpers/formhelper";

//function initialiseFields() {return workOrderModel};

// API End Point
const endPoint = myConfig.editMaintenanceEP;
let objindex = 0;
let fakecount = 0;

const EditMaintenance = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(props.open.state);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [isSubmitionError, setSubmitionError] = useState(false);
  const [formFields, setFormFields] = useState(maintenanceModel)
  const { user } = useSelector((state) => state.auth);
  const [initialValues, setInitialValues] = useState({});
  const [assetList, setAssetList] = useState([]);
  const [asset, setAsset] = useState('');
  const [frequency, setFrequency] = useState([]);
  const [valueDB, setValueDB] = useState({ "asset": [], "Maintenance Library": [], "dept": [], frequency: [], safetyofficer: [], externalVendor: [] });
  function getInitialValues() {
    let ivalues = {};
    const maintenance = props.open.maintenance;
    var data = [];
    formFields.forEach((value) => {
      if (value['field'] == 'frequency' && maintenance[value['field']] == 'Meter' && !formFields.find(x => x.field == "thresloadValue" && x.skip == false) && !formFields.find(x => x.field == "diffValue" && x.skip == false)) {
        var asset = assetList.find(x => x.assetid == maintenance['assetid']);
        if (asset.reset) {
          data.push({ field: "thresloadValue", type: String, label: 'Meter Threshold Value', skip: false, condition: true, conditionField: 'frequency', conditionValue: "Meter", defaultValue: asset?.diffValue })
          data.push({ field: "unit", type: String, label: 'Reset Meter', skip: false, condition: true, conditionField: 'frequency', conditionValue: "Meter", defaultValue: asset?.unit })
        } else {
          data.push({ field: "diffValue", type: Date, label: 'Meter Difference Value', skip: false, condition: true, conditionField: 'frequency', conditionValue: "Meter" });
          data.push({ field: "unit", type: String, label: 'Reset Meter', skip: false, condition: true, conditionField: 'frequency', conditionValue: "Meter", defaultValue: asset?.unit });
        }
      } else if (value['field'] == 'type') {
        var datas = [];
        if (maintenance[value['field']] == "Preventive") {
          datas = ["Meter", "Monthly", "BiWeekly"];
        } else {
          datas = ["One Time"];
        }
        valueDB["frequency"] = datas;
        setFrequency(datas);
      } else if (value['field'] == 'status') {
        value['skip'] = false;
      }
    });
    var s = [...formFields, ...data];
    s.forEach((value) => {
      if (value['field'] == 'approvalrequired' || value['field'] == 'safetyclearance') {
        ivalues[value['field']] = maintenance[value['field']] == 'Yes' ? true : false;
      } else {
        ivalues[value['field']] = maintenance[value['field']];
      }

    });
    ++fakecount;
    setFormFields([...s]);
    setInitialValues({ ...ivalues })
    return ivalues;
  }

  useEffect(() => {
    apiService(myConfig.getDeptEP)
      .then(response => {
        let modDeptList = [];
        for (const deptItem of response.data) {
          modDeptList.push(deptItem.name);
        }
        var db = valueDB;
        db["dept"] = modDeptList;
        setValueDB(db);
      })
      .catch(error => {
        console.error(error);
      })
  }, [open]);

  useEffect(() => {
    apiService(myConfig.getAssetsEP)
      .then(response => {
        setAssetList(response.data);
        let modAssetList = [];
        response.data?.forEach((item) => {
          if (item.state === "Operational") modAssetList.push(item.name + "-" + item.assetid);
        })
        var db = valueDB;
        db["asset"] = modAssetList;
        setValueDB(db);
      })
      .catch(error => {
        console.error(error);
      })
  }, [open]);

  useEffect(() => {
    apiService(myConfig.getMainLibEP)
      .then(response => {
        let modMainLibList = [];
        response.data?.forEach((item) => {
          if (item.state == 'ACTIVE') modMainLibList.push(item.name + "-" + item.code);
        })
        var db = valueDB;
        db["Maintenance Library"] = modMainLibList;
        setValueDB(db);
      })
      .catch(error => {
        console.error(error);
      })
  }, [open]);

  useEffect(() => {
    apiService2('POST', myConfig.getFacilitiesFilterEP, { isFilterSection: true },)
      .then(response => {
        var modSectionList = [];
        for (const fac of response.data?.filter(x => x.type == 'Section')) {
          modSectionList.push(fac.name);
        }
        var db = valueDB;
        db["section"] = modSectionList;
        setValueDB(db);
      })
      .catch(error => {
        console.error(error);
      })
  }, [open]);

  // Handle States for the dialog box
  function handleClose() {
    setOpen(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);
    props.open.setfn();
    objindex = 0;
  }

  // Handle retry when submit failed
  function handleRetry() {
    setSubmitionError(false);
    setSubmitionCompleted(false);
  }


  // console.log(["Here open->", props.open.state, open, isSubmitionError]);
  if (props.open.state & !open) {
    setOpen(true);
    setFormFields([]);
    setFormFields(maintenanceModel);
    getInitialValues();
    //console.log(["OpenCheck: Setting open to false", open, props.open.user]);
  }

  function handleSelect(field, value) {
    var data = [];
    if (field == "type") {
      if (value == "Preventive") {
        data = ["Meter", "Monthly", "BiWeekly"];
      } else {
        data = ["One Time"];
      }
      var db = valueDB;
      db["frequency"] = data;
      setValueDB(db);
    } else if (field == "frequency") {
      if (value == "Meter") {
        if (asset.reset) {
          if (formFields.find(x => x.field == "diffValue")) {
            formFields.pop();
            formFields.pop();
          }
          if (!formFields.find(x => x.field == "thresloadValue" && x.skip == false)) {
            let datas = [
              { field: "thresloadValue", type: Date, label: 'Meter Threshold Value', skip: false, condition: true, conditionField: 'frequency', conditionValue: "Meter" },
              { field: "unit", type: String, label: 'Reset Meter', skip: false, condition: true, conditionField: 'frequency', conditionValue: "Meter", defaultValue: asset?.unit, value: true }
            ];
            let s = [...formFields, ...datas];
            setFormFields(s);
            initialValues['unit'] = asset.unit;
            setInitialValues(initialValues);
          }
        } else {
          if (formFields.find(x => x.field == "thresloadValue")) {
            formFields.pop();
            formFields.pop();
          }
          if (!formFields.find(x => x.field == "diffValue" && x.skip == false)) {
            let datas = [
              { field: "diffValue", type: Date, label: 'Meter Difference Value', skip: false, condition: true, conditionField: 'frequency', conditionValue: "Meter" },
              { field: "unit", type: String, label: 'Reset Meter', skip: false, condition: true, conditionField: 'frequency', conditionValue: "Meter", defaultValue: asset?.unit }
            ];
            let s = [...formFields, ...datas];
            setFormFields(s);
            initialValues['unit'] = asset.unit;
          }
        }
      }
    } else if (field == "assetid") {
      const asset = assetList.find(x => x.name + "-" + x.assetid == value);
      setAsset(asset);
    }
  }

  const validationSchema = Yup.object().shape({
    mainlibcode: Yup.string().required('Maintenance Library is required'),
    assetid: Yup.string().required('Asset Id is required'),
    dept: Yup.string().required('Department is required'),
    approvalrequired: Yup.string().required('Approval Required is required'),
    safetyclearance: Yup.string().required('Safety Clearance is required'),
    type: Yup.string().required('Type is required'),
    frequency: Yup.string().required('Frequency is required'),
  })

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {!isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">View/Update Maintenance </DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6"></DialogContentText>
              <Formik
                //enableReinitialize 
                key={fakecount}
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {

                  const lmaint = props.open.maintenance;
                  let retobj = values;
                  setSubmitting(true);
                  {
                    (() => {
                      for (let key in lmaint) {
                        let tobj = {};
                        if (!retobj.hasOwnProperty(key)) {
                          retobj[key] = lmaint[key];
                        }
                      }
                    })()
                  };
                  retobj['tenantid'] = user.tenantid;
                  console.log(["Submitted->", retobj]);
                  apiService(endPoint, retobj)
                    .then(resp => {
                      console.log("Maintenance updated ");
                      setSubmitionCompleted(true);
                    })
                    .catch(error => {
                      console.error(error);
                      setSubmitionError(true);
                      setSubmitionCompleted(true);
                    })
                }}
                // Need to expand the validation schema more later
                validationSchema={validationSchema}
              >
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    resetForm,
                    setFieldValue
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        {formCreator("Complex", 'edit', formFields, initialValues, values, handleChange, handleBlur, touched, errors, valueDB, handleSelect, false, setFieldValue, 6)}
                      </Grid>
                      <DialogActions>
                        <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                          Cancel
                        </Button>
                        <Button
                          type="button" variant="contained" color="primary"
                          onClick={() => resetForm({ values: getInitialValues() })}
                          disabled={!dirty || isSubmitting}
                        >
                          Reset
                        </Button>
                        <Button type="submit" variant="contained" color="primary" disabled={!dirty || isSubmitting}>
                          Submit
                        </Button>
                      </DialogActions>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title"></DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Maintenance updated Successfully</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Back
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionError && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Sorry! Maintenance Update Failed</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Try Again Later</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleRetry}>
                  Back
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  );
}

export default EditMaintenance;