import * as React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogContent, ListItemButton, ListItemIcon } from '@mui/material';
import { VERSION } from '../settings';
import SendIcon from '@mui/icons-material/Send';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout, reset } from '../features/auth/authSlice';
const SimpleDialog = (props) => {
  const { onClose, title, open, content, type, right } = props;
  const navigate = useNavigate()
  const dispatch = useDispatch()
  
  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (item) => {
    console.log('item.to', item.to);
    
    onClose();
    if (item.value === 'Logout') {
      dispatch(logout())
      dispatch(reset())
      navigate('/')
    } else {
      navigate(item.to)
    }
  };

  return (
    <Dialog
      PaperProps={{ sx: { position: 'fixed', top: 60, right: right || 110, m: 0, width: 250 } }}
      onClose={handleClose} open={open} >
      {
        type === "version" ?
          (<>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>Version: {VERSION}</DialogContent>
          </>) :
          (<DialogContent>
            <List>
              {content.map((item) => (
                <ListItemButton key={item.id} onClick={() => handleListItemClick(item)}>
                  <ListItemIcon>
                    {item.icon ? item.icon : <SendIcon />}
                  </ListItemIcon>
                  <ListItemText primary={item.value} />
                </ListItemButton>
              ))}
            </List>
          </DialogContent>)
      }
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  content: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  right: PropTypes.number
};

export default SimpleDialog;
