import { Box, useTheme, Button, TextField, MenuItem } from "@mui/material";
import { tokens } from "../../theme";
import AddIcon from '@mui/icons-material/Add';
import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify'
import { myConfig } from "../../settings.js";
import { useSelector } from 'react-redux';
import { apiService2 } from '../../features/apiService';
import { MaterialReactTable } from "material-react-table";
import { getAccess } from "../../features/helpers/authhelper";
import { storeModel } from "../../schema/schema";
import StockTransfer from "./transfer";
import { timeZoneConverter } from "../../features/helpers/commonhelper.js";
import { tableView } from "../../features/helpers/table.js";

const Store = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const columns = [];
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [storeList, setStoreList] = useState([]);
    const [storeList2, setStoreList2] = useState([]);
    const [store, setStore] = useState([]);
    const { user } = useSelector((state) => state.auth);
    const [selectedrow, setRow] = useState(-1);
    const [rowSelection, setRowSelection] = useState({});
    const access = getAccess(user, 'store');
    const [storeConfigList, setStoreConfigList] = useState([]);
    const [storeConfig, setStoreConfig] = useState('');
    const [storeConfigId, setStoreConfigId] = useState('');

    for (const item of storeModel) {
        if (item.hasOwnProperty("skip")) {
            if (item.skip) continue;
        }
        columns.push({ accessorKey: item['field'], header: item['label'], size: 150 });
    }

    useEffect(() => {
        apiService2('GET', myConfig.getStoreConfigEP)
            .then(response => {
                var storeConfigList = response.data;
                setStoreConfigList(storeConfigList);
                setStoreConfig(storeConfigList[0].name);
                setStoreConfigId(storeConfigList[0]._id);
                apiService2('GET', myConfig.getStoreEP)
                    .then(response => {
                        var list = response.data;
                        list?.forEach(res => {
                            res.cdate = timeZoneConverter(res.createdOn)
                        })
                        setStoreList(list);
                        if (storeConfigList[0]._id && storeConfigList[0]._id != null) {
                            setStoreList2(list.filter((res) => res.store == storeConfigList[0]._id));
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            })
            .catch(error => {
                console.error(error);
            });
    }, [open]);

    let modStoreConfigList = [];
    for (const cont of storeConfigList) {
        modStoreConfigList.push(cont.name);
    }

    useEffect(() => {
        const wrnumber = Object.keys(rowSelection)[0];
        const selected = Object.values(rowSelection)[0];
        let index = 0;
        if (selected) {
            for (const temp of storeList) {
                if (temp._id == wrnumber) {
                    console.log(temp);
                    setStore(temp);
                    setRow(index);
                    break;
                }
                ++index;
            }
        } else {
            setStore({});
        }
    }, [rowSelection]);

    function handleClose() {
        setOpen(false);
        setRefresh(!refresh);
        storeModel({});
    }

    function handleClickOpen() {
        if (selectedrow >= 0) {
            setOpen(true);
        } else {
            toast.error('Select a store');
        }

    }


    function CustomToolbar() {
        return (
            <Box className="mb-1">
                <TextField
                    id="outlined-basic"
                    label={'Store'}
                    name={'redMax'}
                    value={storeConfig}
                    onChange={(e) => {
                        setStoreConfig(e.target.value);
                        var storeConfig = storeConfigList.find((res) => res.name == e.target.value);
                        setStoreConfigId(storeConfig._id);
                        console.log(storeList.filter((res) => res.store == storeConfig._id));
                        
                        setStoreList2(storeList.filter((res) => res.store == storeConfig._id));
                    }}
                    margin="normal"
                    type="text"
                    select
                    defaultValue={''}
                    sx={{ display: 'flex', width: "200px" }}>
                    {modStoreConfigList.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
                {
                    access.isCreate && <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleClickOpen}>
                        Stock Transfer
                    </Button>
                }
            </Box>
        );
    }
    return (
        <Box m="20px">
            <Box
                m="20px 0 0 0"
                 height="70vh">
                {CustomToolbar()}
                {tableView(false,columns, storeList2, setRowSelection, rowSelection, '_id')}
                <StockTransfer open={{ state: open, setfn: handleClose, store: store }} />
            </Box>
        </Box>
    );
};

export default Store;
